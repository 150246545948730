export const setIsLoaded = (state, val) => {
  state.isLoaded = val;
};

export const setIsError = (state, val) => {
  state.isError = val;
};

export const setLastError = (state, error) => {
  state.lastError = error;
};

export const storeState = (state, data) => {
  state.data = data;
};

export const setPagination = (state, data) => {
  state.pagination = data;
}



