import Vue from "vue";

import { reststate } from "src/boot/axios";
import { resourceModule } from "src/utils/reststate-vuex";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import accountingcodes from "./accountingcodes";
import countries from "./countries";
import fees from "./fees";
import forms from "./forms";
import pings from "./pings";
import queue from "./queue";
import schemas from "./schemas";
import shared from "./shared";
import submissions from "./submissions";
import tests from "./tests";

Vue.config.devtools = true; //- this enables vue-devtools in prod (i.e. via `quasar build`)
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const enableLocalForage = process.env.LF;

/**
 * Factory for creating a store. This supports instanced stores,
 * which could be useful for SSR or for injecting other instances.
 * However it's not necessary at this stage for this application.
 * It may become useful if we need to merge initial values for Settings
 * and other modular stores, with those values from reststate.
 */
async function initialiseStore() {
  const storeConfig = {
    plugins: enableLocalForage ? [vuexLocal.plugin] : [],
    modules: {
      accountingcodes,
      countries,
      pings,
      queue,
      shared,
      fees,
      forms,
      schemas,
      submissions,
      tests,
      /*************************
       * @reststate
       *************************/
      dishes: resourceModule({
        name: "dishes",
        httpClient: reststate,
      }),
      restaurants: resourceModule({
        name: "restaurants",
        httpClient: reststate,
      }),
      ratings: resourceModule({
        name: "ratings",
        httpClient: reststate,
      }),
      capacities: resourceModule({
        name: "capacities",
        httpClient: reststate,
      }),
      compliance: resourceModule({
        name: "compliance",
        httpClient: reststate,
      }),
      errors: resourceModule({
        name: "errors",
        httpClient: reststate,
      }),
      authorisations: resourceModule({
        name: "authorisations",
        httpClient: reststate,
      }),
      bankdetails: resourceModule({
        name: "bankdetails",
        httpClient: reststate,
      }),
      clients: resourceModule({
        name: "clients",
        httpClient: reststate,
      }),
      defaultfees: resourceModule({
        name: "defaultfees",
        httpClient: reststate,
      }),
      primaryservices: resourceModule({
        name: "primaryservices",
        httpClient: reststate,
      }),
      documents: resourceModule({
        name: "documents",
        httpClient: reststate,
      }),
      settings: resourceModule({
        name: "settings",
        httpClient: reststate,
      }),
      usersettings: resourceModule({
        name: "usersettings",
        httpClient: reststate,
      }),
      usersettingsPeople: resourceModule({
        name: "usersettingsPeople",
        httpClient: reststate,
      }),
      notarials: resourceModule({
        name: "notarials",
        httpClient: reststate,
      }),
      enquiries: resourceModule({
        name: "enquiries",
        httpClient: reststate,
      }),
      enquiryTypes: resourceModule({
        name: "enquiryTypes",
        httpClient: reststate,
      }),
      entityTypes: resourceModule({
        name: "entityTypes",
        httpClient: reststate,
      }),
      invoicees: resourceModule({
        name: "invoicees",
        httpClient: reststate,
      }),
      peopleTermsSignatories: resourceModule({
        name: "peopleTermsSignatories",
        httpClient: reststate,
      }),
      termsSignatories: resourceModule({
        name: "termsSignatories",
        httpClient: reststate,
      }),
      productcodes: resourceModule({
        name: "productcodes",
        httpClient: reststate,
      }),
      xeroaccounts: resourceModule({
        name: "xeroaccounts",
        httpClient: reststate,
      }),
      notary: resourceModule({
        name: "notary",
        httpClient: reststate,
      }),
      administratives: resourceModule({
        name: "administratives",
        httpClient: reststate,
      }),
      locations: resourceModule({
        name: "locations",
        httpClient: reststate,
      }),
      representatives: resourceModule({
        name: "representatives",
        httpClient: reststate,
      }),
      people: resourceModule({
        name: "people",
        httpClient: reststate,
      }),
      roles: resourceModule({
        name: "roles",
        httpClient: reststate,
      }),
      organisations: resourceModule({
        name: "organisations",
        httpClient: reststate,
      }),
      terms: resourceModule({
        name: "terms",
        httpClient: reststate,
      }),
      testenquiries: resourceModule({
        name: "testenquiries",
        httpClient: reststate,
      }),
      files: resourceModule({
        name: "files",
        httpClient: reststate,
      }),
    },
    // enable strict mode (adds overhead!)
    // for dev mode only
    // @todo: Q. does this cause problems with vuex? A. only if you're mis-using mutations!
    strict: process.env.STRICT, //- must be true for devtools to work
  };

  const store = new Vuex.Store(storeConfig);
  return store;
}
export default initialiseStore;

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

// export default function(/* { ssrContext } */) {
//   const Store = new Vuex.Store({
//     modules: {
//       // example
//     },

//     // enable strict mode (adds overhead!)
//     // for dev mode only
//     strict: process.env.DEV
//   });

//   return Store;
// }
