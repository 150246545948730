import Vue from "vue";
import VueRouter from "vue-router";
import createRoute from "./routes";
import { notify } from "../utils/";

Vue.use(VueRouter);

let Router = null;

export default function ({ store }) {
  Router = new VueRouter({
    routes: createRoute(store, notify),
    scrollBehavior: () => ({ x: 0, y: 0 }),
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
  });

  return Router;
}

export { Router };
