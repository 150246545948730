export default {
  emptyJsonModels: {
    legalisationSteps: {
      emptyRow: () => {
        return {
          ancillary_services: [],
          country_code: "",
          crmnotes: null,
          primaryServiceId: "",
          legalisation_agent_FCDO: "",
          legalisation_agent_embassy: "",
          legalisation_agent_chamber: "",
          timescales: null,
          document_fwd_instructions: "", // i.e. the general instruction, forward, return, hand-back, etc.
          document_fwd_delivery_type: "", // i.e. courrier etc.
          document_fwd_legalisation_agent: "",
          document_fwd_address: "",  // @todo: what's the difference between this and document_fwd_courier_info_address?
          document_fwd_special_instructions: "",
          document_fwd_courier_info_name: "",
          document_fwd_courier_info_address: "",
          document_fwd_courier_info_phone: "",
          document_fwd_courier_info_defer: false,
          notes: null,
          selected: [],
        };
      },
    },

    fees: {
      emptyRow: () => {
        const rows = {
          country_code: "",
          ancillaryfees: [],
          defaultfees: [],
          deliveryfees: [],
          expenses: [],
          notaryfees: [],
        };
        return rows;
      },
    },

    expectedDocuments: {
      emptyRow: () => {
        return {
          country_code: "",
          transaction: "",
          descriptions: [],
          number: "0",
          tbc: null,
          countrydocs: [],
          sameEntityForAllDocuments: false,
          sameSignatoryForAllDocuments: false,
        };
      },
    },
  },
  rules: [
    {
      label: "No legalisation usually required - Notary only",
      //- #6755
      description: "",
      value: 0
    },
    {
      label: "Legalisation by FCDO apostille",
      description:
        "The Foreign and Commonwealth Development Office (FCDO) will affix or make available (methods: paper-based or e-Apostille) an Apostille stamp/seal to each notarised document, as additional verification of the Notary's authority and details.",
      value: 1
    },
    {
      label: "Legalisation by FCDO certification",
      description:
        "The Foreign and Commonwealth Development Office (FCDO) will affix a Certification to each notarised document as evidence of the Notary's authority and details.",
      value: 2
    },
    {
      label: "Legalisation by embassy / consulate / high commission",
      description:
        "FCDO Certification is then followed by the relevant Embassy / Consulate adding their own legalisation stamp to each document.",
      value: 3
    },
    {
      label: "Certification by chamber of commerce",
      description:
        "In order to achieve legalisation by the Embassy / Consulate, after FCDO Certification, the document will be lodged via a Chamber of Commerce, before Embassy / Consulate involvement.",
      value: 4
    },
    {
      label:
        "Legalisation varies - please check with UK notary and your advisor in the state",
      description:
        "Certain documents for some Ireland, South Africa, West Indies and African continent countries require FCDO Apostille.  Likewise, certain States in USA and Australia and Provinces in Canada require FCDO Apostille.",
      value: 5
    }
  ],
  legal_tier_legends: {
    1: "Legalisation for your documents is managed in one step only, as the destination country is a signatory to the Hague Convention.",
    2: "Legalisation for your documents is managed in two steps, as the destination country does not require lodgement by the chamber of commerce",
    3: "Legalisation for your documents is managed in three steps, as the destination requires embassy / consulate stamp and chamber of commerce lodgement."
  },
  //- these are option objects as these values are used by the optionGroup component on legalisationSteps
  legal_code_rules: [
    {
      code: 0,
      rules: [
        {
          label: "No legalisation usually required - Notary only",
          value: 0
        }
      ]
    },
    {
      code: 2,
      rules: [
        {
          label: "FCDO apostille only",
          value: 1
        }
      ]
    },
    {
      code: 3,
      rules: [
        {
          label: "FCDO certification",
          value: 2
        },
        {
          label: "Legalisation by embassy / consulate / high commission",
          value: 3
        }
      ]
    },
    {
      code: 4,
      rules: [
        {
          label: "FCDO certification",
          value: 2
        },
        {
          label: "Legalisation by embassy / consulate / high commission",
          value: 3
        },
        {
          label: "Certification by chamber of commerce",
          value: 4
        },
      ]
    },
    {
      code: 6,
      rules: [
        {
          label:
            "Legalisation varies - please check with UK notary and your advisor in the state",
          value: 5
        }
      ]
    }
  ],
  counties: {
    GB: [
      {
        name: " -- Frequently Used",
        disable: true
      },

      { name: "Berkshire", area: "England" },
      { name: "Buckinghamshire", area: "England" },
      { name: "East Sussex", area: "England" },
      { name: "Hampshire", area: "England" },
      { name: "London", area: "England" },
      { name: "Surrey", area: "England" },
      { name: "West Sussex", area: "England" },

      {
        name: " -- Alphabetical",
        disable: true
      },
      { name: "Bedfordshire", area: "England" },
      { name: "Cambridgeshire", area: "England" },
      { name: "Cheshire", area: "England" },
      { name: "Cleveland", area: "England" },
      { name: "Cornwall", area: "England" },
      { name: "Cumbria", area: "England" },
      { name: "Derbyshire", area: "England" },
      { name: "Devon", area: "England" },
      { name: "Dorset", area: "England" },
      { name: "Durham", area: "England" },
      { name: "Essex", area: "England" },
      { name: "Gloucestershire", area: "England" },
      { name: "Greater London", area: "England" },
      { name: "Greater Manchester", area: "England" },
      { name: "Hertfordshire", area: "England" },
      { name: "Kent", area: "England" },
      { name: "Lancashire", area: "England" },
      { name: "Leicestershire", area: "England" },
      { name: "Lincolnshire", area: "England" },
      { name: "Merseyside", area: "England" },
      { name: "Norfolk", area: "England" },
      { name: "North Yorkshire", area: "England" },
      { name: "Northamptonshire", area: "England" },
      { name: "Northumberland", area: "England" },
      { name: "Nottinghamshire", area: "England" },
      { name: "Oxfordshire", area: "England" },
      { name: "Shropshire", area: "England" },
      { name: "Somerset", area: "England" },
      { name: "South Yorkshire", area: "England" },
      { name: "Staffordshire", area: "England" },
      { name: "Suffolk", area: "England" },
      { name: "Tyne and Wear", area: "England" },
      { name: "Warwickshire", area: "England" },
      { name: "West Berkshire", area: "England" },
      { name: "West Midlands", area: "England" },
      { name: "West Sussex", area: "England" },
      { name: "West Yorkshire", area: "England" },
      { name: "Wiltshire", area: "England" },
      { name: "Worcestershire", area: "England" },
      { name: "Flintshire", area: "Wales" },
      { name: "Glamorgan", area: "Wales" },
      { name: "Merionethshire", area: "Wales" },
      { name: "Monmouthshire", area: "Wales" },
      { name: "Montgomeryshire", area: "Wales" },
      { name: "Pembrokeshire", area: "Wales" },
      { name: "Radnorshire", area: "Wales" },
      { name: "Anglesey", area: "Wales" },
      { name: "Breconshire", area: "Wales" },
      { name: "Caernarvonshire", area: "Wales" },
      { name: "Cardiganshire", area: "Wales" },
      { name: "Carmarthenshire", area: "Wales" },
      { name: "Denbighshire", area: "Wales" },
      { name: "Aberdeen City", area: "Scotland" },
      { name: "Aberdeenshire", area: "Scotland" },
      { name: "Angus", area: "Scotland" },
      { name: "Argyll and Bute", area: "Scotland" },
      { name: "City of Edinburgh", area: "Scotland" },
      { name: "Clackmannanshire", area: "Scotland" },
      { name: "Dumfries and Galloway", area: "Scotland" },
      { name: "Dundee City", area: "Scotland" },
      { name: "East Ayrshire", area: "Scotland" },
      { name: "East Dunbartonshire", area: "Scotland" },
      { name: "East Lothian", area: "Scotland" },
      { name: "East Renfrewshire", area: "Scotland" },
      { name: "Eilean Siar", area: "Scotland" },
      { name: "Falkirk", area: "Scotland" },
      { name: "Fife", area: "Scotland" },
      { name: "Glasgow City", area: "Scotland" },
      { name: "Highland", area: "Scotland" },
      { name: "Inverclyde", area: "Scotland" },
      { name: "Midlothian", area: "Scotland" },
      { name: "Moray", area: "Scotland" },
      { name: "North Ayrshire", area: "Scotland" },
      { name: "North Lanarkshire", area: "Scotland" },
      { name: "Orkney Islands", area: "Scotland" },
      { name: "Perth and Kinross", area: "Scotland" },
      { name: "Renfrewshire", area: "Scotland" },
      { name: "Scottish Borders", area: "Scotland" },
      { name: "Shetland Islands", area: "Scotland" },
      { name: "South Ayrshire", area: "Scotland" },
      { name: "South Lanarkshire", area: "Scotland" },
      { name: "Stirling", area: "Scotland" },
      { name: "West Dunbartonshire", area: "Scotland" },
      { name: "West Lothian", area: "Scotland" },
      { name: "Antrim", area: "Northern Ireland" },
      { name: "Armagh", area: "Northern Ireland" },
      { name: "Down", area: "Northern Ireland" },
      { name: "Fermanagh", area: "Northern Ireland" },
      { name: "Derry and Londonderry", area: "Northern Ireland" },
      { name: "Tyrone", area: "Northern Ireland" }
    ]
  },

  list: [
    {
      name: " -- Frequently Used",
      disable: true
    },
    {
      name: "United Kingdom",
      code: "GB",
      legal_code: 0,
    },
    {
      name: "Ireland",
      code: "IE",
      legal_code: 6,
    },
    {
      name: "Spain",
      code: "ES",
      legal_code: 2,
    },
    {
      name: "France",
      code: "FR",
      legal_code: 2,
    },
    {
      name: "United States",
      code: "US",
      legal_code: 6,
    },
    {
      name: " -- Alphabetical",
      disable: true
    },
    {
      name: "Afghanistan",
      code: "AF",
      legal_code: 3,
    },
    {
      name: "Albania",
      code: "AL",
      legal_code: 2,
    },
    {
      name: "Algeria",
      code: "DZ",
      legal_code: 4,
    },
    {
      name: "Andorra",
      code: "AD",
      legal_code: 2,
    },
    {
      name: "Angola",
      code: "AO",
      legal_code: 3,
    },
    {
      name: "Anguilla",
      code: "AI",
      legal_code: 0,
    },
    {
      name: "Antigua and Barbuda",
      code: "AG",
      legal_code: 6,
    },
    {
      name: "Argentina",
      code: "AR",
      legal_code: 2,
    },
    {
      name: "Armenia",
      code: "AM",
      legal_code: 2,
    },
    {
      name: "Aruba",
      code: "AW",
      legal_code: 6,
    },
    {
      name: "Australia",
      code: "AU",
      legal_code: 6,
    },
    {
      name: "Austria",
      code: "AT",
      legal_code: 2,
    },
    {
      name: "Azerbaijan",
      code: "AZ",
      legal_code: 2,
    },
    {
      name: "Bahamas",
      code: "BS",
      legal_code: 6,
    },
    {
      name: "Bahrain",
      code: "BH",
      legal_code: 2,
    },
    {
      name: "Bangladesh",
      code: "BD",
      legal_code: 3,
    },
    {
      name: "Barbados",
      code: "BB",
      legal_code: 6,
    },
    {
      name: "Belarus",
      code: "BY",
      legal_code: 2,
    },
    {
      name: "Belgium",
      code: "BE",
      legal_code: 2,
    },
    {
      name: "Belize",
      code: "BZ",
      legal_code: 2,
    },
    {
      name: "Benin",
      code: "BJ",
      legal_code: 3,
    },
    {
      name: "Bermuda",
      code: "BM",
      legal_code: 0,
    },
    {
      name: "Bhutan",
      code: "BT",
      legal_code: 3,
    },
    {
      name: "Bolivia",
      code: "BO",
      legal_code: 2,
    },
    {
      name: "Bosnia and Herzegovina",
      code: "BA",
      legal_code: 2,
    },
    {
      name: "Botswana",
      code: "BW",
      legal_code: 2,
    },
    {
      name: "Brazil",
      code: "BR",
      legal_code: 2,
    },
    {
      name: "Brunei Darussalam",
      code: "BN",
      legal_code: 2,
    },
    {
      name: "Bulgaria",
      code: "BG",
      legal_code: 2,
    },
    {
      name: "Burkina Faso",
      code: "BF",
      legal_code: 3,
    },
    {
      name: "Burundi",
      code: "BI",
      legal_code: 2,
    },
    {
      name: "Cambodia",
      code: "KH",
      legal_code: 3,
    },
    {
      name: "Cameroon",
      code: "CM",
      legal_code: 3,
    },
    {
      name: "Canada",
      code: "CA",
      legal_code: 6,
    },
    {
      name: "Cape Verde",
      code: "CV",
      legal_code: 2,
    },
    {
      name: "Cayman Islands",
      code: "CY",
      legal_code: 6,
    },
    {
      name: "Central African Republic",
      code: "CF",
      legal_code: 3,
    },
    {
      name: "Chad",
      code: "TD",
      legal_code: 3,
    },
    {
      name: "Chile",
      code: "CL",
      legal_code: 2,
    },
    {
      name: "China (Peoples Republic)",
      code: "CN",
      legal_code: 2,
    },
    {
      name: "Colombia",
      code: "CO",
      legal_code: 2,
    },
    {
      name: "Comoros",
      code: "KM",
      legal_code: 3,
    },
    {
      name: "Congo, Republic of (Brazzaville)",
      code: "CG",
      legal_code: 3,
    },
    {
      name: "Costa Rica",
      code: "CR",
      legal_code: 2,
    },
    {
      name: "Croatia",
      code: "HR",
      legal_code: 2,
    },
    {
      name: "Cuba",
      code: "CU",
      legal_code: 3,
    },
    {
      name: "Cyprus",
      code: "CY",
      legal_code: 2,
    },
    {
      name: "Czech Republic",
      code: "CZ",
      legal_code: 2,
    },
    {
      name: "Democratic Republic of the Congo (Kinshasa)",
      code: "CD",
      legal_code: 3,
    },
    {
      name: "Denmark",
      code: "DK",
      legal_code: 2,
    },
    {
      name: "Djibouti",
      code: "DJ",
      legal_code: 4,
    },
    {
      name: "Dominica",
      code: "DM",
      legal_code: 2,
    },
    {
      name: "Dominican Republic",
      code: "DO",
      legal_code: 2,
    },
    {
      name: "Ecuador",
      code: "EC",
      legal_code: 2,
    },
    {
      name: "Egypt",
      code: "EG",
      legal_code: 4,
    },
    {
      name: "El Salvador",
      code: "SV",
      legal_code: 2,
    },
    {
      name: "Equatorial Guinea",
      code: "GQ",
      legal_code: 3,
    },
    {
      name: "Eritrea",
      code: "ER",
      legal_code: 3,
    },
    {
      name: "Estonia",
      code: "EE",
      legal_code: 2,
    },
    {
      name: "Ethiopia",
      code: "ET",
      legal_code: 3,
    },
    {
      name: "Falkland Islands (Malvinas)",
      code: "FK",
      legal_code: 0,
    },
    {
      name: "Faroe Islands",
      code: "FO",
      legal_code: 6,
    },
    {
      name: "Fiji",
      code: "FJ",
      legal_code: 6,
    },
    {
      name: "Finland",
      code: "FI",
      legal_code: 2,
    },
    {
      name: "French Guiana",
      code: "GF",
      legal_code: 2,
    },
    {
      name: "Gabon",
      code: "GA",
      legal_code: 3,
    },
    {
      name: "Gambia",
      code: "GM",
      legal_code: 0,
    },
    {
      name: "Georgia",
      code: "GE",
      legal_code: 2,
    },
    {
      name: "Germany",
      code: "DE",
      legal_code: 2,
    },
    {
      name: "Ghana",
      code: "GH",
      legal_code: 0,
    },
    {
      name: "Gibraltar",
      code: "GI",
      legal_code: 0,
    },
    {
      name: "Greece",
      code: "GR",
      legal_code: 2,
    },
    {
      name: "Grenada",
      code: "GD",
      legal_code: 6,
    },
    {
      name: "Guatemala",
      code: "GT",
      legal_code: 2,
    },
    {
      name: "Guernsey",
      code: "GG",
      legal_code: 0,
    },
    {
      name: "Guinea",
      code: "GN",
      legal_code: 3,
    },
    {
      name: "Guinea-Bissau",
      code: "GW",
      legal_code: 3,
    },
    {
      name: "Guyana",
      code: "GY",
      legal_code: 2,
    },
    {
      name: "Haiti",
      code: "HT",
      legal_code: 3,
    },
    {
      name: "Honduras",
      code: "HN",
      legal_code: 2,
    },
    {
      name: "Hong Kong",
      code: "HK",
      legal_code: 6,
    },
    {
      name: "Hungary",
      code: "HU",
      legal_code: 2,
    },
    {
      name: "Iceland",
      code: "IS",
      legal_code: 2,
    },
    {
      name: "India",
      code: "IN",
      legal_code: 2,
    },
    {
      name: "Indonesia",
      code: "ID",
      legal_code: 2,
    },
    {
      name: "Iran, Islamic Republic Of",
      code: "IR",
      legal_code: 4,
    },
    {
      name: "Iraq",
      code: "IQ",
      legal_code: 4,
    },
    {
      name: "Isle of Man",
      code: "IM",
      legal_code: 0,
    },
    {
      name: "Israel",
      code: "IL",
      legal_code: 2,
    },
    {
      name: "Italy",
      code: "IT",
      legal_code: 2,
    },
    {
      name: "Ivory Coast",
      code: "CI",
      legal_code: 3,
    },
    {
      name: "Jamaica",
      code: "JM",
      legal_code: 6,
    },
    {
      name: "Japan",
      code: "JP",
      legal_code: 2,
    },
    {
      name: "Jersey",
      code: "JE",
      legal_code: 0,
    },
    {
      name: "Jordan",
      code: "JO",
      legal_code: 4,
    },
    {
      name: "Kazakhstan",
      code: "KZ",
      legal_code: 2,
    },
    {
      name: "Kenya",
      code: "KE",
      legal_code: 0,
    },
    {
      name: "Kiribati",
      code: "KI",
      legal_code: 0,
    },
    {
      name: "Korea, Democratic People'S Republic of",
      code: "KP",
      legal_code: 3,
    },
    {
      name: "South Korea, Republic of",
      code: "KR",
      legal_code: 2,
    },
    {
      name: "Kosovo",
      code: "XK",
      legal_code: 2,
    },
    {
      name: "Kuwait",
      code: "KW",
      legal_code: 4,
    },
    {
      name: "Kyrgyzstan",
      code: "KG",
      legal_code: 2,
    },
    {
      name: "Lao People'S Democratic Republic",
      code: "LA",
      legal_code: 3,
    },
    {
      name: "Latvia",
      code: "LV",
      legal_code: 2,
    },
    {
      name: "Lebanon",
      code: "LB",
      legal_code: 4,
    },
    {
      name: "Lesotho",
      code: "LS",
      legal_code: 0,
    },
    {
      name: "Liberia",
      code: "LR",
      legal_code: 2,
    },
    {
      name: "Libyan Arab Jamahiriya",
      code: "LY",
      legal_code: 4,
    },
    {
      name: "Liechtenstein",
      code: "LI",
      legal_code: 2,
    },
    {
      name: "Lithuania",
      code: "LT",
      legal_code: 2,
    },
    {
      name: "Luxembourg",
      code: "LU",
      legal_code: 2,
    },
    {
      name: "Macau",
      code: "MO",
      legal_code: 6,
    },
    {
      name: "Macedonia, Rep. of",
      code: "MK",
      legal_code: 2,
    },
    {
      name: "Madagascar",
      code: "MG",
      legal_code: 3,
    },
    {
      name: "Malawi",
      code: "MW",
      legal_code: 2,
    },
    {
      name: "Malaysia",
      code: "MY",
      legal_code: 3,
    },
    {
      name: "Maldives",
      code: "MV",
      legal_code: 3,
    },
    {
      name: "Mali",
      code: "ML",
      legal_code: 3,
    },
    {
      name: "Malta",
      code: "MT",
      legal_code: 2,
    },
    {
      name: "Marshall Islands",
      code: "MH",
      legal_code: 2,
    },
    {
      name: "Martinique",
      code: "MQ",
      legal_code: 3,
    },
    {
      name: "Mauritania",
      code: "MR",
      legal_code: 4,
    },
    {
      name: "Mauritius",
      code: "MU",
      legal_code: 2,
    },
    {
      name: "Mayotte",
      code: "YT",
      legal_code: 3,
    },
    {
      name: "Mexico",
      code: "MX",
      legal_code: 2,
    },
    {
      name: "Micronesia, Federated States of",
      code: "FM",
      legal_code: 3,
    },
    {
      name: "Moldova, Republic of",
      code: "MD",
      legal_code: 2,
    },
    {
      name: "Monaco",
      code: "MC",
      legal_code: 2,
    },
    {
      name: "Mongolia",
      code: "MN",
      legal_code: 2,
    },
    {
      name: "Montenegro",
      code: "ME",
      legal_code: 2,
    },
    {
      name: "Montserrat",
      code: "MS",
      legal_code: 0,
    },
    {
      name: "Morocco",
      code: "MA",
      legal_code: 2,
    },
    {
      name: "Mozambique",
      code: "MZ",
      legal_code: 3,
    },
    {
      name: "Myanmar, Burma",
      code: "MM",
      legal_code: 3,
    },
    {
      name: "Namibia",
      code: "NA",
      legal_code: 2,
    },
    {
      name: "Nauru",
      code: "NR",
      legal_code: 3,
    },
    {
      name: "Nepal",
      code: "NP",
      legal_code: 3,
    },
    {
      name: "Netherlands",
      code: "NL",
      legal_code: 2,
    },
    {
      name: "Netherlands Antilles",
      code: "AN",
      legal_code: 3,
    },
    {
      name: "New Caledonia",
      code: "NC",
      legal_code: 6,
    },
    {
      name: "New Zealand",
      code: "NZ",
      legal_code: 6,
    },
    {
      name: "Nicaragua",
      code: "NI",
      legal_code: 2,
    },
    {
      name: "Niger",
      code: "NE",
      legal_code: 3,
    },
    {
      name: "Nigeria",
      code: "NG",
      legal_code: 3,
    },
    {
      name: "Niue",
      code: "NU",
      legal_code: 2,
    },
    {
      name: "Norway",
      code: "NO",
      legal_code: 2,
    },
    {
      name: "Oman",
      code: "OM",
      legal_code: 2,
    },
    {
      name: "Pakistan",
      code: "PK",
      legal_code: 2,
    },
    {
      name: "Panama",
      code: "PA",
      legal_code: 2,
    },
    {
      name: "Papua New Guinea",
      code: "PG",
      legal_code: 3,
    },
    {
      name: "Paraguay",
      code: "PY",
      legal_code: 2,
    },
    {
      name: "Peru",
      code: "PE",
      legal_code: 2,
    },
    {
      name: "Philippines",
      code: "PH",
      legal_code: 2,
    },
    {
      name: "Poland",
      code: "PL",
      legal_code: 2,
    },
    {
      name: "Portugal",
      code: "PT",
      legal_code: 2,
    },
    {
      name: "Puerto Rico",
      code: "PR",
      legal_code: 3,
    },
    {
      name: "Qatar",
      code: "QA",
      legal_code: 4,
    },
    {
      name: "Reunion Island",
      code: "RE",
      legal_code: 3,
    },
    {
      name: "Romania",
      code: "RO",
      legal_code: 2,
    },
    {
      name: "Russian Federation",
      code: "RU",
      legal_code: 2,
    },
    {
      name: "Rwanda",
      code: "RW",
      legal_code: 2,
    },
    {
      name: "Saint Helena",
      code: "SH",
      legal_code: 3,
    },
    {
      name: "Saint Kitts and Nevis",
      code: "KN",
      legal_code: 6,
    },
    {
      name: "Saint Lucia",
      code: "LC",
      legal_code: 6,
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "VC",
      legal_code: 6,
    },
    {
      name: "Samoa",
      code: "WS",
      legal_code: 2,
    },
    {
      name: "San Marino",
      code: "SM",
      legal_code: 2,
    },
    {
      name: "Sao Tome and Principe",
      code: "ST",
      legal_code: 2,
    },
    {
      name: "Saudi Arabia",
      code: "SA",
      legal_code: 2,
    },
    {
      name: "Senegal",
      code: "SN",
      legal_code: 2,
    },
    {
      name: "Serbia",
      code: "CS",
      legal_code: 2,
    },
    {
      name: "Seychelles",
      code: "SC",
      legal_code: 2,
    },
    {
      name: "Sierra Leone",
      code: "SL",
      legal_code: 3,
    },
    {
      name: "Singapore",
      code: "SG",
      legal_code: 6,
    },
    {
      name: "Slovakia",
      code: "SK",
      legal_code: 2,
    },
    {
      name: "Slovenia",
      code: "SI",
      legal_code: 2,
    },
    {
      name: "Solomon Islands",
      code: "SB",
      legal_code: 3,
    },
    {
      name: "Somalia",
      code: "SO",
      legal_code: 4,
    },
    {
      name: "South Africa",
      code: "ZA",
      legal_code: 6,
    },
    {
      name: "Sri Lanka",
      code: "LK",
      legal_code: 6,
    },
    {
      name: "Sudan",
      code: "SD",
      legal_code: 4,
    },
    {
      name: "Suriname",
      code: "SR",
      legal_code: 2,
    },
    {
      name: "Swaziland",
      code: "SZ",
      legal_code: 6,
    },
    {
      name: "Sweden",
      code: "SE",
      legal_code: 2,
    },
    {
      name: "Switzerland",
      code: "CH",
      legal_code: 2,
    },
    {
      name: "Syrian Arab Republic",
      code: "SY",
      legal_code: 4,
    },
    {
      name: "Tajikistan",
      code: "TJ",
      legal_code: 2,
    },
    {
      name: "Tahiti",
      code: "PF",
      legal_code: 6,
    },
    {
      name: "Taiwan (Republic of China)",
      code: "TW",
      legal_code: 3,
    },
    {
      name: "Tanzania",
      code: "TZ",
      legal_code: 3,
    },
    {
      name: "Thailand",
      code: "TH",
      legal_code: 3,
    },
    {
      name: "Timor-Leste (East Timor)",
      code: "TL",
      legal_code: 3,
    },
    {
      name: "Togo",
      code: "TG",
      legal_code: 3,
    },
    {
      name: "Tonga",
      code: "TO",
      legal_code: 2,
    },
    {
      name: "Trinidad and Tobago",
      code: "TT",
      legal_code: 6,
    },
    {
      name: "Tunisia",
      code: "TN",
      legal_code: 2,
    },
    {
      name: "Turkey",
      code: "TR",
      legal_code: 2,
    },
    {
      name: "Turkmenistan",
      code: "TM",
      legal_code: 3,
    },
    {
      name: "Turks and Caicos Islands",
      code: "TC",
      legal_code: 0,
    },
    {
      name: "Tuvalu",
      code: "TV",
      legal_code: 0,
    },
    {
      name: "Uganda",
      code: "UG",
      legal_code: 0,
    },
    {
      name: "Ukraine",
      code: "UA",
      legal_code: 2,
    },
    {
      name: "United Arab Emirates",
      code: "AE",
      legal_code: 3,
    },
    {
      name: "Uruguay",
      code: "UY",
      legal_code: 2,
    },
    {
      name: "Uzbekistan",
      code: "UZ",
      legal_code: 2,
    },
    {
      name: "Vanuatu",
      code: "VU",
      legal_code: 2,
    },
    {
      name: "Venezuela",
      code: "VE",
      legal_code: 2,
    },
    {
      name: "Vietnam",
      code: "VN",
      legal_code: 3,
    },
    {
      name: "Virgin Islands (British)",
      code: "VG",
      legal_code: 0,
    },
    {
      name: "Yemen",
      code: "YE",
      legal_code: 4,
    },
    {
      name: "Zambia",
      code: "ZM",
      legal_code: 0,
    },
    {
      name: "Zimbabwe",
      code: "ZW",
      legal_code: 0,
    }
  ]
};
