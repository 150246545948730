import * as types from "src/store/constants";

export const fetchNotaries = (context) => {
  context.dispatch(
    "people/loadWhere",
    {
      filter: { role_id: [types.ROLES.admin, types.ROLES.notary] },
      options: { include: ["bankdetails"] },
    },
    { root: true }
  );
};

export const fetchUsersettingsPeopleRecords = async (
  context
) => {
  const id = context.rootGetters['submissions/id'];
  await context.dispatch(
    "usersettingsPeople/loadWhere",
    {
      filter: { person_id: id },
      options: { include: ["people"] },
    },
    { root: true }
  );
  await context.dispatch("people/loadRelated",
  {
    parent: {
      id: id,
      type: context.rootGetters['submissions/entity'].type,
    },
    relationship: "usersettingsPeople",
  }
);
};
