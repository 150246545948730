import { strstr, ucfirst } from "locutus/php/strings";
import { empty } from "locutus/php/var";
import _ from "lodash";
import { union } from "set-manipulator";
import {
  checker,
  getAssociationTargetTable,
  getRelationshipNameFromAssociation,
  relationshipsAreEqual,
  snakeToLowerCamel,
  strip,
} from "src/utils";

const logName = "documents";

export { getField } from "src/utils/vuex-map-fields";

/**
 * If we are passing in an association object, then there's no need for the schema, it just passes the object back.
 * Otherwise if mixed is a string, it will look up the association for the given schema by name
 * @param {object|string} mixed
 * @param {string} schema
 * @returns {object} association
 */
export const associationDammit = (state, getters) => (mixed, schema) => {
  let name, association;
  if (_.isString(mixed)) {
    if (!_.isString(schema) || empty(schema)) {
      throw new Error(
        "type error: {string} schema – must be present, as `mixed` is a string"
      );
    }
    name = ucfirst(mixed);
    association = getters.getAssociationByName(schema, name);
  } else if (_.isObject(mixed) && !_.isArray(mixed)) {
    name = mixed.name;
    association = mixed;
  } else {
    throw new Error("type error: {string|object} mixed");
  }
  return association;
};

/**
 * Given a single association (by name or as object)
 * @param {string} schema store name
 * @param {string | object} associationMixed association name or object
 * @param {string | number | object} entity
 * @param {object|array|string} pathOrData the related data for a single association against which to check the entity
 * @returns {boolean}
 */
export const associationIsDirty =
  (state, getters, rootState, rootGetters) => (options) => {
    const { schema, associationMixed, entity, pathOrData, from, debug } =
      options;

    if (!_.isString(schema)) throw Error("type error: {string} schema");

    if (empty(entity)) {
      return false;
    }
    if (empty(associationMixed)) {
      return;
    }

    const association = getters.associationDammit(associationMixed, schema);

    const dataParams = {
      pathOrData,
      entity,
      association,
    };
    const data = getters.normalisePathOrData(dataParams);

    let splitRelationships = getters.splitRelationships({
      schema,
      entity,
      association,
      pathOrData: data,
      from: "associationIsDirty",
      debug,
    });

    
    // Filter oldRelationships based on emptyModel keys and then omit specified keys
    const getEmptyModel = (type) => getters.emptyRow(type);
    const filterKeys = (obj, allowedAttributes) => _.pick(obj, allowedAttributes);
    
    if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS || (debug && debug == association.name)) {
      console.log('associationIsDirty filterKeys', filterKeys);
      console.log("debug", debug)
      console.log("📔 debugging " + debug + ": associationIsDirty: " + association.name + " - splitRelationships prior to filtering", splitRelationships);
    }

    if(Array.isArray(splitRelationships.oldRelationships) && splitRelationships.oldRelationships.length > 0) {

      splitRelationships.oldRelationships = splitRelationships.oldRelationships.map((record) => {
        // console.log('associationIsDirty original record', record);
        const emptyModel = getEmptyModel(record.type);
        // console.log('associationIsDirty emptyModel', emptyModel);
        const allowedAttributes = Object.keys(emptyModel.attributes);
        // console.log('associationIsDirty allowedAttributes', allowedAttributes);
        const filteredAttributes = filterKeys(record.attributes, allowedAttributes);
        // console.log('associationIsDirty filteredAttributes', filteredAttributes);
        const cleanedRecord = {...record};
        cleanedRecord.attributes = filteredAttributes;
        // console.log('associationIsDirty cleanedRecord', cleanedRecord);
        return cleanedRecord;
      });
    }

    if(Array.isArray(splitRelationships.newRelationships) && splitRelationships.newRelationships.length > 0) {
      splitRelationships.newRelationships = splitRelationships.newRelationships.map((record) => {
        // console.log('associationIsDirty original record', record);
        const emptyModel = getEmptyModel(record.type);
        // console.log('associationIsDirty emptyModel', emptyModel);
        const allowedAttributes = Object.keys(emptyModel.attributes);
        // console.log('associationIsDirty allowedAttributes', allowedAttributes);
        const filteredAttributes = filterKeys(record.attributes, allowedAttributes);
        // console.log('associationIsDirty filteredAttributes', filteredAttributes);
        const cleanedRecord = {...record};
        cleanedRecord.attributes = filteredAttributes;
        // console.log('associationIsDirty cleanedRecord', cleanedRecord);
        return cleanedRecord;
      });
    }

    
    if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS || (debug && debug == association.name)) {
      console.log("dirtyAssociations args for associationIsDirty", options);
      console.log("splitRelationships post filtering", splitRelationships);
    }

    const associationIsDirty = !relationshipsAreEqual(splitRelationships, debug);
    
    if (debug && debug == association.name) {
      console.log("DEBUG association", debug);
      console.log("associationIsDirty args", options);
      console.log("data", data);
      console.log("associationIsDirty", associationIsDirty);
    }
    return associationIsDirty;
  };

export const associations = (state) => (name) => {
  return state.data.schemas[name].associations;
  // return _.get(state, `data.schemas[${name}].associations`, {}); // option to prevent undefind, but not as performant
};

export const associationNames = (state, getters) => (name) => {
  return getters.associations(name).map((x) => x.name);
}

export const associationDataType = (state) => (schema, association) => {
  return this.typeMap(schema)[`${association.name}Id`];
};

// get the var type expected for a given column in a schema
export const attributeDataType = (state) => (schema, column) => {
  return this.typeMap(schema)[column];
};

/**
 * @returns (array|boolean} list of attribute names for this schema or false
 */
export const attributeNames =
  (state, getters) =>
  /**
   * @param {object} options
   * - @param {string} schema - cake table name / vuex store name
   * - @param {array} ignore - array of proprerties to ignore
   */
  (options) => {
    // console.log("isDirtyAttributes options", options);
    const attributeNames =
      typeof options.ignore !== "undefined" && Array.isArray(options.ignore)
        ? getters
            .namesOfEditableAttributes(options.schema)
            .filter((x) => !options.ignore.includes(x))
        : getters.namesOfEditableAttributes(options.schema);
    if (empty(attributeNames)) {
      return false;
    }
    return attributeNames;
  };

/**
 * Similar to getters.related but will convert m21 relationship
 * names to singular before running.
 * @param {boject} state
 * @param {object} getters
 * @param {object} rootState
 * @param {object} rootGetters
 */
export const cakeRelated =
  (state, getters, rootState, rootGetters) =>
  /**
   * @todo this should be split into two functions:
   * - one to get relationshipName and gettername
   * - another to use that to get the related record
   * @param {string} schemaName i.e. cake Table name against which to find related items
   * @param {object} options
   * -- @param {object} parent restate entity
   * -- @param {object|string} association will get association object from name or use provided object
   * -- @param {string} getterName optional override for the relationship name (may be singular e.g.)
   * -- @param {string} debug optional association for logging
   * @return {object|array}
   */
  (schemaName, options) => {
    let type,
      getter,
      gotten,
      got = getters["dataTypeFromAssociationType"](options.association),
      relationshipName = "",
      getterName = "",
      associationObj;
    const { debug } = options;

    if (options.getterName) getterName = options.getterName;
    if (_.isString(options.association)) {
      relationshipName = getters.relationshipNameFromAssociation(
        schemaName,
        options.association
      );
      getterName = getterName || snakeToLowerCamel(options.association);
      associationObj = getters.getAssociationByName(
        schemaName,
        options.association
      );
    } else {
      relationshipName = getRelationshipNameFromAssociation(
        options.association
      );

      function determineGetterName(association, getterName) {
        if (!empty(getterName)) return getterName;
        return snakeToLowerCamel(association.name);
      }

      getterName = determineGetterName(options.association, getterName);
      associationObj = options.association;
    }

    // console.log('cakeRelated options',options );
    // console.log('cakeRelated debug',debug );
    // console.log('associationObj.name',associationObj.name );

    const targetTable = getAssociationTargetTable(associationObj);
    const parent = { id: options.parent.id, type: options.parent.type };

    if (
      (debug && debug == associationObj.name) ||
      process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS ||
      process.env.DEBUG_CAKERELATED
    ) {
      console.info("📙 : >>>>> getters['schemas/CakeRelated']", relationshipName);
      console.log("relationshipName", relationshipName);
      console.log("getterName", getterName);
      console.log("schemaName", schemaName);
      console.log("options.association", options.association);
      console.log(
        "included cakeRelated schemaName (used only to determine relationshipName:",
        schemaName
      );
      console.log("included cakeRelated options:", options);
      console.log(
        "included cakeRelated relationship targetTable:",
        targetTable
      );
      console.log("relationshipName:", relationshipName);
      console.log("parent:", parent);
      console.log("association.type:", associationObj.type);
      console.log("association.name:", associationObj.name);
    }

    const getIt = function (getter, parent, relationship) {
      const getterOptions = {
        parent,
        relationship,
      }
      if (typeof getter !== "undefined") {
        if (
          (debug && debug == associationObj.name) ||
          process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS ||
          process.env.DEBUG_CAKERELATED
        ) {
          console.log("::: get related");
          console.log("getter", getter);
          console.log("getIt parent", parent);
          console.log("getIt relationship", relationship);
          console.log("getIt relationship", relationship);
          console.log("getIt options", getterOptions);
        }
        return getter(getterOptions);
      }
    };

    //- @why: if the targetTable and relationship name differ, then check both stores because JSONAPI's include will store to one, while loadRelated will store to the other and both these methods are used.
    if (targetTable !== relationshipName) {
      let gottenA =
        getIt(
          rootGetters[`${targetTable}/related`],
          parent,
          relationshipName
        ) ?? [];
      let gottenB =
        getIt(rootGetters[`${getterName}/related`], parent, relationshipName) ??
        [];
      if (!Array.isArray(gottenA)) gottenA = [gottenA];
      if (!Array.isArray(gottenB)) gottenB = [gottenB];
      gotten = union(gottenA, gottenB, (o) => o.id);
      if (gotten.length === 1) gotten = gotten[0];

      if (
        (debug && debug == associationObj.name) ||
        process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS ||
        process.env.DEBUG_CAKERELATED
      ) {
        console.log(">> searching store by cakeRelated gettername", getterName);
        console.log(">> searching store by targetTable", targetTable);
        console.log("gottenA", gottenA);
        console.log("gottenB", gottenB);
        console.log("gotten 1", gotten);
      }
    } else {
      if (
        process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS ||
        process.env.DEBUG_CAKERELATED
      ) {
        console.log(
          ">> searching store by cakeRelated gettername",
          targetTable
        );
        console.log("gotten 2", gotten);
      }
      gotten = getIt(
        rootGetters[`${targetTable}/related`],
        parent,
        relationshipName
      );
    }

    if (!empty(gotten)) got = gotten;
    if (
      (debug && debug == associationObj.name) ||
      process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS ||
      process.env.DEBUG_CAKERELATED
    ) {
      console.log("got", got);
    }
    return got;
  };

export const columns = (state) => (name) => {
  return state.data.schemas[name].columns;
};

export const constraints = (state) => (name) => {
  return state.data.schemas[name].constraints;
  // return _.get(state, `data.schemas[${name}].constraints`, {}); // option to prevent undefind, but not as performant
};

export const data = (state) => {
  return state.data;
};

export const dataTypeFromAssociationType =
  (state, getters) => (association) => {
    // console.log("dataTypeFromAssociationType association", association);
    if (["oneToMany", "manyToMany"].includes(association.type)) return [];
    return {};
  };

export const defaultValues = (state) => (name) => {
  return state.data.schemas[name].defaults;
  // return _.get(state, `data.schemas[${name}].defaults`, {}); // option to prevent undefind, but not as performant
};

export const dirtyAttributes =
  (state, getters) =>
  /**
   * @param {object} options
   * - @param {string} schema - cake table name / vuex store name
   * - @param {object|string} entity - either @reststate record or id
   * - @param {object} attributes - the data structure to test this entity against
   * - @param {array} ignore - array of proprerties to ignore
   */
  (options) => {
    const attributeNames = getters.attributeNames(options);
    const reststateRecord = getters.entityDammit(
      options.schema,
      options.entity
    );
    // console.log("dirtyAttributes e", reststateRecord);
    const dirtyAttributes = attributeNames.reduce((next, name) => {
      const isEqual = _.isEqualWith(
        reststateRecord.attributes[name],
        options.attributes[name],
        checker
      );
      // console.log("name", name);
      // console.log("reststateRecord.attributes", reststateRecord.attributes);
      // console.log("options.attributes", options.attributes);
      // console.log("key", name);
      // console.log("val1", reststateRecord.attributes[name]);
      // console.log("val2", options.attributes[name]);
      // console.log("equal", isEqual);
      if (!isEqual) {
        next.push(name);
      }
      return next;
    }, []);

    return dirtyAttributes;
  };

export const dirtyAssociations =
  (state, getters) =>
  /**
   * @param {object} options
   * - @param {string} schema - cake table name / vuex store name
   * - @param {object|string} entity - either @reststate record or id
   * - @param {array} limit - array of association names to limit this test to
   * - @param {object, string} pathOrData - the data structure to test this entity against; i.e. `.related` from the form for this entity, which is a list of all the related data, keyed by association name
   * - @param {string} from - optional stack trace
   * - @param {string} debug - optional association for logging
   */
  (options) => {

    // console.log("schemas/dirtyAssociations", options);

    let { pathOrData, entity, limit, schema, from, debug } = options;
    // debug = "Clients"
    const associations = getters.associations(schema);
    let dirtyAssociations = [];
    if (!associations.length) return dirtyAssociations;

    const associationsToProcess =
      typeof options.limit !== "undefined" && Array.isArray(limit)
        ? associations.filter((a) => limit.includes(a.name))
        : associations;

    // console.log(
    //   "schemas/dirtyAssociations associationsToProcess",
    //   associationsToProcess
    // );

    // chenage this to match your desired association
    if (from === 'butUsersettingsPeopleton') {
      console.log("dirtyAssociations from", from);
      console.log("dirtyAssociations entity", entity);
      console.log(
        "dirtyAssociations eligible associations",
        associations.map((i) => i.name)
      );
      console.log(
        "dirtyAssociations filtered associations",
        associationsToProcess.map((i) => i.name)
      );
    }

    _.each(associationsToProcess, (association) => {
      const data = getters.normalisePathOrData({
        pathOrData,
        entity,
        association,
        from: "dirtyAssociations",
      });
      const args = {
        schema,
        associationMixed: association,
        entity,
        pathOrData: _.get(data, association.name, {}),
        debug,
      };
      if (getters.associationIsDirty(args)) {
        if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS) {
          console.log("isDirty found to be TRUE for - ", association.name);
        }
        dirtyAssociations.push(association);
      } else {
        if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS) {
          console.log("isDirty found to be FALSE for - ", association.name);
        }
      }
    });
    if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS) {
      console.log("dirtyAssociations found: ", dirtyAssociations);
    }
    return dirtyAssociations;
  };

/**
 * @param {string} schemaName
 * @param {string} uid for INSERT context, use the `getEmptyRow` action instead, which will generate a uid. For other contexts, pass the uid here
 * @returns {object} an empty form object, capable of holding data for this schema
 */
export const emptyRow =
  (state, getters) =>
  (schemaName, uid, insert = true) => {
    if (empty(getters.schemas)) {
      return false;
    }
    const row = {};
    row.attributes = {};
    row.type = schemaName;
    row.id = uid; // required by LoaderMixin, which will fail if id is undefined. Set uid here, then its immediately available to all copies of this object
    row.insert = insert; // a flag for SaverMixin to pick up that this is an INSERT, not an UPDATE. @todo: This flag should be auto-deleted by the save process but may be best to manually delete this property when the queue runs.
    const typeMap = getters.typeMap(schemaName);
    const defaultValues = getters.defaultValues(schemaName);
    const attributeNames = getters.namesOfEditableAttributes(schemaName);

    _.each(attributeNames, (columnName) => {
      const type = typeMap[columnName];
      if (type === "array") {
        row.attributes[columnName] = [];
      } else if (type === "object") {
        row.attributes[columnName] = {};
      } else if (type === "boolean") {
        row.attributes[columnName] =
          defaultValues[columnName] === null ||
          defaultValues[columnName] === undefined
            ? null
            : Boolean(Number(defaultValues[columnName]));
      } else if (type === "string") {
        row.attributes[columnName] = defaultValues[columnName] || "";
      } else if (type === "integer") {
        row.attributes[columnName] = Number(defaultValues[columnName]) || null;
      } else if (type === "uuid") {
        row.attributes[columnName] = defaultValues[columnName] || "";
      } else if (type === "json") {
        //@why - we can't tell dynamically whether json is storing array or object
        //@todo: this is a fragile hack because it assumes unique json column names across tables
        if (["country", "county"].includes(columnName)) {
          row.attributes[columnName] = {};
        } else {
          row.attributes[columnName] = [];
        }
      } else {
        row.attributes[columnName] = defaultValues[columnName] || null;
      }
    });
    row.related = getters.relatedCollectonNodes(schemaName);
    // console.log("emptyRow uid", uid);
    //- return a clone, which thus won't alias any entities referenced during data collection
    return _.cloneDeep(row);
  };

export const entityDammit =
  (state, getters, rootState, rootGetters) =>
  /**
   * @param {string} schema name of schema
   * @param {*} value – {object}: returns object, {string|number}: looks up entity via reststate; if not found, returns emptyRow
   */
  (schema, value) => {
    // console.log("entityDammit schema", schema);
    // console.log("entityDammit value", value);
    // console.log("entityDammit typeof value", typeof value);
    if (!_.isArray(value) && _.isObject(value)) return value;
    if (!_.isString(schema))
      throw Error("entityDammit schema must be a string");
    if (empty(value)) return rootGetters["schemas/emptyRow"](schema, null);
    if (typeof value === "number" || typeof value === "string") {
      // console.log("entityDammit retrieve by Id", value);
      const record = rootGetters[`${schema}/byId`]({ id: value });
      // console.log("reststateRecord", record);
      return record || rootGetters["schemas/emptyRow"](schema, value);
    }
    return rootGetters["schemas/emptyRow"](schema, null);
  };

/**
 * @param {object} state
 * @param {object} getters
 * @param {string} name Association name
 * @returns {object} association object
 */
export const getAssociationByName =
  (state, getters) => (schemaName, associationName) => {
    const association = getters
      .associations(schemaName)
      .filter((x) => x.name === associationName);
    if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS) {
      console.log(
        "associations",
        getters.associations(schemaName).map((x) => x.name)
      );
      console.log("association", association);
    }
    return association[0] || null;
  };

export const getEntityAssociations =
  (state, getters) =>
  (entity, from = "unknown") => {
    if (typeof entity === "undefined" || typeof entity.type === "undefined") {
      return [];
    }
    return getters["associations"](entity.type);
  };

export const getFormFields = (state, getters) => (schemaName) => {
  return getters.namesOfEditableAttributes(schemaName).map((x) => {
    const typeMap = getters.typeMap(schemaName);
    switch (typeMap[x]) {
      case "datetime":
        return {
          name: x,
          component: "mnr-date-time",
        };

      default:
        return {
          name: x,
          component: "q-input",
        };
    }
  });
};

/**
 * @param {string} schemaName
 * @returns {object} column config for q-table based on schema
 */
export const getTableColumnFromSchema = (state, getters) => (schemaName) => {
  return getters.namesOfSaveableAttributes(schemaName).map((x) => {
    return {
      name: x,
      label: x,
      field: (row) => row.attributes[x],
      align: "left",
    };
  });
};

export const indexes = (state) => (name) => {
  return state.data.schemas[name].indexes;
  // return _.get(state, `data.schemas[${name}].indexes`, {}); // option to prevent undefind, but not as performant
};

/**
 * @todo: there is an unfortunate data conflict here wrt primeAssociations / defaultAssociations.
 * That distinction wasn't made initially and isDirty checks defaultAssociations,
 * not the primeAssociations set by the component, probably in an attempt to avoid currying.
 * @param {object} options: - including `limit` which is the list of associations to check
 * @returns
 */
export const isDirtyAssociations = (state, getters) => (options) => {
  // console.log("isDirtyAssociations options", options);
  options.from = options.from
    ? options.from
    : " schema getters isDirtyAssociations";

  // options.debug = 'Clients';
  
  const result = getters.dirtyAssociations(options);
  // console.log("isDirtyAssociations options / result - ", options, result);
  return result.length ? true : false;
};

/**
 * @why This is a noise filter. JavaScript's fluidity of Array type (which is really a specialised Object) is used by
 * vuex to polute the data space with things like observables and similar. Thus comparing a and b is not so simple.
 * @returns {boolean}
 */
export const isDirtyAttributes =
  (state, getters, rootState, rootGetters) =>
  /**
   * @param {object} options
   * - @param {string} schema - cake table name / vuex store name
   * - @param {object|string} entity - either @reststate record or id
   * - @param {object} attributes - the data structure to test this entity against
   * - @param {array} ignore - array of proprerties to ignore
   * - @param {string} from - for debugging (as trace isn't helpful here)
   */
  (options) => {
    // console.log("isDirtyAttributes options", options);
    if (options.attributes === undefined) return false;
    const attributeNames = getters.attributeNames(options);
    const e = getters.entityDammit(options.schema, options.entity);

    // console.log('options.schema', options.schema);

    // console.log(
    //   ">>>>>>>>>>>>>> isDirtyAttributes for ",
    //   options.schema,
    //   options.entity.id || options.entity
    // );
    // console.log("isDirtyAttributes from", options.from);
    // console.log("isDirtyAttributes options", options);
    // console.log("isDirtyAttributes entity", e);
    // console.log("isDirtyAttributes data to compare", options.data);

    const isEqualResult = attributeNames.reduce((next, name) => {
      if (options.attributes[name] === undefined) {

        //- @debug: what does this actually mean? It means that an attribute key in the data we're comparing to the store is missing. This can happen e.g. for hidden fields such as password
        // console.error(
        //   "DataObject does not match schema - options.attribute is undefined for key '" +
        //     name +
        //     "' on schema '" +
        //     options.schema + "'"
        // );
        return next
      }
      try {
        const isEqual = _.isEqualWith(
          e.attributes[name],
          options?.attributes?.[name],
          checker
        );
        // console.log("name", name);
        // console.log("isEqual", isEqual);
        next = isEqual ? next : false;
        return next;
      } catch (error) {
        // console.log("options", options);
        // console.log("e.attributes", e.attributes);
        // console.log("options.attributes", options.attributes);
        // console.log("attributeNames", attributeNames);
        console.error(
          "attribute missing: " + name + " on schema: " + options.schema
        );
        console.error(error);
      }
    }, true);

    // console.log("isDirtyAttributes isEqualResult", isEqualResult);
    // console.log("isDirtyAttributes isDirty", !isEqualResult);
    return !isEqualResult;
  };

export const isError = (state) => {
  return state.isError;
};

export const isLoaded = (state) => {
  return state.isLoaded;
};

/**
 * Is the association a "toOne" association
 * @param {string} schema
 * @param {object|string} associationMixed
 * @returns {boolean}
 */
export const isToOneAssociation =
  (state, getters) => (schema, associationMixed) => {
    const association = getters.associationDammit(associationMixed, schema);
    return Boolean(strstr(association.type, "ToOne"));
  };

export const lastError = (state) => {
  return state.lastError;
};

/**
 * @deprecated: shouldn't be different from editable as created and modified are handled BE
 */
export const namesOfSaveableAttributes = (state, getters) => (name) => {
  return getters.columns(name).filter((x) => {
    return !x.endsWith("Id") && x !== "id";
  });
};

export const namesOfEditableAttributes = (state, getters) => (name) => {
  return getters.columns(name).filter((x) => {
    return (
      !x.endsWith("Id") && x !== "id" && x !== "created" && x !== "modified"
    );
  });
};

/**
 *
 * @param {*} options
 * - @param {*} pathOrData
 * - @param {*} entity
 * - @param {*} association
 * @returns
 */
export const normalisePathOrData =
  (state, getters, rootState, rootGetters) => (options) => {
    let data;
    const { pathOrData, entity, association, from } = options;
    // console.log("normalisePathOrData entity", entity);
    if (empty(entity)) {
      console.error("normalisePathOrData entity option is undefined");
      return pathOrData;
    }
    if (empty(association)) {
      console.error("normalisePathOrData association option is undefined");
      return pathOrData;
    }
    // if (from) // console.log("normalisePathOrData from", from);

    // if parameter is not provided, then see if it can be calculated from prime
    if (typeof pathOrData == "undefined") {
      if (rootGetters["submissions/isPrimeEntity"](entity)) {
        const path = `${rootGetters["submissions/formDataPath"]}.related.${association.name}`;
        if (!empty(path)) data = rootGetters["submissions/getField"](path);
      }
    }
    // if string, then get from the path
    if (typeof pathOrData == "string") {
      data = rootGetters["submissions/getField"](pathOrData);
    }
    if (_.isObject(pathOrData) && empty(pathOrData)) {
      data = getters.dataTypeFromAssociationType(association);
    }
    data = data ? strip(data) : strip(pathOrData);
    return data;
  };

export const oldRelationships =
  (state, getters, rootState, rootGetters) => (entity, association, debug) => {
    if (empty(association)) {
      throw new Error("oldRelationships missing Association argument");
    }

    const schemaName = rootGetters["submissions/schema"];
    const params = {
      parent: entity,
      association,
      from: "$service oldRelationships " + association.name,
      debug: debug /*|| 'Clients'*/, // Set default value for debug if not provided
    };

    let oldRelationships =
      entity === undefined
        ? []
        : getters.relatedByAssociation(schemaName, params);

    if (
      (debug && debug == association.name) ||
      process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS
    ) {
      console.log("DEBUG schemas/oldRelationships ", entity.type);
      console.log(
        "DEBUG schemas/oldRelationships association.name",
        association.name
      );
      console.log("DEBUG schemas/oldRelationships association", association);
      console.log(
        "DEBUG schemas/oldRelationships oldRelationships schemaName",
        schemaName
      );
      console.log(
        "DEBUG schemas/oldRelationships oldRelationships params",
        params
      );
      console.log(
        "DEBUG schemas/oldRelationships oldRelationships",
        oldRelationships
      );
    }

    // Convert single object to array to make old and new iterable
    const isArrayOldRelationships = _.isArray(oldRelationships);
    oldRelationships = isArrayOldRelationships
      ? [...oldRelationships.map((obj) => strip(obj))] // Remove cruft
      : empty(oldRelationships)
      ? []
      : [oldRelationships];

    return oldRelationships;
  };

export const options = (state) => (name) => {
  return state.data.schemas[name].options;
  // return _.get(state, `data.schemas[${name}].options`, {}); // option to prevent undefind, but not as performant
};

export const paginationDefaults = (state) => {
  return state.paginationDefaults;
};

export const pagination = (state) => {
  return state.pagination;
};

export const paginator = (state, getters, rootState) => (schema) => {
  // console.log('PAGINATOR',  {...(rootState.submissions?.forms?.[schema]?.pagination ?? {}) });

  const paginator = {
    ...getters.paginationDefaults,
    ...getters.pagination,
    ...(rootState.submissions?.forms?.[schema]?.pagination ?? {}),
  };
  // console.log('paginator for '+schema, paginator );
  return paginator;
};

export const perPageOptions = (state) => {
  return state.perPageOptions;
};

/**
 * Alias to cakeRelated
 * @param {object} state
 * @param {object} getters
 */
export const relatedByAssociation =
  (state, getters) => (schemaName, options) => {
    let { debug } = options;
    let nuOptions = _.cloneDeep(options);
    if (typeof options.association == "string") {
      nuOptions.association = getters.getAssociationByName(
        schemaName,
        options.association
      );
    }
    if (
      (debug && debug == options.association) ||
      process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS
    ) {
      if (!schemaName) {
        console.trace();
      }
      console.log(
        "getters schemas/relatedByAssociation.schemaName",
        schemaName
      );
      console.log("getters schemas/relatedByAssociation.options", nuOptions);
    }
    return getters.cakeRelated(schemaName, nuOptions);
  };

/**
 * Returns a set `related` form data nodes of the correct type for each type of association
 * @param {object} state
 * @param {object} getters
 * @param {string} schemaName
 * @returns {object}
 */
export const relatedCollectonNodes = (state, getters) => (schemaName) => {
  const associations = getters.associations(schemaName);
  if (associations && associations.length) {
    const collectionNodes = {};
    _.each(associations, (association) => {
      collectionNodes[association.name] =
        getters["dataTypeFromAssociationType"](association);
    });

    return collectionNodes;
  }
  return {};
};

/**
 * Given an association name, this will find the association and get the singular form if type is m21
 * @param {object} state
 * @param {objet} getters
 * @param {object} rootState
 * @returns {string} relationship name
 */
export const relationshipNameFromAssociation =
  (state, getters) => (schemaName, associationName) => {
    //- check the getterName against the parent's associations. If it is an association of type `manyToOne`, then use the singular form of the association name, instead of the plural.
    const association = getters.getAssociationByName(
      schemaName,
      associationName
    );
    let relationshipName = associationName;
    if (association) {
      relationshipName = getRelationshipNameFromAssociation(association);
    }
    return relationshipName;
  };

export const schemas = (state) => {
  return state.data.schemas;
};

/**
 * Compares oldRelationships (i.e. the relationships that were loaded from the server) with newRelationships (i.e. the relationships that are being submitted to the server)
 * @param {string} schema vuex store name
 * @param {object} entity with that schema
 * @param {object} association on that entity
 * @param {string|object} pathOrData path to node on submissions store, or the new data itself by other means as an object
 * @returns {object} each property is an array
 *  - removed: those relationships that are being removed
 *  - added: those relationships that are both new entities and new relationships
 *  - edited: those relationships that are new relationships for existing entities
 */
export const splitRelationships =
  (state, getters, rootState, rootGetters) => (options) => {
    const { schema, association, from, debug } = options;
    let { entity, pathOrData } = options;
    
    entity = getters.entityDammit(schema, entity);

    // try to work out the correct data path
    if (empty(pathOrData) && rootGetters["submissions/isPrimeEntity"](entity)) {
      const path = `${rootGetters["submissions/formDataPath"]}.related.${association.name}`;
      if (!empty(path)) pathOrData = path;
    }

    if (debug && debug == association.name) {
      console.log("📘 splitRelationships for association: " + association.name)
      console.trace();
      console.log("DEBUG splitRelationships options", options);
      console.log("DEBUG splitRelationships entity", entity);
      console.log("DEBUG splitRelationships pathOrData", pathOrData);
    }

    let oldRelationships = getters.oldRelationships(entity, association, debug),
      newRelationships = getters.normalisePathOrData({
        pathOrData,
        entity,
        association,
      }),
      added = [],
      edited = [],
      removed = [];
    const emptyOldRelationships = empty(oldRelationships),
      emptyNewRelationships = empty(newRelationships),
      isArrayNewRelationships = _.isArray(newRelationships);

    if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS) {
      console.log("splitRelationships options", options);
      console.log("splitRelationships entity", entity);
    }
    if (
      (emptyOldRelationships && emptyNewRelationships) ||
      typeof newRelationships === "undefined"
    ) {
      return {
        removed,
        added,
        edited,
        oldRelationships,
        newRelationships,
        emptyOldRelationships,
        emptyNewRelationships,
      };
    }
   
    newRelationships = isArrayNewRelationships
      ? [...newRelationships] //- remove cruft
      : empty(newRelationships)
      ? []
      : [newRelationships];

    // console.log("association.name", association.name);
    // console.log("oldRelationships", oldRelationships);
    // console.log("newRelationships", newRelationships);
    // console.log("emptyOldRelationships", emptyOldRelationships);
    // console.log("emptyNewRelationships", emptyNewRelationships);

    //- determined added and removed
    if (emptyOldRelationships && !emptyNewRelationships) {
      added = newRelationships;
    } else {
      added = _.differenceWith(
        [...newRelationships],
        [...oldRelationships],
        (a, b) => {
          return a.id === b.id && a.type === b.type;
        }
      );
    }

    //- split edited and added based on insert flag (which is added by emptyRow)
    if (!empty(added)) {
      edited = added.filter((x) => empty(x.insert));
      added = added.filter((x) => !empty(x.insert));
    }

    if (!emptyOldRelationships && emptyNewRelationships) {
      removed = oldRelationships;
    } else {
      removed = _.differenceWith(
        [...oldRelationships],
        [...newRelationships],
        (a, b) => {
          return a.id === b.id && a.type === b.type;
        }
      );
    }
    // console.log("added", added);
    // console.log("edited", edited);
    // console.log("removed", removed);
    // console.log("============ END: submissions splitRelationships ===========");

    return {
      removed,
      added,
      edited,
      oldRelationships,
      newRelationships,
      emptyOldRelationships,
      emptyNewRelationships,
    };
  };

export const storeExists = (state, getters, rootState) => (name) => {
  // console.log("include rootState", rootState);
  // console.log("include rootState name", name);
  // console.log("include rootState exists", !!rootState[name]);
  return !!rootState[name];
};

export const tables = (state) => {
  return state.data.tables;
};

export const typeMap = (state) => (name) => {
  // console.log("typeMap name", name);
  return state.data.schemas[name].typeMap;
  // return _.get(state, `data.schemas[${name}].typeMap`, {}); // option to prevent undefind, but not as performant
};
