import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

/**
 * @reststate false Not reststate as there's no corresponding BE store.
 * @persist true
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
