/**
 Is there a way to use the Vue instance in routes.js?
 e.g. I want to be able to dispatch an action before entering a route... so I need this.$store.
Yusuf Kandemir (EN/TR)Today at 9:31 AM
@Noel you can wrap it in a function, then supply the parameters you want
// router/routes.js
export default store => {
  return [
    // your routes here
  ]
}
// router/index.js
import createRoutes from './routes'
// ...
routes: createRoutes(this.$store)
*/

import { RouterError } from "src/exceptions";

/**
 * NB: do not auto-format this file! It becomes harder to read if you do.
 */
export default (store, notify) => {
  const checkPermission = async (flag, permission) => {
    if (permission) {
      var permissions = flag.user.permissions;
      if (!permissions.includes(permission)) {
        throw Error("Permission not found");
      }
    }
  };

  const routeReset = async (next, routeReset, schemaName, nodeName) => {
    /**
     * NB: if routeReset is false, then the submissions context will not be updated and the route will retain the previous submittions setting – generally this is undesirable as a static setting because it creates a false context unless carefully managed, primarily this should be handled via querystring value
     */
    if (!routeReset) {
      next();
      return;
    }

    // console.log("schemaName", schemaName);
    // console.log("nodeName", nodeName);

    try {
      let path = await store.dispatch("submissions/routeReset", {
        schemaName,
        nodeName,
      });
      // console.log("path", path);

      next(path);
    } catch (error) {
      throw new RouterError(error);
    }
  };

  const isLoggedIn = async () => {
    return await store.dispatch("pings/isLoggedIn");
  };

  const emailConfirm = async (token) => {
    try {
      const res = await store.dispatch("pings/verifyToken", {
        token: token,
      });
      if (res && res.status == 200) {
        if (res.data.result == true) {
          return true;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    return false;
  };

  const defaultBeforeEnter = async (providedOptions = {}) => {
    const defaultOptions = { permission: false, routeReset: true };
    document.title = providedOptions.from.meta.title || 'Mobile Notary Register';
    /**
     * @why: default is true, only set to false if explicity set to false,
     * either in the route definition or the query string.
     * Generally, true is only necessary for CRUD forms as it will setup the model for that form.
     * It's not necessary for list components because there's no submissions model required for those
     */
    const routeResetOption =
      providedOptions.to.query.routeReset === false
        ? false
        : providedOptions.routeReset === false
        ? false
        : true;

    const options = {
      ...defaultOptions,
      ...providedOptions,
      routeReset: routeResetOption,
    };
    // console.log("defaultBeforeEnter params for routeReset", options);
    const flag = await isLoggedIn();
    // console.log('isLoggedIn flag', flag );

    if (flag == false) {
      if (providedOptions.hasOwnProperty("next")) {
        providedOptions.next({ path: "/user/login" });
      }
      return;
    }
    checkPermission(flag, options.permission);
    await routeReset(
      options.next,
      options.routeReset,
      options.schemaName,
      options.nodeName
    );
    options.next();
  };

  return [
    {
      path: "/",
      beforeEnter: async (to, from, next) => {
        //- @why: to handle errors passed by XeroApiException as querystring
        if (to.query.error) {
          notify(to.query.error, "negative");
        }
        try {
          const flag = await isLoggedIn();
          // console.log('/ flag', flag, to.path );

          if (flag === false && to.path !== "/user/login") {
            console.error("Redirecting to /user/login");
            next({ path: "/user/login" });
          } else {
            console.log("Allowing access to /");
            next();
          }
        } catch (error) {
          console.error("Error in beforeEnter for /:", error);
          next();
        }
      },
      component: () => import("layouts/DefaultLoggedIn.vue"),
      children: [
        {
          path: "",
          beforeEnter: async (to, from, next) => {
            next();
          },
          component: () => import("pages/Index.vue"),
          meta: {
            title: 'MNR | Welcome'
          }
        },
        {
          path: "bankdetail",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "bankdetails",
              nodeName: "bankdetail",
              permission: "GET.Contacts.view",
            });
          },
          component: () => import("pages/bankdetail.vue"),
          meta: {
            title: 'MNR | Bank Detail'
          }
        },
        {
          path: "bankdetails",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "bankdetails",
              nodeName: "bankdetail",
              permission: "GET.Contacts.index",
            });
          },
          component: () => import("pages/bankdetails.vue"),
          meta: {
            title: 'MNR | Bank Details'
          }
        },
        {
          path: "contact",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "people",
              nodeName: "person",
              permission: "GET.Contacts.view",
            });
          },
          component: () => import("pages/contact.vue"),
          meta: {
            title: 'MNR | Contact'
          }
        },
        {
          path: "contacts",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "people",
              nodeName: "person",
              permission: "GET.Contacts.index",
            });
          },
          component: () => import("pages/contacts.vue"),
          meta: {
            title: 'MNR | Contacts'
          }
        },
        {
          path: "enquiry",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "enquiries",
              nodeName: "enquiry",
              permission: "GET.Enquiries.view",
            });
          },
          component: () => import("pages/enquiry.vue"),
          meta: {
            title: 'MNR | Enquiry'
          }
        },
        {
          path: "enquiries",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "enquiries",
              nodeName: "enquiry",
              permission: "GET.Enquiries.index",
            });
          },
          component: () => import("pages/enquiries.vue"),
          meta: {
            title: 'MNR | Enquiries'
          }
        },
        {
          path: "notarial",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "notarials",
              nodeName: "notarial",
              permission: "GET.Notarials.view",
            });
          },
          component: () => import("pages/notarial.vue"),
          meta: {
            title: 'MNR | Notarial'
          }
        },
        {
          path: "notarials",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "notarials",
              nodeName: "notarial",
              permission: "GET.Notarials.index",
            });
          },
          component: () => import("pages/notarials.vue"),
          meta: {
            title: 'MNR | Notarials'
          }
        },
        {
          path: "primaryservice",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "primaryservices",
              nodeName: "primaryservice",
              permission: "GET.Primaryservices.view",
            });
          },
          component: () => import("pages/primaryservice.vue"),
          meta: {
            title: 'MNR | Primary Service'
          }
        },
        {
          path: "primaryservices",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "primaryservices",
              nodeName: "primaryservice",
              permission: "GET.Primaryservices.index",
            });
          },
          component: () => import("pages/primaryservices.vue"),
          meta: {
            title: 'MNR | Primary Services'
          }
        },
        {
          path: "defaultfee",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "defaultfees",
              nodeName: "defaultfee",
              permission: "GET.Defaultfees.view",
            });
          },
          component: () => import("pages/defaultfee.vue"),
          meta: {
            title: 'MNR | Default Fee'
          }
        },
        {
          path: "defaultfees",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "defaultfees",
              nodeName: "defaultfee",
              permission: "GET.Defaultfees.index",
            });
          },
          component: () => import("pages/defaultfees.vue"),
          meta: {
            title: 'MNR | Default Fees'
          }
        },
        {
          path: "organisation",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "organisations",
              nodeName: "organisation",
              permission: "GET.Organisations.view",
            });
          },
          component: () => import("pages/organisation.vue"),
          meta: {
            title: 'MNR | Organisation'
          }
        },
        {
          path: "organisations",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "organisations",
              nodeName: "organisation",
              permission: "GET.Organisations.index",
            });
          },
          component: () => import("pages/organisations.vue"),
          meta: {
            title: 'MNR | Organisations'
          }
        },
        {
          path: "roles",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "roles",
              nodeName: "role",
              permission: "POST.Roles.add",
            });
          },
          component: () => import("pages/roles.vue"),
          meta: {
            title: 'MNR | Roles'
          }
        },
        {
          path: "settings",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "settings",
              nodeName: "settings",
              permission: "GET.Settings.view",
            });
          },
          component: () => import("pages/Settings.vue"),
          meta: {
            title: 'MNR | Settings'
          }
        },
        {
          path: "xeroaccount",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "xeroaccounts",
              nodeName: "xeroaccount",
              permission: "GET.Xeroaccounts.index",
            });
          },
          component: () => import("pages/xeroaccount.vue"),
          meta: {
            title: 'MNR | Xero Account'
          }
        },
        {
          path: "xeroaccounts",
          beforeEnter: async (to, from, next) => {
            console.log(
              "--> routes.js – Checking access for xeroaccounts",
              to,
              from
            );

            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "xeroaccounts",
              nodeName: "xeroaccount",
              permission: "GET.Xeroaccounts.view",
            });
          },
          component: () => import("pages/xeroaccounts.vue"),
          meta: {
            title: 'MNR | Xero Accounts'
          }
        },
        {
          path: "usersetting",
          beforeEnter: async (to, from, next) => {
            defaultBeforeEnter({
              to,
              from,
              next,
              schemaName: "people",
              nodeName: "person",
              permission: "GET.Usersettings.index",
            });
          },
          component: () => import("pages/Usersetting.vue"),
          meta: {
            title: 'MNR | User Settings'
          }
        },
      ],
    },
    {
      path: "/email",
      component: () => import("layouts/DefaultLoggedOut.vue"),
      beforeEnter: async (to, from, next) => {
        const flag = await emailConfirm(to.query.token);
        if (flag == true) {
          next();
        } else {
          next({ path: "error" });
        }
      },
      children: [
        { path: "confirm", component: () => import("pages/Verified.vue") },
        { path: "reset", component: () => import("pages/Reset.vue") },
        { path: "device", component: () => import("pages/Device.vue") },
      ],
      meta: {
        title: 'MNR | Email Confirmations'
      }
    },
    {
      path: "/user",
      component: () => import("layouts/DefaultLoggedOut.vue"),
      beforeEnter: async (to, from, next) => {
        const flag = await isLoggedIn();
        // @why: don't let the user into /user if they are logged in because all these routes only apply when not logged in. Redirect to the root of the app if they are logged in already.
        if (flag == false || to.path == "/user/logout") {
          next();
        } else {
          next({ path: "/" });
        }
      },
      children: [
        { path: "", component: () => import("pages/Login.vue") },
        { path: "login", component: () => import("pages/Login.vue"),
          meta: {
            title: 'MNR | Login'
          }
        },
        { path: "logout", component: () => import("pages/Logout.vue"), 
          meta: {
            title: 'MNR | Logout'
          }
        },
        { path: "register", component: () => import("pages/Register.vue"), 
          meta: {
            title: 'MNR | Register'
          } 
        },
        {
          path: "reset-password",
          component: () => import("pages/ResetPassword.vue"),
          meta: {
            title: 'MNR | Reset Password'
          }
        },
      ],
    },
    {
      path: "/status",
      component: () => import("layouts/Status.vue"),
      children: [],
    },
    {
      path: "/storybook",
      component: () => import("layouts/Storybook.vue"),
      beforeEnter: async (to, from, next) => {
        store.commit("submissions/resetAllForms");
        const flag = await isLoggedIn();
        // console.log("flag", flag);
        if (flag == false) {
          next({ path: "/user/login" });
        } else {
          if (flag.user.permissions.includes("storybook")) {
            next();
          } else {
            next({ path: "/" });
            // notify(
            //   `You are not allowed to access this url 1: ${path}`,
            //   "negative"
            // );
          }
        }
      },
      children: [
        { path: "", component: () => import("pages/Storybook.vue") },
        {
          path: "m21Select",
          component: () => import("components/storybook/m21Select.vue"),
        },
        {
          path: "stateOptionSelect",
          component: () => import("components/storybook/stateOptionSelect.vue"),
        },
        {
          path: "QueryWatch",
          component: () => import("components/storybook/QueryWatch.vue"),
        },
        {
          path: "ReststateExamples",
          component: () => import("components/storybook/ReststateExamples.vue"),
        },
        {
          path: "ReststateExamplesOld",
          component: () =>
            import("components/storybook/ReststateExamples_old.vue"),
        },
        {
          path: "exceptions",
          component: () => import("components/storybook/exceptions.vue"),
        },
        {
          path: "pouchdb",
          component: () => import("components/storybook/PouchDB.vue"),
        },
      ],
    },
    {
      path: "*",
      beforeEnter: async (to, from, next) => {
        notify("*");
        next();
      },
      component: () => import("pages/Error404.vue"),
    },
  ];
};
