import { to } from "await-to-js";
import { strip } from "src/utils";

export default class TermsController {
  constructor(Service) {
    this.$service = Service;
  }

  async save(data) {
    // let succss, error;
    let err, res;
    // console.log("save data", data);
    if (data.insert) {
      [err, res] = await this.create(data);
    } else {
      [err, res] = await to(this.update(data));
    }

    // console.log("res", res);
    if (err != null) {
      throw err;
    }
    if (res.success == true) {
      await this.updateAssociations(data);
      return true;
    }
  }

  async update(data) {
    const options = {
      attributes: strip(data.attributes),
      id: strip(data.id),
      type: strip(data.type)
    };
    console.warn("options", options);
    await this.$service.updateEntityAttributes(options);
  }

  async create(data) {
    await this.$service.totoDammit("terms", "create", {
      attributes: strip(data.attributes),
      id: strip(data.id),
      type: strip(data.type)
    });
  }

  async updateAssociations(data) {
    // console.log("updateAssociations for Terms", data);
    const options = {
      entity: data,
      relatedData: data.related,
      limitAssociations: ["TermsSignatories", "People", "Organisations"],
      from: "termsController.js"
    };
    // console.log("options", options);
    await this.$service.updateEntityAssociations("terms", options);
  }
}
