import _ from "lodash";
import {
  LEGALISATION_AGENT,
} from "src/store/constants";
/**
 * 
 * @returns The actual models from state. Modifying these will cause new instances.
 */
export const emptyJsonModels = (state) => {
  const models = _.cloneDeep(state.emptyJsonModels);
  return models;
}

/**
 * @param {string} model name of the attribute for enquiry or notarial where this JSON entity is stored.
 * @returns Object containing a clone of the empty row model 
 */
export const emptyJsonModel = (state, getters) => (model) => {
  return _.cloneDeep(getters.emptyJsonModels[model]);
}

export const countryModelExists =
  (state, getters, rootState, rootGetters) => (name, countryCode) => {
    const path = `${rootGetters["submissions/formDataPath"]}.attributes.${name}`;
    const data = rootGetters["submissions/getField"](path) || false;
    if (!data) return false;
    const exists = data.filter((x) => x.country_code === countryCode);
    return !_.isEmpty(exists);
  };

export const getCountry = (state) => (code) => {
  if (_.isEmpty(code)) {
    return false;
  }
  let found = state.list.filter((x) => x.code === code);
  if (_.isEmpty(found)) {
    throw new Error("Received non-existent country code: ", code);
  }
  return found[0];
};

export const countryoptions = (state) => {
  return state.list;
};

export const getCountryName = (state, getters) => (code) => {
  let country = getters.getCountry(code);
  return country.name;
};

export const ruleIds = (state, getters) => (countryCode) => {
  const rules = getters.rulesForCountryCode(countryCode);
  return rules.map(x => x.value);
}

export const rule = (state) => (ruleId) => {
  return state.rules.find((r) => r.value === ruleId);
};

export const rulesForCountryCode = (state, getters) => (countryCode) => {
  const country = getters.getCountry(countryCode);
  // console.log('rulesForCountryCode country', country);
  // console.log('rulesForCountryCode countryCode', countryCode);

  return getters.rulesForLegalCode(country.legal_code)
}
export const rulesForCountry = (state, getters) => (country) => {
  return getters.rulesForLegalCode(country.legal_code)
}
export const rulesForLegalCode = (state) => (legal_code) => {
  const found = state.legal_code_rules.filter((x) => x.code === legal_code);
  if (_.isEmpty(found)) {
    return [];
  }
  return found[0].rules;
};

export const requiresLegalisation = (state, getters) => (country) => {
  const rules = getters.rulesForCountry(country);
  const found = rules.filter((x) => x.value !== 0);
  return !_.isEmpty(found);
};

export const getRequiredLegalisationStepsByLegalCode = (state) => (code) => {
  return state.legal_code_rules.filter((rule) => rule.code === code)[0].rules;
};

export const getPrimaryServices =
  (state, getters, rootState, rootGetters) => (codes) => {
    const primaryServices = rootGetters["primaryservices/all"]?.filter(
      (x) => codes.includes(x.attributes.legalCode) || []
    );
    return primaryServices;
  };

export const primaryServiceId = (state, getters, rootState, rootGetters) => (countryCode) => {
  return rootGetters["submissions/step"](countryCode).primaryServiceId
}

export const getDefaultFees = (state, getters, rootState, rootGetters) => {
  return rootGetters["defaultfees/all"];
};

export const getRuleIdsForTier = () => (tier) => {
  switch (tier) {
    case 2:
      return [3];
    case 3:
      return [4];
    default:
      return [1, 2];
  }
}

export const getTierByRuleId = () => (ruleId) => {
  switch (ruleId) {
    case 1: return 1;
    case 2: return 1;
    case 3: return 2;
    case 4: return 3;
    default: return null
  }
}

export const tierByLegalisationCode = (state, getters) => (legalisationCode) => {
  switch (legalisationCode) {
    case 2: return 1;
    case 3: return 2;
    case 4: return 3;
    default: return null;
  }
}


export const defaultFeesByPrimaryServiceId =
  (state, getters, rootState, rootGetters) =>
    (primaryserviceId) => {
      if (!primaryserviceId) return [];
      const allDefaultFees = rootGetters["defaultfees/all"];
      // console.log('allDefaultFees', allDefaultFees);

      const defaultfees =
        allDefaultFees?.filter(
          (x) => x.relationships.primaryservice.data.id === primaryserviceId
        ) || [];
      return defaultfees;
    };

export const ruleLabel = (state, getters) => (ruleId) => {
  const rule = getters.rule(ruleId);
  return rule.label;
};

/**
 * 
 * @param {integer} ruleId 
 * @param {integer} step 
 * @returns reststate record
 */
export const defaultFeesByRuleIdAndLegalisationStep = (state, getters) => (ruleId, step) => {
  // console.log('ruleId', ruleId);
  // console.log('typeof ruleId', typeof ruleId);
  // console.log('step', step);

  switch (ruleId) {
    //- for FCDO tier, find the selected FCDO_postal_choice (which is a primaryserviceID by value) and get the default fees for that Primary Service
    case 1: case 2:
      return getters.defaultFeesByPrimaryServiceId(step.primaryServiceId);
    //- for Embassy tier, the choice is just internal or external, from which the default fees can be determined @todo: should ideally fetch these ids from primaryservices where legalisation_code = 3
    case 3:
      if (step.legalisation_agent_embassy === LEGALISATION_AGENT.internal) {
        return getters.defaultFeesByPrimaryServiceId(
          "6a2f912c-6682-4b7a-aff2-000000000000"
        );
      } else if (step.legalisation_agent_embassy === LEGALISATION_AGENT.external) {
        return getters.defaultFeesByPrimaryServiceId(
          "6a2f912c-6682-4b7a-aff2-000000000000"
        );
      } else {
        return []
      }
    //- for Chamber tier, the choice is just internal or external, from which the default fees can be determined @todo: should ideally fetch these ids from primaryservices where legalisation_code = 4
    case 4:
      if (step.legalisation_agent_chamber === LEGALISATION_AGENT.internal) {
        return getters.defaultFeesByPrimaryServiceId(
          "8a2f912c-6682-4b7a-aff2-000000000000"
        );
      } else if (step.legalisation_agent_chamber === LEGALISATION_AGENT.external) {
        return getters.defaultFeesByPrimaryServiceId(
          "9a2f912c-6682-4b7a-aff2-000000000000"
        );
      } else {
        return []
      }
  }
}

// export const getEnquirySelectedSteps = (state, getters) => country => {
//   if (_.isEmpty(country.code)) return false;
//   // console.log("getters", getters);
//   const steps = getters;
// };
/*
  checks through the selected steps for the country's rules
  @return (bool): whether tha country has FCO options
*/
// @deprecated: these can be found in enquiries store
// export const isFCO = state => (country, steps) => {
//   if (_.isEmpty(country.code)) return false;
//   const rules = steps[country.code];
//   if (_.isEmpty(rules)) {
//     return false;
//   }
//   const valid = [1, 2];
//   const invalidSet = leftDifference(rules, valid);
//   const invalid = !!invalidSet.length;
//   const show = !invalid;
//   return show;
// };

// export const legalisationNotRequired = state => (country, steps) => {
//   state;
//   if (_.isEmpty(country.code)) return false;
//   const rules = steps[country.code];
//   if (_.isEmpty(rules)) {
//     return false;
//   }
//   const valid = [0];
//   const invalidSet = leftDifference(rules, valid);
//   const invalid = !!invalidSet.length;
//   const show = !invalid;
//   return show;
// };
