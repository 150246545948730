export const defaultFormStateFactory = () => {
  return {
    bankdetail: {
      data: {},
      settings: {
        source: null, //- new | enquiry
        queryKey: "bankdetail_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "bankdetailForm",
        schema: "bankdetails",
        defaultAssociations: [
          "People"
        ],
      },
    },
    enquiry: {
      data: {},
      settings: {
        source: null, //- new | enquiry
        queryKey: "enquiry_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "enquiryForm",
        schema: "enquiries",
        defaultAssociations: [
          "Organisations",
          "Administratives",
          "Representatives",
          "Clients",
          "EnquiryTypes",
          "Locations",
          "Notary",
          "Invoicees"
        ],
      },
    },
    notarial: {
      data: {},
      settings: {
        source: null, //- new | enquiry | notarial
        queryKey: "notarial_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: false,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "notarialPrimeForm",
        schema: "notarials",
        defaultAssociations: [
          "Administratives",
          "Clients",
          "Compliance",
          "Documents",
          "EnquiryTypes",
          "Locations",
          "Notaries",
          "Organisations",
          "Representatives",
          "Terms",
        ],
      },
    },
    person: {
      data: {},
      settings: {
        source: null,
        queryKey: "person_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "personForm",
        schema: "people",
        defaultAssociations: [
          "Bankdetails",
          "SignatoriesDocuments",
          "Roles",
          "Authorisations",
        ],
      },
    },
    organisation: {
      data: {},
      settings: {
        source: null,
        queryKey: "organisation_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "organisationForm",
        schema: "organisations",
        defaultAssociations: ["People"],
      },
    },
    authorisation: {
      data: {},
      settings: {
        source: null,
        queryKey: "authorisation_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "authorisationForm",
        schema: "authorisations",
        defaultAssociations: ["People", "Files", "Capacities"],
      },
    },
    document: {
      data: {},
      settings: {
        source: null,
        queryKey: "document_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "documentForm",
        schema: "documents",
        defaultAssociations: [
          "Clients",
          "DocumentClients",
          "DocumentOrganisations",
          "DocumentRepresentatives",
          "DocumentSignatories",
          "Files",
          "Notarials",
          "Organisations",
          "Representatives",
          "Signatories",
          "Compliance",
        ],
      },
    },
    restaurant: {
      data: {},
      settings: {
        source: null,
        queryKey: "restaurant_id",
        variant: null, //
        dataLoaders: ["restaurants/loadAll", "dishes/loadAll"],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "restaurantForm",
        schema: "restaurants",
        defaultAssociations: ["Dishes"],
      },
    },
    primaryservice: {
      pagination: {
        sortBy: "created",
        descending: false,
        page: 1,
        rowsPerPage: 10,
      },
      data: {},
      settings: {
        source: null,
        queryKey: "primaryservice_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "primaryserviceForm",
        schema: "primaryservices",
        defaultAssociations: ["Defaultfees"],
      },
    },
    defaultfee: {
      data: {},
      settings: {
        source: null,
        queryKey: "defaultfee_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "defaultfeeForm",
        schema: "defaultfees",
        defaultAssociations: [],
      },
    },
    settings: {
      data: {},
      settings: {
        source: null,
        queryKey: "setting_id",
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "financialSettingsForm",
        schema: "settings",
        defaultAssociations: [],
      },
    },
    testenquiry: {
      data: {},
      settings: {
        source: null, //- new | enquiry
        queryKey: "testenquiry_id",
        variant: null, //
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "testenquiryForm",
        schema: "testenquiries",
        defaultAssociations: [],
      },
    },
    xeroaccount: {
      data: {},
      settings: {
        source: null,
        queryKey: "xeroaccount_id",
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "xeroaccountsForm",
        schema: "xeroaccounts",
        defaultAssociations: ["People"],
      },
    },
    productcode: {
      data: {},
      settings: {
        source: null,
        queryKey: "productcode_id",
        dataLoaders: [],
        initialiser: null,
        isUpdate: null,
        isLoaded: null,
        isSubmitted: null,
        isSaving: null,
        isValid: null,
        ref: "productcodesForm",
        schema: "productcodes",
        defaultAssociations: ["People"],
      },
    },
  };
};

export default function () {
  return {
    /**
     * Form settings, each of these represent meta-state related to the form UI
     */
    path: null,
    stepper: {
      step: 1,
    },
    forms: defaultFormStateFactory(),
    showValidationBanner: false,
    isListLoading: false, //used by list views
    showSearch: false, //used by list views
    disableSearch: false, //used by list views
  };
}
