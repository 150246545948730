import { notify } from "src/utils";

export const fetchAll = async (context, options) => {
  options.Class = options.Class || false //- "REVENUE" || "EXPENSE" 
  const res = await options.service.xero.accountCode(options.userId, options.Class);

  console.log('res', res);
  
  if (res.status == 200) {
    context.commit("store", res.data.accountData);
  } else {
    notify("Authenticate the user from Xero Account Page", "warning", {
      timeout: 0,
      actions: [
        {
          color: "white",
          label: "Goto Xero Account Page",
          handler: () => {
            options.router.push("xeroaccounts");
          },
        },
      ],
    });
  }

};