export default function () {
  return {
    paginationDefaults: {
      sortBy: "created",
      descending: true,
      page: 1,
      rowsPerPage: 10,
    },
    perPageOptions: [10,20,50,100,200],
    pagination: {

    },
    isLoaded: false,
    lastError: "",
    isError: false,
    data: {} // where the schema data from respons will be stored
  };
}
