import to from "await-to-js";
import { ucfirst } from "locutus/php/strings";
import { is_numeric } from "locutus/php/var";
import _ from "lodash";
import { intersection } from "src/utils";

const getAction = (message, options) => {
  switch (message.method) {
    case "post":
      break;
    case "patch":
      break;
    case "delete":
      break;

    default:
      //get
      if (message.id) return "loadById";
      return "loadAll";
  }
};

const getParams = (message, options) => {
  let params = {};
  switch (message.method) {
    case "post":
      break;
    case "patch":
      break;
    case "delete":
      break;

    default:
      //get
      if (message.id) params.id = message.id;
      break;
  }
  return params;
};
/**
 * Returns the desired request method from @reststate action.
 * Used by the API to determine offline queue strategy
 * @param {string} path the action to dispatch
 */
export const getRequestMethod = path => {
  const pathSplit = path.split("/");
  const pathNode = pathSplit.length > 1 ? 1 : 0;
  const checker = (value, elements) =>
    elements.some(element => value.includes(element));
  if (checker(pathSplit[pathNode], ["update", "set"])) return "PATCH";
  if (checker(pathSplit[pathNode], ["create", "add"])) return "POST";
  if (checker(pathSplit[pathNode], ["remove", "delete"])) return "DELETE";
  return "GET";
};

const RS = function(store) {
  return {
    /*****************************************
     * INTERFACE
     ****************************************/
    initialise() {},
    getById(id) {
      return store.getters[`${storeName}/byId`]({ id });
    },
    getAll() {
      return store.getters[`${storeName}/all`];
    },
    method(options) {
      const { path } = this._extractMessageOptions(options);
      return getRequestMethod(path);
    },
    serialize(value, key) {
      if (typeof value === "function") {
        return value.toString();
      }
      return value;
    },
    deserialize(key, value) {
      if (
        value &&
        typeof value === "string" &&
        value.substr(0, 8) == "function"
      ) {
        var startBody = value.indexOf("{") + 1;
        var endBody = value.lastIndexOf("}");
        var startArgs = value.indexOf("(") + 1;
        var endArgs = value.indexOf(")");

        return new Function(
          value.substring(startArgs, endArgs),
          value.substring(startBody, endBody)
        );
      }
      return value;
    },
    async send(message) {
      const { path, data } = this._extractMessageOptions(message);
      if (process.env.DEBUG_API) {
        // console.log("RS send path", path);
        // console.log("RS send data", data);
      }
      let [e, r] = await to(store.dispatch(path, data));
      if (e) {
        throw e;
      }
      if (r) {
        // console.log("ExecutionChain RS.send result", r);
        return r;
      }
    },
    storeExists(storeName) {
      return store.hasOwnProperty(storeName);
    },

    /*****************************************
     * PRIVATE
     ****************************************/
    /**
     * @param {string|object} options if {string} this will be the endPoint for a request that requires no body, otherwise use options
     * @returns {object}
     */
    _extractMessageOptions(options) {
      if (process.env.DEBUG_API) {
        // console.log("RS _extractMessageOptions", options);
      }
      let path, data;
      if (_.isString(options)) {
        path = options;
        data = {};
      } else if (
        !_.isObject(options) ||
        !intersection(["actionPath", "endPoint"], Object.keys(options)).length
      ) {
        console.debug("message options", options);
        throw Error("Reststate: invalid message options");
      } else {
        path = options.actionPath || options.endPoint;
        data = _.get(options, "data", {});
      }
      if (process.env.DEBUG_API) {
        // console.log("RS _extractMessageOptions path", path);
        // console.log("RS _extractMessageOptions data", data);
      }
      return { path, data };
    },

    /*****************************************
     * PUBLIC
     ****************************************/
    getRequestMethod,

    isError(storeName) {
      return store.getters[`${storeName}/isError`];
    },
    getError(storeName) {
      return store.getters[`${storeName}/error`];
    },
    getStatus(storeName) {
      return store.state[storeName].status;
    }
  };
};

export { RS as default };

/**
 * Equivalent to `mapActions` but wraps these in such a way that they will be called via `api`.
 * @todo Currently must be run in `created` hook; because it references the vm and thus can't be destructured directly into `computed` properties
 * Ideally it should be refactored to allow such destructuring – after all vuex and vuex-map-fields manage this and they access the vm
 * @note it has now been refactored in that way as `mapReststateActions`
 * @param {array} actions
 */
export function mapInjectedApiActions(actions) {
  // console.log("load actions", actions);
  const api = this.$api;
  const API = this.$API;
  const self = this;
  const RS = API.RS;
  _.each(actions, function(path, key) {
    // console.log("load action", path);
    const requestMethod = RS.getRequestMethod(path);
    let proxy = key;
    const pathSplit = path.split("/");
    if (is_numeric(key)) {
      //- facilitate alternate format for actions definitions as array of strings, rather than an object
      proxy = `${pathSplit[1]}${ucfirst(pathSplit[0])}`;
      proxy = path;
    }
    //- must be a standard (non-arrow) function as it refers to 'this' within
    self[proxy] = function(data, metadata) {
      const options = _.merge(
        {
          actionPath: path,
          storeName: pathSplit[0],
          action: pathSplit[1],
          // actionKey: key, //@deprecated: not needed here
          requestMethod,
          data
        },
        metadata
      );
      return api.apply(this, ["RS", options]);
    };
  });
}
