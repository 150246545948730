export const hashExists = (state, getters) => hash =>
  getters.byHash(hash).length > 0;
export const byHash = state => hash =>
  state.records.filter(r => r.hash === hash);

export const messages = state => state.records;
export const getMessages = state => state.records;
export const isBusy = state => state.busy;
export const byIndex = state => index => state.records[index];

export const hasMessages = state => {
  return Boolean(state.records?.length);
};
