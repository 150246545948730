import Vue from "vue";

function _slicedToArray(arr, i) {
  return (
    _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest()
  );
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  if (
    !(
      Symbol.iterator in Object(arr) ||
      Object.prototype.toString.call(arr) === "[object Arguments]"
    )
  ) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (
      var _i = arr[Symbol.iterator](), _s;
      !(_n = (_s = _i.next()).done);
      _n = true
    ) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function arrayToObject() {
  var fields =
    arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return fields.reduce(function(prev, path) {
    var key = path.split(".").slice(-1)[0];

    if (prev[key]) {
      throw new Error("The key `".concat(key, "` is already in use."));
    } // eslint-disable-next-line no-param-reassign

    prev[key] = path;
    return prev;
  }, {});
}

function objectEntries(obj) {
  if (process.env.DEBUG_TRACE) {
    if (typeof obj === "undefined") {
      console.trace();
    }
  }
  return Object.keys(obj).map(function(key) {
    return [key, obj[key]];
  });
}

function normalizeNamespace(fn) {
  return function() {
    if (process.env.DEBUG_MAP_NORMALIZE) {
      // console.log("normalizeNamespace args", arguments);
    }
    for (
      var _len = arguments.length, params = new Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      params[_key] = arguments[_key];
    }

    // eslint-disable-next-line prefer-const
    var _ref =
        typeof params[0] === "string" ? [].concat(params) : [""].concat(params),
      _ref2 = _slicedToArray(_ref, 4),
      namespace = _ref2[0],
      map = _ref2[1],
      options =
        typeof _ref2[2] === "object" ? Object.assign({}, _ref2[2]) : _ref2[2],
      mutationType = _ref2[3];
    var signature = "legacy";
    var getterType;

    if (namespace.length && namespace.charAt(namespace.length - 1) !== "/") {
      namespace += "/";
    }
    if (process.env.DEBUG_MAP_NORMALIZE) {
      // console.log("normalizeNamespace options", options);
    }
    if (typeof options === "object") {
      options.getterType = ""
        .concat(namespace)
        .concat(options.getterType || "getField");
      options.mutationType = ""
        .concat(namespace)
        .concat(options.mutationType || "updateField");
      signature = "options";
    } else {
      getterType = options;
      getterType = "".concat(namespace).concat(getterType || "getField");
      mutationType = "".concat(namespace).concat(mutationType || "updateField");
    }

    if (signature === "options") {
      return fn(namespace, map, options);
    }

    return fn(namespace, map, getterType, mutationType);
  };
}
/**
 * @returns Function which takes path as a String argument, splits it by . and traverses state to find the value
 */
function getField(state) {
  return function(path) {
    if (process.env.DEBUG_TRACE) {
      console.trace();
    }

    // console.log("getField state", state);
    // console.log("getField path", path);
    const field = path.split(/[.[\]]+/).reduce(function(prev, key) {
      // console.log("getField key", key);
      // console.log("getField prev", prev);
      // console.log("getField prev[key]", prev[key]);
      // console.log("getField typeof prev[key]", typeof prev[key]);
      return prev?.[key];
    }, state);

    // console.log("getField field value", field);
    return field;
  };
}
function updateField(state, _ref3) {
  var path = _ref3.path,
    value = _ref3.value;
  if (process.env.DEBUG_MAP_FIELDS) {
    console.log("+------------------- updateField -------------------+");
    console.log("| vuex-map-fields updateField: path", path);
    console.log("| vuex-map-fields updateField: _ref3", _ref3);
    console.log("| vuex-map-fields updateField: value", value);
    console.log(
      "| vuex-map-fields updateField: initial state",
      _.get(state, path)
    );
  }

  path.split(/[.[\]]+/).reduce(function(prev, key, index, array) {
    if (array.length === index + 1) {
      // eslint-disable-next-line no-param-reassign
      Vue.set(prev, key, value);
    }

    return prev[key];
  }, state);
  if (process.env.DEBUG_MAP_FIELDS) {
    console.log(
      "| vuex-map-fields updateField: final state",
      _.get(state, path)
    );
    console.log("+---------------------------------------------------+");
  }
  if (process.env.DEBUG_TRACE) {
    console.trace();
  }
}
var mapFields = normalizeNamespace(function(
  namespace,
  fields,
  getterType,
  mutationType
) {
  var fieldsObject = Array.isArray(fields) ? arrayToObject(fields) : fields;
  return Object.keys(fieldsObject).reduce(function(prev, key) {
    var path = fieldsObject[key];
    var field = {
      get: function get() {
        if (process.env.DEBUG_MAP_FIELDS) {
          console.log(
            "+------------------- mapField getter -------------------+"
          );
          // console.log("| vuex-map-fields mapField getter: path:- ", path);
          console.log(
            "| vuex-map-fields mapField getter: getterType:- ",
            getterType
          );
          console.log(
            "| vuex-map-fields mapField getter: value:- ",
            this.$store.getters[getterType](path)
          );
          console.log(
            "+-------------------------------------------------------+"
          );
        }
        if (process.env.DEBUG_TRACE) {
          console.trace();
        }
        return this.$store.getters[getterType](path);
      },
      set: function set(value) {
        if (process.env.DEBUG_MAP_FIELDS) {
          console.log(
            "+------------------- mapField setter -------------------+"
          );
          // console.log("| vuex-map-fields mapField setter: path:- ", path);
          console.log(
            "| vuex-map-fields mapField setter: mutationType:- ",
            mutationType
          );
          // console.log("| vuex-map-fields mapField getter: value:- ", value);
          console.log(
            "+-------------------------------------------------------+"
          );
        }
        if (process.env.DEBUG_TRACE) {
          console.trace();
        }
        this.$store.commit(mutationType, {
          path: path,
          value: value
        });
      }
    }; // eslint-disable-next-line no-param-reassign

    prev[key] = field;
    return prev;
  }, {});
});
var mapMultiRowFields = normalizeNamespace(function(
  namespace,
  paths,
  getterType,
  mutationType
) {
  var pathsObject = Array.isArray(paths) ? arrayToObject(paths) : paths;
  return Object.keys(pathsObject).reduce(function(entries, key) {
    var path = pathsObject[key]; // eslint-disable-next-line no-param-reassign

    entries[key] = {
      get: function get() {
        var store = this.$store;
        if (process.env.DEBUG_MAP_FIELDS) {
          // console.log("getterType", getterType);
          // console.log("path", path);
        }
        var rows = objectEntries(store.getters[getterType](path));
        return rows.map(function(fieldsObject) {
          return Object.keys(fieldsObject[1]).reduce(function(prev, fieldKey) {
            var fieldPath = ""
              .concat(path, "[")
              .concat(fieldsObject[0], "].")
              .concat(fieldKey);
            return Object.defineProperty(prev, fieldKey, {
              get: function get() {
                return store.getters[getterType](fieldPath);
              },
              set: function set(value) {
                store.commit(mutationType, {
                  path: fieldPath,
                  value: value
                });
              }
            });
          }, {});
        });
      }
    };
    return entries;
  }, {});
});
var mapDynamicFields = normalizeNamespace(function(namespace, fields, options) {
  // console.log("namespace", namespace);
  // console.log("fields", fields);
  // console.log("options", options);
  var fieldsObject = Array.isArray(fields) ? arrayToObject(fields) : fields;
  return Object.keys(fieldsObject).reduce(function(prev, key) {
    var field = {
      get: function get() {
        //- 'this' refer to vue component
        var path = fieldsObject[key].replace("PATH", this[options.pathField]);

        // console.log("fieldsObject[key]", fieldsObject[key]);
        // console.log("path", path);
        // console.log("options", options);
        // console.log("getterType", options.getterType);
        // console.log("getters", this.$store.getters);
        // console.log(
        //   "mapDynamicFields state",
        //   this.$store.state.submissions.forms.restaurant.data.attributes
        // );
        return this.$store.getters[options.getterType](path);
      },
      set: function set(value) {
        // 'this' refer to vue component
        var path = fieldsObject[key].replace("PATH", this[options.pathField]);
        this.$store.commit(options.mutationType, {
          path: path,
          value: value
        });
      }
    };
    prev[key] = field;
    return prev;
  }, {});
});
var mapDynamicMultiRowFields = normalizeNamespace(function(
  namespace,
  paths,
  options
) {
  var pathsObject = Array.isArray(paths) ? arrayToObject(paths) : paths;
  return Object.keys(pathsObject).reduce(function(entries, key) {
    // eslint-disable-next-line no-param-reassign
    entries[key] = {
      get: function get() {
        var store = this.$store;
        var path = pathsObject[key].replace("PATH", this[options.pathField]);
        if (process.env.DEBUG_MAP_FIELDS) {
          // console.log("path", path);
          // console.log("getterType", options.getterType);
          // console.log("getters", store.getters);
        }
        var rows = store.getters[options.getterType](path);
        return rows.map(function(fieldsObject, index) {
          return Object.keys(fieldsObject).reduce(function(prev, fieldKey) {
            var fieldPath = ""
              .concat(path, "[")
              .concat(index, "].")
              .concat(fieldKey);
            return Object.defineProperty(prev, fieldKey, {
              get: function get() {
                return store.getters[options.getterType](fieldPath);
              },
              set: function set(value) {
                store.commit(options.mutationType, {
                  path: fieldPath,
                  value: value
                });
              }
            });
          }, {});
        });
      }
    };
    return entries;
  }, {});
});
var createHelpers = function createHelpers(_ref4) {
  var getterType = _ref4.getterType,
    mutationType = _ref4.mutationType,
    options = _ref4.options;
  return {
    mapFields: normalizeNamespace(function(namespace, fields) {
      return mapFields(namespace, fields, getterType, mutationType);
    }),
    mapMultiRowFields: normalizeNamespace(function(namespace, paths) {
      return mapMultiRowFields(namespace, paths, getterType, mutationType);
    }),
    mapDynamicFields: normalizeNamespace(function(namespace, fields) {
      if (process.env.DEBUG_MAP_NORMALIZE) {
        console.log("vuex-map-fields/index options", options);
      }
      return mapDynamicFields(namespace, fields, options);
    }),
    mapDynamicMultiRowFields: normalizeNamespace(function(namespace, paths) {
      return mapDynamicMultiRowFields(namespace, paths, options);
    })
  };
};

export {
  createHelpers,
  getField,
  mapDynamicFields,
  mapDynamicMultiRowFields,
  mapFields,
  mapMultiRowFields,
  updateField,
};
