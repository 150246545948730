import * as actions from "./actions";
// NB: empty getters file called using wildcard will return object `{default:{}}` which causes errors as a function is expected
import * as getters from "./getters";
import * as mutations from "./mutations";
import state from "./state";

/**
 * @reststate false
 * @persist true
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
