import { format } from "date-fns";

let dateFormat;
export const setFormat = (form) => {
  dateFormat = form;
};

export const date = (dateStr) => {
  const date = new Date(dateStr);
  return format(date, dateFormat || "dd/MM/yyyy");
};
