export const setPingLastStatusCode = (state, payload) => {
  state.lastPingStatusCode = payload;
  if (payload === 200) {
    state.serverOnline = true;
    state.networkOnline = true;
  } else {
    state.serverOnline = false;
  }
  state.isLoaded = true;
  state.pingExpired = false;
  state.timeLastPinged = Date.now();
};



export const setPingExpired = (state, payload) => {
  // console.log("getPing: setExpired", payload);
  state.pingExpired = payload;
};
export const setTimeStamp = (state, payload) => {
  state.timeLastPinged = payload;
};
export const setIsLoaded = (state, payload) => {
  state.isLoaded = payload;
};
export const setNetworkOnline = (state, payload) => {
  if (payload === false) state.serverOnline = false;
  state.networkOnline = payload;
};
export const setServerOnline = (state, payload) => {
  state.serverOnline = payload;
};
export const setIsError = (state, payload) => {
  state.isError = payload;
};
export const setLastError = (state, payload) => {
  state.lastError = payload;
};
export const setEnabled = (state, payload) => {
  state.enabled = payload;
};
export const isLoggedIn = (state, payload) => {
  state.isLoggedIn = payload;
};
export { updateField } from "src/utils/vuex-map-fields/index";
