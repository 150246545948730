import { ucwords, ucfirst, lcfirst } from "locutus/php/strings";
export const titlecase = value => {
  if (!value) return "";
  return ucwords(value);
};

export const sentenceCase = val => {
  if (!val) return "";
  return ucfirst(val);
};

export const lowerFirst = val => {
  if (!val) return "";
  return lcfirst(val);
};
