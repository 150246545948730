import { empty } from "locutus/php/var";
import _ from "lodash";
import * as types from "src/store/constants";

//- @todo: deprecated?
export const getFcoFeeOption =
  (state, getters, rootState, rootGetters) => (fee_field_record) => {
    const strings = [fee_field_record.name];
    if (fee_field_record.location) {
      strings.push(fee_field_record.location);
    }
    if (fee_field_record.time) {
      strings.push(fee_field_record.time);
    }
    if (fee_field_record.description) {
      strings.push(fee_field_record.description);
    }
    const label = strings.length > 1 ? strings.join(" - ") : strings[0];
    const value = fee_field_record.value;
    return { label, value };
  };

export const ancillaryFeesAccountingCodes = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  return state.ancillaryFeesAccountingCodes;
};
//- @todo: deprecated?
export const getFcoFeeOptions =
  (state, getters, rootState, rootGetters) =>
  (country_code, legalisation_agent = false) => {
    const fee_field_records = getters.getFcoDefinitions;
    let options = [];
    for (let record of fee_field_records) {
      switch (legalisation_agent) {
        case types.LEGALISATION_AGENT.external:
          if (record.type === "external") {
            options.push(getters.getFcoFeeOption(record));
          }
          break;
        case types.LEGALISATION_AGENT.internal:
          if (record.type === "internal") {
            options.push(getters.getFcoFeeOption(record));
          }
          break;
        default:
          options.push(getters.getFcoFeeOption(record));
          break;
      }
    }
    return options;
  };
//- @todo: deprecated?
export const getFcoDefinitions = (state, getters, rootState, rootGetters) => {
  //- #509
  const primaryservices = rootGetters["primaryservices/all"]
    .filter((x) => x.attributes.legalisationCode === 2) // i.e. Tier 1
    .map((x) => {
      return { ...x.attributes, value: x.id };
    });
  return primaryservices;
  //- @deprecated static state
  // const fee_field_records = state.options.fco_fee_definitions;
  // return fee_field_records
};

export const getSelectedFeeFieldRecords =
  (state, getters, rootState, rootGetters) => (country_code) => {
    const countryStep =
      rootGetters["submissions/getCountryLegalisationStep"](country_code);
    const fee_field_records = getters.getFcoDefinitions.filter((x) => {
      return x.value === countryStep.primaryServiceId;
    });
    return fee_field_records;
  };

//- @todo: deprecated?
export const getFcoFeeLabel =
  (state, getters, rootState, rootGetters) => (country_code) => {
    const fee_field_record =
      getters.getSelectedFeeFieldRecords(country_code)[0]; // this is ok because only one field is selected
    //- return the label
    return _.isEmpty(fee_field_record) ? "" : fee_field_record.label;
  };

//- @todo: deprecated?
export const getFcoFeeDescription =
  (state, getters, rootState, rootGetters) => (country_code) => {
    const fee_field_record = getters.getSelectedFeeFieldRecords(country_code);
    return _.isEmpty(fee_field_record) ? "" : fee_field_record.description;
  };
export const getFcoFeePrice = (state) => (code) => {
  let fee_field_record = state.options.fco_fee_definitions.filter(
    (x) => x.value === code
  )[0];
  return fee_field_record.price;
};

export const getFeeRowsForCountrySection =
  (state, getters, rootState, rootGetters) => (countryCode, section) => {
    const rows = rootGetters["submissions/getCountryCollectionValue"](
      `${rootGetters["submissions/formDataPath"]}.attributes.fees`,
      countryCode,
      `${section}`
    );
    return rows;
  };

//- @deprecated: this was predicated on multiple rows being stored in the same node and differentiated by keyname
export const getFeeFieldNames = (state) => (fees) => {
  return Object.keys(fees).reduce((acc, cur, idx, src) => {
    if (acc === null) acc = [];
    if (cur.includes("amount")) acc.push(cur.substr(0, cur.length - 7));
    return acc;
  }, null);
};
export const getFeesPerDocument = (state) => {
  return state.fees_per_document;
};

export const feeSections = (state) => {
  return state.feeSections;
};

export const notarialFeeType = (state) => {
  return state.notarialFeeType;
};

export const countExpectedDocumentsInCountry =
  (state, getters, rootState, rootGetters) => (country_code) => {
    return rootGetters["submissions/getCountryCollectionValue"](
      `${rootGetters["submissions/formDataPath"]}.attributes.expectedDocuments`,
      country_code,
      "number"
    );
  };

export const countExpectedDocumentsinAllCountries = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const countryCodes = rootGetters["submissions/requiredCountries"].map(
    (country) => country.code
  );
  let count = 0;
  countryCodes.forEach((country_code) => {
    count += Number(getters.countExpectedDocumentsInCountry(country_code));
  });
  return count;
};

export const perDocumentQuantity =
  (state, getters, rootState, rootGetters) => (field, country_code) => {
    if (field) {
      return Number(
        rootGetters["submissions/getCountryCollectionValue"](
          `${rootGetters["submissions/formDataPath"]}.attributes.expectedDocuments`,
          country_code,
          "number"
        )
      );
    } else {
      const legalisationSteps = rootGetters[
        "submissions/getCountryCollectionValue"
      ](
        `${rootGetters["submissions/formDataPath"]}.attributes.legalisationSteps`,
        country_code,
        "selected"
      );

      if (
        field === "fco_standard_postal_service_fee" &&
        [0, -1].some((v) => legalisationStepsSelected.includes(v))
      ) {
        return 0;
      } else {
        return 1;
      }
    }
  };

//- only applicable for notary and ancillary fees
export const vatRequired = (state) => (feeKey) => {
  return Number(state.fee_vat_status[feeKey]);
};

//- @todo: deprecated?
export const getFcoPostalPrice = (state) => (val) => {
  return (
    state.options.fco_fee_definitions.filter((x) => x.value === val)[0].price ||
    0
  );
};

export const showFCOfee = (state, getters) => (country_code) => {
  if (_.isEmpty(getters.step(country_code))) return false;
  const attractsFees = [
    types.FEE_FCO.london,
    types.FEE_FCO.standard,
    types.FEE_FCO.miltonkeynes,
  ];
  let show =
    !empty(getters.step(country_code).primaryServiceId) &&
    attractsFees.includes(Number(getters.step(country_code).primaryServiceId));
  return show;
};

export const showStandardServiceFee = (state, getters) => (country_code) => {
  return getters.step(country_code).primaryServiceId === types.FEE_FCO.standard;
};

export const showEmbassyFees = (state, getters) => (country_code) => {
  return getters
    .step(country_code)
    .selected.includes(types.COUNTRY_RULES.consulate);
};

export const showChamberFees = (state, getters) => (country_code) => {
  return getters
    .step(country_code)
    .selected.includes(types.COUNTRY_RULES.chamber);
};

export const step =
  (state, getters, rootState, rootGetters) => (country_code) => {
    return rootGetters["submissions/getCountryLegalisationStep"](country_code);
  };

export const feeLabels = (state) => {
  return state.fee_labels;
};

export const serviceTier = (state, getters, rootState, rootGetters) => (id, from) => {
  const legalisationCode = rootGetters["primaryservices/byId"]({
    id,
  })?.attributes?.legalisationCode;
  if(!legalisationCode) return null;
  const tier = rootGetters["countries/tierByLegalisationCode"](legalisationCode);
  return tier
};

export const validateAllFeesHaveAmounts =
  (state, getters, rootState, rootGetters) => (entity) => {
    if (empty(entity?.attributes?.fees)) return false;
    if (empty(entity?.attributes?.notaryFeeType)) return false;

    for (let countryFees of entity.attributes.fees) {
      const sections = state.feeSections;

      for (let section in state.feeSections) {
        const fees = countryFees[section];
        if (Array.isArray(fees) && fees.length == 0 && section == "defaultfees")
          return false;
        if (Array.isArray(fees) && fees.length == 0) continue;
        if (Array.isArray(fees) && fees.length > 0) {
          for (let fee of fees) {
            if (empty(fee.amount) || fee.amount == 0 || fee.amount == "0.00") {
              return false;
            }
          }
        }
      }
    }
    return true;
  };

export const currentDefaultFees =
  (state, getters, rootState, rootGetters) => (countryCode) => {
    const existingFees = rootGetters["submissions/getField"](state)(
      `${rootGetters["submissions/formDataPath"]}.attributes.fees`
    );
    if (!existingFees || !existingFees.length) return [];
    const feesFilteredForCountry = existingFees.filter(
      (x) => x.country_code === countryCode
    );
    if (!feesFilteredForCountry || !feesFilteredForCountry.length) return [];
    const defaultFees = feesFilteredForCountry[0].defaultfees || [];
    return defaultFees;
  };

export const primaryServiceDefaultFees =
  (state, getters, rootState, rootGetters) => (countryCode, from) => {
    if (
      rootGetters["submissions/isLegalisationStepOverridden"](
        countryCode
      )
    )
      return [];
     
    const legalisationStep = rootGetters["submissions/step"](countryCode);
    const ruleIds = rootGetters["countries/ruleIds"](countryCode);
    // window.log.d("legalisationStep", legalisationStep);
    // window.log.d("ruleIds", ruleIds);

    const defaultfees = [];
    if (!ruleIds || !ruleIds.length) return [];
    for (let ruleId of ruleIds) {
      const fee = rootGetters[
        "countries/defaultFeesByRuleIdAndLegalisationStep"
      ](ruleId, legalisationStep);
      // window.log.d("fee", fee);
      if (fee) {
        defaultfees.push(...fee);
      }
    }
    return defaultfees;
  };

export const dynamicFeeRowData =
  (state, getters, rootState, rootGetters) => (values, from) => {
    const row = {
      accountingCode:
        values.entity?.attributes?.accountingCode ||
        values.entity?.accountingCode ||
        null,
      amount: values.entity?.attributes?.price || values.entity?.amount || "0",
      country_code: values.country_code,
      feeIdx: values.feeIdx,
      label: values.entity?.attributes?.name || values.entity?.label || "",
      nodeName: values.nodeName,
      perDocument:
        values.entity?.attributes?.perDocument ||
        values.entity?.perDocument ||
        false,
      purview: values.entity?.attributes?.purview || 2,
      quantity:
        values.entity?.attributes?.quantity || values.entity?.quantity || null,
      quantitySetManually:
        values.quantitySetManually ||
        values.entity?.attributes?.quantity ||
        values.entity?.quantity
          ? true
          : false,
      tier: values.tier || "",
      type: 2, //- [1: estimate, 2: fixed]
      vatPercentage: values.vatPercentage || values.entity?.vatPercentage || 20,
      vatRequired:
        values.entity?.attributes?.vat || values.entity?.vatRequired || false,
    };
    if (values.entity?.id) {
      row.id = values.entity.id;
    }
    return row;
  };
