export const setLeftDrawerOpen = (state, payload) => {
  state.leftDrawerOpen = payload;
};
export const toggleLeftDrawerOpen = (state) => {
  state.leftDrawerOpen = !state.leftDrawerOpen;
};

export const setNotarialStep = (state, val) => {
  state.notarial.currentStep = val;
};

export const setDebug = (state, val) => {
  state.debug = val;
};

export const setTest = (state, val) => {
  state.test = val;
};

export function setActingAs(state, val) {
  state.actingAs = val;
}
