import { uid } from "quasar";
import { axios } from "src/boot/axios";

// import { snakeToLowerCamel } from "src/utils";
// import { complement, intersection } from "set-manipulator";

/**
 * list any tables that should be excluded from schema hydration.
 * This should include those that don't have the action type: /storeSchema.
 * @why to prevent console errors for those that don't have the action type.
 * If any exclusions are set, inclusions will be ignored – i.e. exclusions take precedence.
 * @deprecated was used only by mergeSchemaIntoTableStores, which has been deprecated
 */
// const exclusions = [
// "restaurants",
// "bankdetails",
// "capacities",
// "entityTypes",
// "csvImports",
// "devices",
// "dishes",
// "dishesRestaurants"
// ];

/**
 * list any tables that should be included from schema hydration; all other tables will be excluded.
 * This should include those that do have the action type: /storeSchema.
 * @why to prevent console errors for those that don't have the action type.
 * @deprecated was used only by mergeSchemaIntoTableStores, which has been deprecated
 */
// const inclusions = ["settings"];

/**
 * Uses set theory manipulators to return a list of tables on which to store schemas
 * @param {array} inclusions
 * @param {array} exclusions
 * @param {array} tables
 * @returns {array}
 * @deprecated was used only by mergeSchemaIntoTableStores, which has been deprecated
 */
// export const getTablesToMap = (tables, inclusions = [], exclusions = []) => {
//   let tablesToMap = [];
//   if (tables.length) {
//     if (exclusions.length) {
//       tablesToMap = complement(
//         tables.map(table => snakeToLowerCamel(table)),
//         exclusions
//       );
//     } else if (inclusions.length) {
//       tablesToMap = intersection(
//         tables.map(table => snakeToLowerCamel(table)),
//         inclusions
//       );
//     } else {
//       tablesToMap = tables;
//     }
//   }
//   // console.log("tablesToMap", tablesToMap);
//   return tablesToMap;
// };

/**
 * Merges schema for a given table into the vuex table store.
 * This was done to provide the convenience of having a store know it's own schema.
 * However there are two problems with this:
 * 1. This requires store state to be objects, not functions (as you can't merge functions).
 * But Vue has taken the position that functions are preferred and many libraries rely on this.
 * 2. Stores are intended to be modular and unmodified externally.
 * So it's not recommended.
 * @param {array} tablesToMap
 * @deprecated stores are meant to be modular and merging only supports stores with object states (not function states), thus should not merge
 */
// export const mergeSchemaIntoTableStores = (context, data) => {
//   if (!data.tables) throw Error("Could not merge schemas; table data missing");
//   const tablesToMap = getTablesToMap(data.tables, inclusions, exclusions);
//   tablesToMap.map(table => {
//     const action = `${table}/storeSchema`;
//     // console.log("schema store: fetch action", action);
//     // console.log("schema store: fetch schema", data.schemas[table]);
//     // console.log("schema store: fetch context", context);
//     context.dispatch(action, data.schemas[table], { root: true });
//   });
// };

/**
 * Fetches all the table schemas in one requests, then stores them individually to each vuex store
 * @param {object} context
 */
export const fetch = async function(context) {
  const response = await axios.json.get("schema.json"); //- standard JSON request, thus use axios directly
  context.commit("storeState", response.data);
  return response;
};

export function getEmptyRow(context, options) {
  const { schema, insert = true } = options;
  let emptyRow = context.getters.emptyRow(schema, uid(), insert);
  return emptyRow;
}

export const resetPagination = context => {
  context.commit("setPagination", context.getters.paginationDefaults);
};
