export default function() {
  return {
    timeLastPinged: null,
    pingExpired: null,
    networkOnline: false,
    serverOnline: false,
    pingExpiredThreshold: 1000,
    lastPingStatusCode: null,
    enabled: true,
    isLoggedIn: false,
    isLoaded: false,
    isError: false,
    tuning: {
      delayMs: 0,
      throttleMs: 0,
      debounceMs: 0
    }
  };
}
