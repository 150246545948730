import { _ } from "core-js";
import { toReststateRecord } from "src/utils";
import { ResourceClient } from "src/utils/reststate-vuex/client";
import { intersection, compliment } from "set-manipulator";
import { empty } from 'locutus/php/var';

import deepEquals from "@reststate/vuex/src/deepEquals";

const STATUS_INITIAL = "INITIAL";
const STATUS_LOADING = "LOADING";
const STATUS_ERROR = "ERROR";
const STATUS_SUCCESS = "SUCCESS";

const storeRecord = (records) => (newRecord) => {
  const existingRecord = records.find((r) => r.id === newRecord.id);
  if (existingRecord) {
    Object.assign(existingRecord, toReststateRecord(newRecord));
  } else {
    records.push(toReststateRecord(newRecord));
  }
};

/**
 * Strip out unnecessary properties from object
 * @param {object} resource
 * @returns {object}
 */
const getResourceIdentifier = (resource) => {
  if (!resource) {
    return resource;
  }

  return {
    type: resource.type,
    id: resource.id,
  };
};

const getRelationshipType = (relationship) => {
  const data = Array.isArray(relationship.data)
    ? relationship.data[0]
    : relationship.data;
  return data && data.type;
};

const storeIncluded = ({ commit, dispatch }, result) => {
  if (result.included) {
    // store the included records
    result.included.forEach((relatedRecord) => {
      // console.log("include storeRecord relatedRecord", relatedRecord);
      const action = `${relatedRecord.type}/storeRecord`;
      dispatch(action, relatedRecord, { root: true });
    });

    // store the relationship for primary and secondary records
    let allRecords = [...result.included];
    if (Array.isArray(result.data)) {
      allRecords = [...allRecords, ...result.data];
    } else {
      allRecords = [...allRecords, result.data];
    }

    allRecords.forEach((primaryRecord) => {
      if (primaryRecord.relationships) {
        // console.log("include storeIncluded primaryRecord", primaryRecord);

        Object.keys(primaryRecord.relationships).forEach((relationshipName) => {
          // console.log(
          //   "include storeIncluded relationshipName ",
          //   relationshipName
          // );
          const relationship = primaryRecord.relationships[relationshipName];
          if (!relationship.data || relationship.data.length === 0) {
            return;
          }
          // console.log("include storeIncluded relationship", relationship);
          const type = getRelationshipType(relationship);
          let relatedIds;
          if (Array.isArray(relationship.data)) {
            relatedIds = relationship.data.map(
              (relatedRecord) => relatedRecord.id
            );
          } else {
            ({ id: relatedIds } = relationship.data);
          }
          // console.log("include storeIncluded relatedIds", relatedIds);
          const params = {
            parent: getResourceIdentifier(primaryRecord),
            relationship: relationshipName,
          };
          // console.log("include storeIncluded params", params);
          const options = {
            relatedIds,
            params,
          };
          const action = `${type}/storeRelated`;
          // console.log("include storeIncluded action", action);
          dispatch(action, options, { root: true });
        });
      }
    });
  }
};

const matchesIndices = (criteria, array) => {
  return array.reduce((indices, item, index) => {
    const isMatch = Object.keys(criteria).every(key => {
      // console.log("getRelated matchesIndices key", key);
      // console.log("getRelated matchesIndices criteria[key]", criteria[key]);
      // console.log("getRelated matchesIndices item[key]", item[key]);
      const match = deepEquals(criteria[key], item[key])
      // console.log("getRelated matchesIndices match", match);
      return match
    });
    if (isMatch) {
      indices.push(index);
    }
    return indices;
  }, []);
};



const matches = (criteria) => (test) => {
  // console.log("getRelated matches criteria", criteria);
  // console.log("getRelated matches test", test);
  return Object.keys(criteria).every((key) => {
    // console.log("getRelated key", key);
    // console.log("getRelated criteria[key]", criteria[key]);
    // console.log("getRelated test[key]", test[key]);
    const match = deepEquals(criteria[key], test[key])
    // console.log('match', match)
    return match;
  });
};

const matchesAlt = (criteria) => (test) => {
  return Object.keys(criteria).every((key) =>
    deepEquals(criteria[key], test[key])
  );
};

const handleError = (commit, state) => (errorResponse) => {
  console.log("reststate index handleError");
  commit("STORE_ERROR", errorResponse);
  commit("SET_STATUS", STATUS_ERROR);
  if (state) {
    console.log(state);
  }
  throw errorResponse;
};

const initialState = (resourceName) => {
  return {
    records: [],
    related: [],
    filtered: [],
    page: [],
    error: null,
    status: STATUS_INITIAL,
    links: {},
    lastCreated: null,
    lastMeta: null,
    storeName: resourceName,
  };
};

const resourceModule = ({ name: resourceName, httpClient }) => {
  const client = new ResourceClient({ name: resourceName, httpClient });

  /**
   * Returns a compound identifier – not an actual array index
   * @param {*} params
   * @returns {object} with parent and relationship properties
   */
  const getRelationshipIndex = (params) => {
    // console.log("getRelationshipIndex params", params);
    // if(typeof params == 'undefined'){
    // console.trace();
    // }
    const { parent, relationship = resourceName } = params;
    // console.log("getRelationshipIndex parent", parent);
    // console.log("getRelationshipIndex relationship", relationship);
    const parentResourceIdentifier = getResourceIdentifier(parent);
    // console.log(
    //   "getRelationshipIndex parentResourceIdentifier",
    //   parentResourceIdentifier
    // );
    const relationshipIndex = {
      parent: parentResourceIdentifier,
      relationship,
    };
    // console.log("relationshipIndex", relationshipIndex);
    // console.trace();
    return relationshipIndex;
  };

  return {
    namespaced: true,

    state: initialState(resourceName),

    mutations: {
      REPLACE_ALL_RECORDS: (state, records) => {
        state.records = records;
      },

      REPLACE_ALL_RELATED: (state, related) => {
        state.related = related;
      },

      REPLACE_ALL_FILTERED: (state, filtered) => {
        state.filtered = filtered;
      },

      SET_STATUS: (state, status) => {
        state.status = status;
      },

      STORE_RECORD: (state, newRecord) => {
        const { records } = state;
        storeRecord(records)(newRecord);
      },

      STORE_RECORDS: (state, newRecords) => {
        const { records } = state;
        newRecords.forEach(storeRecord(records));
      },

      STORE_PAGE: (state, records) => {
        state.page = records.map(({ id }) => id);
      },

      STORE_META: (state, meta) => {
        state.lastMeta = meta;
      },

      STORE_ERROR: (state, error) => {
        state.error = error;
      },

      /**
       * Similar to STORE_RELATED escept will preserve existing related ids if they
       * are already in Vuex. Should be used with care – best when you want to update
       * partials of an iteration.
       * @param {array|string} relatedIds
       * @param {*} params
       */
      ADD_RELATED: (state, { relatedIds, params }) => {
        console.log("ADD_RELATED params", params);
        const { related } = state;
        const relationshipIndex = getRelationshipIndex(params);
        console.log("ADD_RELATED relationshipIndex", relationshipIndex);
        const match = matches(relationshipIndex)
        console.log("ADD_RELATED match", match);

        const existingRecord = related.find(match);
        console.log("ADD_RELATED existingRecord", existingRecord);

        const idCandidatesForAdding = Array.isArray(relatedIds) ? relatedIds : [relatedIds];

        //- if related record exists, modify it
        if (existingRecord) {

          //- if record exists but has no related ids, then just add the related ids
          if (empty(existingRecord.relatedIds)) {
            existingRecord.relatedIds = relatedIds;
          }

          //- otherwise concatenate 
          else {
            const idsToAdd = idCandidatesForAdding.filter((val, idx) => {
              if (Array.isArray(existingRecord.relatedIds)) {
                const exists = existingRecord.relatedIds.find((rec) => rec.id == val);
                return !exists;
              } else {
                const exists = existingRecord.relatedIds = val;
                return !exists;
              }
            });
            if (empty(idsToAdd)) return;
            existingRecord.relatedIds = [...existingRecord.relatedIds, ...idsToAdd];
          }
        }

        //- if related record does not exist, create it
        else {
          related.push(Object.assign({ relatedIds }, relationshipIndex));
        }
      },

      /**
       * Searches for an existing parent match in the store's relationships,
       * If found, it overwrites all related IDs for that match
       * If nof found, it appends a new parent compound key with the passed Ids
       * @param {array|string} relatedIds
       * @param {*} params
       */
      STORE_RELATED: (state, { relatedIds, params }) => {
        console.debug("STORE_RELATED params", params);

        const { related } = state;
        const relationshipIndex = getRelationshipIndex(params);
        // console.log("relationshipIndex", relationshipIndex);
        const existingRecord = related.find(matches(relationshipIndex));
        // console.log("relationshipIndex", relationshipIndex);

        if (existingRecord) {
          existingRecord.relatedIds = relatedIds;
        } else {
          related.push(Object.assign({ relatedIds }, relationshipIndex));
        }
      },

      STORE_FILTERED: (state, { matchedIds, params }) => {
        const { filtered } = state;

        const existingRecord = filtered.find(matches(params));
        if (existingRecord) {
          existingRecord.matchedIds = matchedIds;
        } else {
          filtered.push(Object.assign({ matchedIds }, params));
        }
      },

      STORE_LAST_CREATED: (state, record) => {
        state.lastCreated = record;
      },

      REMOVE_ALL_RECORDS: (state) => {
        state.records = [];
      },

      REMOVE_RECORD: (state, record) => {
        state.records = state.records.filter((r) => r.id !== record.id);
      },

      REMOVE_PAGED_RECORD: (state, record) => {
        state.page = state.page.filter((r) => r !== record.id);
      },

      REMOVE_RELATED: (state, { relatedIds, params }) => {
        const { related } = state;
        const relationshipIndex = getRelationshipIndex(params);
        const existingRecord = related.find(matches(relationshipIndex));
        // console.log("relationshipIndex", relationshipIndex);

        if (existingRecord) {
          const toRemove = !_.isArray(relatedIds) ? [relatedIds] : relatedIds;
          toRemove.forEach((id) => {
            existingRecord.relatedIds.splice(
              existingRecord.relatedIds.indexOf(id),
              1
            );
          });
        }
      },

      INITIALISE_RELATED: (state) => {
        state.related = [];
      },

      REMOVE_ALL_RELATED: (state, { params }) => {
        const { related } = state;
        console.debug("REMOVE_ALL_RELATED params", params);
        console.debug("REMOVE_ALL_RELATED related", related);
        const relationshipIndex = getRelationshipIndex(params);
        console.debug("REMOVE_ALL_RELATED relationshipIndex", relationshipIndex);

        const found = matchesIndices(relationshipIndex, related);
        console.debug("REMOVE_ALL_RELATED found", found);

        if (found?.length > 0) {
          console.debug("REMOVE_ALL_RELATED splice");
          for (const foundIndex of found) {
            related.splice(foundIndex, 1);
          }
        } else {
          console.debug("REMOVE_ALL_RELATED no match: nothing to remove");
        }
        console.debug("REMOVE_ALL_RELATED related", related);
      },

      SET_LINKS: (state, links) => {
        state.links = links || {};
      },

      RESET_STATE: (state) => {
        Object.assign(state, initialState(resourceName));
      },
    },

    actions: {
      loadAll({ commit, dispatch }, { options } = {}) {
        // console.trace();
        return client
          .all({ options })
          .then((result) => {
            commit("REPLACE_ALL_RECORDS", result.data);
            commit("STORE_META", result.meta);
            storeIncluded({ commit, dispatch }, result);
          })
          .catch(handleError(commit));
      },

      loadById({ commit, dispatch }, { id, options }) {
        return client
          .find({ id, options })
          .then((results) => {
            commit("STORE_RECORD", results.data);
            commit("STORE_META", results.meta);
            storeIncluded({ commit, dispatch }, results);
          })
          .catch(handleError(commit));
      },

      loadWhere({ commit, dispatch }, params) {
        const { filter, options } = params;
        return client
          .where({ filter, options })
          .then((results) => {
            const matches = results.data;
            const matchedIds = matches.map((record) => record.id);
            commit("STORE_RECORDS", matches);
            commit("STORE_FILTERED", { params, matchedIds });
            commit("STORE_META", results.meta);
            storeIncluded({ commit, dispatch }, results);
          })
          .catch(handleError(commit));
      },

      loadPage({ commit, dispatch }, params) {
        // console.log('loadPage params', params);
        const { filter, options } = params;
        // // console.log('filter type', typeof filter);
        // console.log('filter', filter);
        if (typeof filter == "undefined" || !filter.search) {
          // console.log('loadPage all');
          return client
            .all({ options })
            .then((response) => {
              commit("STORE_RECORDS", response.data);
              commit("STORE_PAGE", response.data);
              commit("STORE_META", response.meta);
              commit("SET_LINKS", response.links);
              storeIncluded({ commit, dispatch }, response);
            })
            .catch(handleError(commit));
        } else {
          // console.log('loadPage where');
          return client
            .where({ filter, options })
            .then((response) => {
              commit("STORE_RECORDS", response.data);
              commit("STORE_PAGE", response.data);
              commit("STORE_META", response.meta);
              commit("SET_LINKS", response.links);
              storeIncluded({ commit, dispatch }, response);
            })
            .catch(handleError(commit));
        }
      },

      loadNextPage({ commit, state, dispatch }) {
        const options = {
          url: state.links.next,
        };
        return client.all({ options }).then((response) => {
          commit("STORE_RECORDS", response.data);
          commit("STORE_PAGE", response.data);
          commit("SET_LINKS", response.links);
          commit("STORE_META", response.meta);
          storeIncluded({ commit, dispatch }, response);
        });
      },

      loadPreviousPage({ commit, state, dispatch }) {
        const options = {
          url: state.links.prev,
        };
        return client.all({ options }).then((response) => {
          commit("STORE_RECORDS", response.data);
          commit("STORE_PAGE", response.data);
          commit("SET_LINKS", response.links);
          commit("STORE_META", response.meta);
          storeIncluded({ commit, dispatch }, response);
        });
      },

      /**
       * Given a parent record, loads the related records via the relationship.
       * This action is called on the related store.
       * User hasMany Articles. Article belongsTo a user.
       * Thus: dispatch('articles/loadRelated', {parent: <People>, relationship: 'articles'})
       * @param {object} parent
       * @param {string} relationship
       * @param {object} options
       * @returns {void}
       */
      loadRelated({ commit, dispatch }, params) {
        const { parent, relationship = resourceName, options } = params;
        const paramsToStore = {
          ...params,
          relationship,
        };
        return client
          .related({ parent, relationship, options })
          .then((results) => {
            const { id, type } = parent;
            if (Array.isArray(results.data)) {
              const relatedRecords = results.data;
              const relatedIds = relatedRecords.map((record) => record.id);
              commit("STORE_RECORDS", relatedRecords);
              commit("STORE_RELATED", { params: paramsToStore, relatedIds });
            } else {
              const record = results.data;
              const relatedIds = record.id;
              commit("STORE_RECORDS", [record]);
              commit("STORE_RELATED", { params: paramsToStore, relatedIds });
            }
            commit("STORE_META", results.meta);
            storeIncluded({ commit, dispatch }, results);
          })
          .catch(handleError(commit));
      },

      //@custom @todo: not sure this works, just an idea
      // loadRelatedWhere({ commit, dispatch }, params) {
      //   const { parent, relationship = resourceName, options } = params;
      //   const paramsToStore = {
      //     ...params,
      //     relationship
      //   };
      //   return client
      //     .related({ parent, relationship, options })
      //     .where({ filter, options })
      //     .then(results => {
      //       const { id, type } = parent;
      //       if (Array.isArray(results.data)) {
      //         const relatedRecords = results.data;
      //         const relatedIds = relatedRecords.map(record => record.id);
      //         commit("STORE_RECORDS", relatedRecords);
      //         commit("STORE_RELATED", { params: paramsToStore, relatedIds });
      //       } else {
      //         const record = results.data;
      //         const relatedIds = record.id;
      //         commit("STORE_RECORDS", [record]);
      //         commit("STORE_RELATED", { params: paramsToStore, relatedIds });
      //       }
      //       commit("STORE_META", results.meta);
      //       storeIncluded({ commit, dispatch }, results);
      //     })
      //     .catch(handleError(commit));
      // },

      create({ commit, state }, recordData) {
        return client.create(recordData).then((result) => {
          commit("STORE_RECORD", result.data);
          commit("STORE_LAST_CREATED", result.data);
          return result;
        }).catch(handleError(commit, state));
        /**
         * @deprecated: catch here prevents the api wrap from handling errors
         */
        // .catch(errorResponse => {
        //   // console.log("@reststate index catch", errorResponse);
        // });
      },

      update({ commit, dispatch, getters }, record) {
        return client.update(record).then(() => {
          const oldRecord = getters.byId({ id: record.id });

          // remove old relationships first
          if (oldRecord && oldRecord.relationships) {
            for (const entry of Object.entries(oldRecord.relationships)) {
              const [relationship, entity] = entry;
              const type = getRelationshipType(relationship);
              const paramsToStore = {
                relationship,
                parent: getResourceIdentifier(oldRecord),
              };
              if (type) {
                dispatch(
                  `${type}/storeRelated`,
                  {
                    params: paramsToStore,
                    relatedIds: null,
                  },
                  { root: true }
                );
              }
            }
          }

          // save entity
          commit("STORE_RECORD", record);

          // set new relationships
          if (record.relationships) {
            for (const relationship of Object.keys(record.relationships)) {
              const relationshipObject = record.relationships[relationship];
              const { data } = relationshipObject;
              const isNonEmptyArray =
                Array.isArray(data) && Boolean(data.length);
              const isObject = Boolean(data && data.type && data.id);

              if (isNonEmptyArray || isObject) {
                const paramsToStore = {
                  parent: getResourceIdentifier(record),
                  relationship,
                };
                const type = getRelationshipType(relationshipObject);
                let relatedIds;
                if (Array.isArray(data)) {
                  relatedIds = data.map((record) => record.id);
                } else {
                  relatedIds = data.id;
                }
                dispatch(
                  `${type}/storeRelated`,
                  {
                    params: paramsToStore,
                    relatedIds,
                  },
                  { root: true }
                );
              }
            }
          }
        });
      },

      delete({ commit }, record) {
        return client.delete(record).then(() => {
          commit("REMOVE_RECORD", record);
          commit("REMOVE_PAGED_RECORD", record);
        });
      },

      storeRecord({ commit }, record) {
        commit("STORE_RECORD", record);
      },

      storeRelated({ commit }, { relatedIds, params }) {
        // console.log("include storeRelated", relatedIds, params);
        commit("STORE_RELATED", {
          relatedIds,
          params,
        });
      },

      removeRecord({ commit }, record) {
        commit("REMOVE_RECORD", record);
      },

      resetState({ commit }) {
        commit("RESET_STATE");
      },

      /**
       * Given a JSON api URL of /library/2/books, `library` would be parent and relationship, `books` would be current store, ids would be book ids
       * @param {object} parent reststate entity
       * @param {string} relationship defaults to parent store name
       * @param {string} readRelationship defaults to relationship but can be an override if the relationship to read from differs from that to write from (e.g. people / clients)
       * @param {array} data of objects {id:Number|String ,type:String }
       */
      addRelated({ commit, getters }, params) {
        // console.trace();

        // console.log("addRelated params", params);
        const getterParams = _.clone(params);
        if (params.readRelationship) {
          getterParams.relationship = params.readRelationship;
          delete getterParams.readRelationship;
          delete params.readRelationship;
        }
        let relatedItems = _.clone(getters.related(getterParams)); // existing reststate entities
        // console.log("reststate addRelated relatedItems", relatedItems);
        if (
          //- @why: null means there are no existing related records
          relatedItems !== null &&
          //- @why: if it's not an array then it is a to-one relationship, which addRelated is not designed to handle, use setRelated instead (as per JSONAPI spec)
          !Array.isArray(relatedItems)
        ) {
          throw new Error("POST requests not allowed for to-one relationships");
        }
        const { parent, relationship = resourceName, data } = params,
          dataArray = Array.isArray(data) ? data : [data],
          toAdd =
            relatedItems === null
              ? dataArray
              : dataArray.filter(
                (x) => !relatedItems.map((o) => o.id).includes(x.id)
              );

        // console.log("parent", parent);
        // console.log("relationship", relationship);
        // console.log("data", data);
        // console.log("toAdd", toAdd);

        if (!toAdd.length) return;
        return client
          .postRelationships(parent, relationship, toAdd)
          .then(() => {
            let relatedIds;
            if (Array.isArray(toAdd)) {
              relatedIds = toAdd.map((record) => record.id);
            } else {
              relatedIds = toAdd.id;
            }
            commit("ADD_RELATED", {
              params: { parent, relationship },
              relatedIds,
            });
          });
      },

      setRelated(context, params) {
        /**
         * Given a JSON api URL of /library/2/books, `library` would be parent and relationship, `books` would be current store, ids would be book ids
         * @param {object} parent reststate entity
         * @param {string} relationship defaults to parent store name
         * @param {array} data of objects {id:Number|String ,type:String }
         */
        const { parent, relationship = resourceName, data } = params;
        const fwdParams = {
          parent,
          relationship,
        };
        const oldFwdRelationships = context.getters["related"](fwdParams);
        const oldFwdRelationshipsIsArray = Array.isArray(oldFwdRelationships);

        return client
          .patchRelationships(parent, relationship, data)
          .then((results) => {
            let relatedIds, toAdd;
            const dataIsArray = Array.isArray(data);
            if (dataIsArray) {
              toAdd = relatedIds = data.map((record) => record.id);
            } else {
              relatedIds = data.id;
              toAdd = [data.id];
            }
            //- store fwd relationships
            context.commit("STORE_RELATED", {
              params: { parent, relationship },
              relatedIds,
            });

            // /**
            //  * @deprecated: put a lot of effort into trying to get this
            //  * to also store reverse relationships. However that idea doesn't work because
            //  * it makes the assumption that other related reverse relationship records have been loaded
            //  * which is not necessarily the case. So the only option is to loadRelated after doing the save
            //  */
            // //- add new reverse relationships
            // toAdd.forEach(id => {
            //   let newRevRelationships = context.rootGetters[
            //     `${parent.type}/related`
            //   ]({
            //     parent: { type: resourceName, id },
            //     relationship: parent.type
            //   });
            //   context.commit(
            //     `${parent.type}/STORE_RELATED`,
            //     {
            //       params: {
            //         parent: { type: resourceName, id },
            //         relationship: parent.type
            //       },
            //       relatedIds: Array.isArray(newRevRelationships)
            //         ? [...newRevRelationships.map(x => x.id), parent.id]
            //         : parent.id
            //     },
            //     { root: true }
            //   );
            // });
          });
      },

      removeRelated({ commit }, params) {
        // console.trace();

        /**
         * Given a JSON api URL of /library/2/books,
         * - `library` would be parent entity (and relationship by default),
         * - `books` would be the store from which this method is being called,
         * -  data would be book ids
         * @param {object} parent reststate entity
         * @param {string} relationship defaults to parent store name
         * @param {array|object} data ids to remove {id:Number|String ,type:String }
         */
        const { parent, relationship = resourceName, data } = params;
        const dataArray = Array.isArray(data) ? data : [data];
        return client
          .removeRelationships(parent, relationship, dataArray)
          .then((response) => {
            commit("REMOVE_ALL_RELATED", { params: { parent, relationship } });
            commit("STORE_RELATED", {
              params: { parent, relationship },
              relatedIds: response.data.map((x) => x.id),
            });
          });
      },

      removeAllRelated({ commit }, params) {
        // console.trace();
        const { parent, relationship = resourceName, toOne = false } = params;
        // const dataArray = Array.isArray(data) ? data : [data];
        return client
          .clearRelationships(parent, relationship, toOne)
          .then(() => {
            commit("REMOVE_ALL_RELATED", {
              params: { parent, relationship },
            });
          });
      },
    },

    getters: {
      storeName() {
        return resourceName
      },
      isLoading: (state) => state.status === STATUS_LOADING,
      isError: (state) => state.status === STATUS_ERROR,
      status: (state) => state.status,
      error: (state) => state.error,
      hasPrevious: (state) => !!state.links.prev,
      hasNext: (state) => !!state.links.next,
      all: (state) => state.records,
      lastCreated: (state) => state.lastCreated,
      byId:
        (state) =>
          ({ id }) =>
            state.records.find((r) => r.id == id),
      //@custom
      idIn:
        (state) =>
          ({ ids }) =>
            state.records.filter((r) => {
              return ids.includes(r.id);
            }),
      byField:
        (state) =>
          ({ value, field }) =>
            state.records.find((r = r[field] == value)),
      lastMeta: (state) => state.lastMeta,
      page: (state) =>
        state.page.map((id) =>
          state.records.find((record) => record.id === id)
        ),
      where: (state) => (params) => {
        // console.log("where params", params);
        // console.log("where state.filtered", state.filtered);
        // console.log("where matches params", matches(params));
        const entry = state.filtered.find(matches(params));

        // console.log("where entry", entry);

        if (!entry) {
          return [];
        }

        const ids = entry.matchedIds;
        // console.log("ids", ids);
        return ids.map((id) =>
          state.records.find((record) => record.id === id)
        );
      },

      //- @todo: a function that doesn't require a special request to fullfill basic local filtering
      whereAlt: (state) => (params) => {
        // const entry = state.records.find(matchesAlt(params.filter));
      },
      /**
       * Given an association name, call this method on the association store.
       * E.g. Enquiry m2m Clients. To find the client of an enquiry, call this getter from the clients store,
       * and pass enquiry entity in as parent parameter.
       * This of it like this: `clients/related` to `parent defined by entity id and type`
       * It works for both sides of the relationship
       * @param {object} parent
       * @param {string} relationship
       * @param {boolean} debug if true then console logs - useful for limiting logging to specific cases
       * @return {array|object} many|single
       */
      related: (state, getters, rootState, rootGetters) => (params) => {

        const relationshipIndex = getRelationshipIndex(params);
        const { debug } = params;

        const related = state.related.find(matches(relationshipIndex));

        if (debug) {
          console.log('>>> restate-vuex.index.related: '.getters.storeName);
          console.log('params', params);
          console.log("relationshipIndex", relationshipIndex);
          console.log("state.related", state.related);
          console.log("related", related);
        }

        if (!related) {
          if (debug) {
            console.log("getRelated nothing related found");
          }
          return null;
        } else if (Array.isArray(related.relatedIds)) {
          const ids = related.relatedIds;
          if (debug) {
            console.log("getRelated related ids", ids);
            console.log("state", state);
          }

          const results = ids
            .map((id) =>
              state.records.find((record) => {
                if (debug) {
                  console.log("record", record);
                  console.log("record.id", record.id);
                  console.log("id", id);
                }
                return record.id === id;
              })
            )
            .filter((record) => record !== undefined);

          if (debug) {
            console.log("results", results);
          }
          return results;
        } else {
          const id = related.relatedIds;
          if (debug) {
            console.log("getRelated related id", id);
            console.log("state.records", state.records);
          }
          const record = state.records.find((record) => {
            return id === record.id;
          });
          if (debug) {
            console.log("record", record);
          }
          return record;
        }
      },
    },
  };
};

const mapResourceModules = ({ names, httpClient }) =>
  names.reduce(
    (acc, name) =>
      Object.assign({ [name]: resourceModule({ name, httpClient }) }, acc),
    {}
  );

export { getRelationshipType, mapResourceModules, resourceModule };
