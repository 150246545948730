//- @deprecated: using v-money directive instead
export const mnrmoney = (value, parseFormat = false) => {
  const amount = Number(value);
  const formatted = amount.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2
  });
  return formatted;
};
