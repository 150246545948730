export const SHARED_SET_LEFT_DRAWER_OPEN = "setLeftDrawerOpen";
export const SHARED_TOGGLE_LEFT_DRAWER_OPEN = "toggleLeftDrawerOpen";


export const QUEUE_CLEAR_MESSAGES = "clearMessages";
export const QUEUE_POP_MESSAGE = "popMessage";
export const QUEUE_ADD_MESSAGE = "addMessage";
export const QUEUE_ADD_MESSAGES = "addMessages";
export const QUEUE_REMOVE_MESSAGE = "removeMessage";
export const QUEUE_UPDATE_MESSAGE = "updateMessage";

export const API_CONFIG = {
  serverCache: "RS",
  deviceCache: "LF"
};

export const SAVE_TYPE = {
  continue: 1,
  saveAndContinue: 2,
  saveAndReload: 3
};

export const ROLES = {
  dev: 1,
  admin: 2,
  notary: 3,
  auditor: 4,
  contact: 5,
};

export const INVOICE_TARGET = {
  principal: 1,
  admin: 2,
  rep: 3,
  org: 4,
  other: 5
};

export const ENQUIRY_WHO = {
  ind: 1,
  rep: 2,
  org: 3
};

export const ENQUIRY_TYPE = {
  other: 9,
  website: 8,
  walkin: 7,
  telephone: 6,
  repeat: 5,
  recommendation: 4,
  society: 3,
  regulator: 2,
  google: 1
};

export const ENTITY_TYPE = {
  individual: 1,
  company: 2,
  partnership: 3,
  charity: 4,
  trust: 5,
  other: 6
};

export const COUNTRY_RULES = {
  notRequired: 0,
  apostille: 1,
  certification: 2,
  consulate: 3,
  chamber: 4,
  varies: 5
};

export const LEGALISATION_AGENT = {
  internal: 1,
  external: 2,
  none: 3
}

export const DOC_FWD = {
  TBC: 0,
  NA: 1,
  toAddress: 2,
  toAgent: 3,
  toClient: 4
};
export const DOC_3RD = {
  courier: 1,
  firstclass: 2,
  rmsd: 3,
  dx: 4
};

export const FEE_FCO = {
  london: 1,
  standard: 2,
  miltonkeynes: 3,
  partial: 4,
  NA: 5
};
// @todo: delete this it's just for debugging
export const TARGET = {
  simple: 1,
  complex: 2,
  other: 3
};

export const LOCATION = {
  tbc: 1,
  notary: 2,
  clientHome: 3,
  clientBusiness: 4,
  remote: 5,
  other: 6,
};

export const paymentPortion = {
  full: 1,
  partial: 2
};

export const paymentType = {
  prior: 1,
  appointment: 2,
  oninvoice: 3
};

export const ADMIN_CONTACT = {
  client: 1,
  other: 2,
  admin: 3
};

export const NOTARIAL_FEE_PER = {
  country: 1,
  appointment: 2,
  document: 3
};

export const USER_CONFIG = {
  theme: 1,
  notaryProFeeAccountingCode: 2
}

