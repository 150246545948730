import state from "./state";
// NB: empty getters file called using wildcard will return object `{default:{}}` which causes errors as a function is expected
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

/**
 * @reststate false - Not reststate as there's no corresponding BE store.
 * @persist false - this is a read-only store, always available, no reason to persist it
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
