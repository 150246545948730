import { ucfirst } from "locutus/php/strings";
import { empty } from "locutus/php/var";
import _ from "lodash";
import * as types from "src/store/constants";
import { contactName } from "src/utils";
import pckj from "src/../package.json";

export function leftDrawerOpen(state) {
  return state.leftDrawerOpen;
}

export function debug(state) {
  return state.debug;
}

export function test(state) {
  return state.test;
}

export const actingAs = (state) => {
  return state.actingAs;
};

export const permissions = (state, getters) => {
  return getters.actingAs?.permissions;
};

export const notaries = (state, getters, rootState, rootGetters) => {
  return rootGetters["people/where"]({
    filter: { role_id: [types.ROLES.admin, types.ROLES.notary] },
  });
};

export const version = () => {
  return pckj.version;
};

export const usersetting = (state, getters, rootState, rootGetters) => (name) => {
  return getters.actingAs.usersettings.find(x => x.name == name)?.value || null
}

// fetches usersettings from the store that are related to a given user. This is not to do with submissions
export const usersettings = (state, getters, rootState, rootGetters) => {

  // console.log('getters.actingAs',getters.actingAs );
  // console.log('getters.actingAs.userId', getters.actingAs.userId);

  if (!getters.actingAs.userId) return null;
  // console.log('getters.actingAs.userId', getters.actingAs.userId);

  const related = rootGetters["usersettings/related"]({
    parent: {
      id: getters.actingAs.userId,
      type: "people",
    },
    relationship: "usersettings",
  });

  return related;
};



/**
 * @returns (Boolean) true if url is pointed at root.
 */
export const isRootURL = (state, getters, rootState, rootGetters) => {
  return document.location.href.split("/").length <= 3;
};

/**
 * @returns (Boolean) true if url is pointed at root.
 */
export const rootURL = (state, getters, rootState, rootGetters) => {
  const split = document.location.href.split("/");
  return split[0] + "//" + split[2];
};

export const hasPermission =
  (state, getters) =>
  (val, debug = false) => {
    let found;
    if (Array.isArray(val)) {
      found = _.intersection(getters.permissions, val).length > 0;
    } else {
      found = getters.permissions?.includes(val);
    }
    if (debug) {
      console.log("val", val);
      console.log("found", found);
    }
    return found;
  };

export const roleId = (state, getters) => {
  return getters.actingAs?.roleId;
};

export const roles = (state, getters, rootState, rootGetters) => {
  return rootGetters["roles/all"];
};

export const getLabel = (state) => (val, optionsmodel) => {
  if (empty(val)) return null;
  let result = state.options[optionsmodel].filter((x) => x.value === val)[0];
  return result.label;
};

/**
 * Given a notary id or record and a bankdetails property name, return the value
 * @param {object} state
 * @param {object} getters
 * @param {object} rootState
 * @param {object} rootGetters
 * @param {object|string} notary
 * @param {string} property
 */
export const notaryBankDetails =
  (state, getters, rootState, rootGetters) => (notary, property) => {
    if (Object(notary) !== notary) {
      notary = rootGetters["people/byId"](notary);
    }
    const bankDetails = rootGetters["bankdetails/related"]({
      parent: notary,
      relationship: "bankdetail",
    });
    if (empty(bankDetails)) return "N/A";
    return bankDetails.attributes[property];
  };

export const notaryName = (state, getters, rootState, rootGetters) => (id) => {
  const notary = rootGetters["people/byId"]({ id });
  return contactName(notary.attributes);
};

export const isNotary = (state, getters) => {
  return getters.actingAs.roleId == types.ROLES.admin || getters.actingAs.roleId == types.ROLES.notary ? true : false;
};

/**
 * Given a set of legalisation steps for a country
 * @param {object} state
 * @returns {boolean} includes FCO country rule steps
 */
export const isFCO = (state) => (steps) => {
  if (empty(steps)) {
    return false;
  }
  const isit = Boolean(
    steps.includes(types.COUNTRY_RULES.apostille) ||
      steps.includes(types.COUNTRY_RULES.certification)
  );
  return isit;
};

export const legalisationNotRequired = (state) => (steps) => {
  if (empty(steps)) {
    return false;
  }
  const isit = Boolean(steps.includes(types.COUNTRY_RULES.notRequired));
  return isit;
};

export const legalisationRequired = (state, getters) => (steps) => {
  const isit = !getters.legalisationNotRequired(steps);
  return isit;
};

export const showLegalisationAgentFwdOptions =
  (state) => (document_fwd_instructions) => {
    if (empty(document_fwd_instructions)) {
      return false;
    }
    const isit = Boolean(document_fwd_instructions == types.DOC_FWD.toAgent);
    return isit;
  };

export const showMethodOfDelivery = (state) => (document_fwd_instructions) => {
  if (empty(document_fwd_instructions)) {
    return false;
  }
  // return document_fwd_instructions !== 1 && document_fwd_instructions !== 5;
  const isit = Boolean(
    ![types.DOC_FWD.NA, types.DOC_FWD.toClient].includes(
      document_fwd_instructions
    )
  );
  return isit;
};

export const getAncillaryOptions = (state, getters, rootState, rootGetters) => {
  const list = [];
  _.each(rootGetters["fees/feeLabels"], (label, key) => {
    list.push({
      label,
      value: key,
    });
  });
  return list;
};
/**
 * @param {Object} state
 * @param {String} filterKey
 * @param {String} optionValue
 */
export const getOptionLabel =
  (state, getters, rootState) => (filterKey, optionValue) => {
    if (empty(optionValue)) return null;
    const result = rootState.shared.options[filterKey].filter((option) => {
      return option.value === optionValue;
    });
    if (!result || !result[0]) return "--";
    return result[0].label || "--";
  };

export const evidenceTypes = (state) => {
  return state.options.evidence_type;
};

export const notarialStep = (state) => {
  return state.notarial.currentStep;
};

export const notarialSteps = (state) => {
  return state.notarial.steps;
};

export const notarialStepName = (state, getters) => (step) => {
  return Object.keys(getters.notarialSteps)[
    Object.values(getters.notarialSteps).indexOf(step)
  ];
};

export const currentNotarialStepIsDirtyFnName = (state, getters) => {
  const fnName = `isStepDirty${ucfirst(
    getters.notarialStepName(getters.notarialStep)
  )}`;

  // console.log("fnName", fnName);
  return fnName;
};

export const sharedOptions = (state) => {
  return state.options;
};

export const stepName = (state) => (stepId) => {
  return Object.keys(state.notarial.steps).find(
    (key) => state.notarial.steps[key] === stepId
  );
};
