import Vue from "vue";

import _ from "lodash";
import { updateField } from "src/utils/vuex-map-fields"; // import it

import { defaultFormStateFactory } from "./state";

export {
  updateField,
} from "src/utils/vuex-map-fields"; // export it to make it available locally

// @deprecated: I don't think this is in use
export function setEnquiryId(state, val) {
  state.enquiry_id = val;
}

/**
 * This is an override of vuex-map-fields default mutation.
 * The purpose is to store the value of q-select option only, rather than the object containing both label and value
 * This is useful for BE persistence
 * @param {object} state
 * @param {object} _ref3
 */
export function updateOptionField(state, _ref3) {
  // console.log("updateOptionField", _ref3);
  var path = _ref3.path,
    value = _ref3.value;
  path.split(/[.[\]]+/).reduce(function (prev, key, index, array) {
    if (array.length === index + 1) {
      // eslint-disable-next-line no-param-reassign
      const val =
        //- check for the q-select signature
        _.isObject(value) && Object.keys(value).includes("value")
          ? value.value
          : value;
      prev[key] = val;
    }

    return prev[key];
  }, state);
}

export function updateDateField(state, val) {
  return updateField(state, { path: val.path, value: val.value });
  // return updateField(state, { path: val.path, value: sqlDate(val.value) });
}

export function updateDateTimeField(state, val) {
  return updateField(state, { path: val.path, value: val.value });
  // return updateField(state, { path: val.path, value: sqlDateTime(val.value) });
}

export function updateArrayField(state, val) {
  return updateField(state, { path: val.path, value: val.value });
  // return updateField(state, { path: val.path, value: sqlDateTime(val.value) });
}

export function flagInserted(state, path) {
  return updateField(state, { path: `${path}.insert`, value: false });
}

/**
 * @deprecated: replaced by an action which can handle node paths of arbitrary depth
 * Intended for use with vuex-map-fields mapMultiRowFields
 * @param {object} state
 * @param {object} params should contain {storeName: String, node: String, data: Object}
 */
// export function addCountryRow(state, params) {
//   // console.log("addCountryRow state", state);
//   // console.log("addCountryRow params", params);
//   //- don't add duplicate rows
//   const exists = state[params.storeName][params.node].filter(
//     item => item.country_code === params.data.country_code
//   );
//   if (exists.length) return;
//   state[params.storeName][params.node].push(params.data);
// }

/**
 * Intended for use with vuex-map-fields mapMultiRowFields
 * @deprecated: not in use and there's an action fort his now.
 * @param {object} state
 * @param {object} _ref3
 */
// export function removeCountryRow(state, _ref3) {
//   let pathSplit = _ref3.path.split(".");
//   let filterKey = pathSplit.pop();
//   let parentNode = pathSplit.join(".");
//   const path = _ref3.path,
//     value = _ref3.value;
//   const arr = path.split(/[.[\]]+/).reduce(function(prev, key, index, array) {
//     if (array.length > index + 1) {
//       // eslint-disable-next-line no-param-reassign
//       return prev[key];
//     }
//     return prev;
//   }, state);

//   let filteredArray = arr.filter(x => x[filterKey] !== value);
//   updateField(state, { path: parentNode, value: filteredArray });
// }

// @deprecated: use updateField
// export function setForm(state, options) {
//   const data = _.cloneDeep(options.data);
//   // console.log("setForm options", options);
//   // console.log("setForm data", data);
//   //- clone in order to avoide assign by reference (or else emptyState will be overwritten with actual state on subsequent calls to this mutation)
//   state[options.storeName] = data;
// }
// export { updateField } from "src/utils/vuex-map-fields/index";

export function addDynamicFeeRow(state, row) {
  const items = _.get(
    state,
    `forms.${state.path}.data.attributes.fees[${row.feeIdx}].${row.nodeName}`,
    []
  );

  row.idx = items.length;

  items.push(row);
}
export function removeDynamicFeeRows(state, options) {
  //- @why: don't try to remove them if they don't exist
  const path = `forms.${state.path}.data.attributes.fees[${options.feeIdx}].${options.nodeName}`;
  const current = _.get(state, path, []);
  if(_.isEmpty(current)) return;
  _.set(
    state,
    `forms.${state.path}.data.attributes.fees[${options.feeIdx}].${options.nodeName}`,
    []
  );
}
export function setDefaultFeesDirectly(state, options) {
  _.set(
    state,
    `forms.${state.path}.data.attributes.fees[${options.countryIdx}].defaultfees`,
    options.defaultfees
  );
}

export function removeDynamicFeeRow(state, options) {
  const items =
    state.forms[state.path]?.data.attributes.fees[options.feeIdx][
      options.nodeName
    ] || [];
  var idx = options.id
    ? items.findIndex((x) => x.id === options.id)
    : options.eidx;
  items.splice(idx, 1);
}
export function removeTierDynamicFeeRows(state, options) {
  const items =
    state.forms[state.path].data.attributes.fees[options.feeIdx][
      options.nodeName
    ] || [];
  const indicesToRemove = [];
  items.forEach((item, index) => {
    if (item.tier === options.tier) {
      indicesToRemove.push(index);
    }
  });
  indicesToRemove.reverse().forEach((index) => {
    items.splice(index, 1);
  });
}

/**
 * This adds an actual empty related document record to the provided store
 * @param {object} state
 * @param {object} emptyRow
 */
export function addCountryDocRow(state, emptyRow) {
  state.forms.notarial.data.related.Documents.push(emptyRow);
}

export function setListLoading(state, bool) {
  state.isListLoading = bool;
}

/**
 * @deprecated doesn't work anymore because storeName node isn't a thing anymore
 * Use the removeCountryRow action instead
 */
// export function removeCountryDocRow(state, options) {
//   state[state.storeName].documents[options.countryIdx].countrydocs.splice(
//     options.docIdx,
//     1
//   );
// }

export function initialiseAppointmentDocuments(state, options) {
  const documentCollection = state.appointment.documents[options.countryIdx];
  if (typeof documentCollection.countrydocs === "undefined") {
  }
}

export function addComplianceRow(state, emptyRow) {
  state.forms.notarial.data.related.Compliance.push(emptyRow);
}

/**
 * Merges the attributes node from one object onto another object
 * and stores the result to vuex store defined by path.
 * NB: because the recipient object is cloned, its properties other than
 * `attributes` will be preserved (e.g. `id`)
 * @usecases
 * 1: If the schema changes, columns might be missing from
 * the stored data, thus merge the attributes into the empty schema,
 * rather than overwriting the empty schema.
 * 2: Copying enquiry attributes onto a notarial record
 * @param {object} options
 *  - @param {object} source @reststate record
 *  - @param {string} path state node to write the merge to
 *  - @param {object} recipient the data structure to merge into
 */
export const entityAttributesToForm = (state, options) => {
  // console.log("entityAttributesToForm mutation options", options);
  const { source, path, recipient } = options;
  if (source === undefined) {
    // console.log("source", source);
    throw "Missing required `source` parameter for entityAttributesToForm mutation";
  }
  if (path === undefined) {
    // console.log("path", path);
    throw "Missing required `path` parameter for entityAttributesToForm mutation";
  }
  if (recipient === undefined) {
    // console.log("recipient", recipient);
    throw "Missing required `recipient` parameter for entityAttributesToForm mutation";
  }
  const obj = _.clone(recipient),
    attributes = _.clone(source.attributes),
    value = _.merge(obj, { attributes });

  updateField(state, { path, value });
};

export const updateFormStructure = (state, params) => {
  const { path, value } = params;
  updateField(state, { path, value });
};
export const updatePrimeEntityName = (state, params) => {
  const { path, value } = params;
  updateField(state, { path, value });
};

export const resetAllForms = (state) => {
  state.forms = defaultFormStateFactory();
};

export const setShowValidationBanner = (state, val) => {
  state.showValidationBanner = val;
};

export const setStepperStep = (state, step) => {
  state.stepper.step = step;
};

export const setRequiredCountries = (state, val) => {
  Vue.set(state.forms.notarial.data.attributes, "requiredCountries", val);
};

export const setShowSearch = (state, val) => {
  state.showSearch = val;
};

export const setDisableSearch = (state, val) => {
  state.disableSearch = val;
};

export const enableSearch = (state) => {
  state.disableSearch = false;
};

export const disableSearch = (state) => {
  state.disableSearch = true;
};

export const toggleShowSearch = (state) => {
  state.showSearch = !state.showSearch;
};
