import {
  ago,
  date,
  dateTime,
  lowerFirst,
  mnrdate,
  mnrdatetime,
  mnrtime,
  mnrmoney,
  setFormat,
  setDateTimeFormat,
  sentenceCase,
  titlecase
} from "src/filters";

/**
 * This uses moment, which is 30kb in size. Ideally try do without it
 * Note that browser implementation of date string parsing is not standardised.
 * Use `parseFormat` argument to specify the input format your dates will have
 * https://vuejs.org/v2/guide/migration.html#Filter-Argument-Syntax-changed
 * this will fix it for all browsers
 * @todo: try send and receive ISO date strings to and from the back-end, this would be ideal.
 */

export default async ({ Vue, app }) => {
  Vue.filter("titlecase", titlecase);
  Vue.filter("sentenceCase", sentenceCase);
  Vue.filter("lowerFirst", lowerFirst);
  Vue.filter("ago", ago);
  Vue.filter("mnrtime", mnrtime);
  Vue.filter("mnrdate", mnrdate);
  Vue.filter("mnrdatetime", mnrdatetime);
  Vue.filter("mnrmoney", mnrmoney);
  setFormat(app.i18n.messages[app.i18n.locale].dateFormat);
  Vue.filter("date", date);

  setDateTimeFormat(app.i18n.messages[app.i18n.locale].dateTimeFormat);
  Vue.filter("dateTime", dateTime);
};
