import to from "await-to-js";
import { empty } from "locutus/php/var";
import { MessageProcessError, OfflineError, QueueError } from "src/exceptions";
import { hash } from "src/utils";

/*=======================================
QUEUE UTILITIES
======================================*/
/**
 * @param {object} message
 * @returns {string} hashed message
 */
export const createMessageHash = (message) => {
  delete message.created;
  const hashee = _.cloneDeep(message);
  if (hashee.action === "create") {
    /**
     * When creating, the id should be excluded, otherwise the message
     * will always seem unique (since the id is always unique). In order
     * to test for unnecessary re-submission of POST messages.
     *  */
    delete hashee.data.id;
  }
  // console.log("hash hashee", hashee);
  const newHash = hash(hashee);
  // console.log("hash hash", newHash);
  return newHash;
};

/**
 * @deprecated: overly complex
 * Checks message for existing hash. If not present, adds provided hash to message.
 * @param {object} message
 * @param {string} hash
 * @returns {object}
 */
export const addHashIfMissing = function (message, hash) {
  if (empty(message.hash) && !empty(this.messages)) {
    const exists = this.messages.filter((q) => q.hash === hash);
    // console.log("hash exists: ", exists);
    if (exists.length) {
      // console.log("has already exists");
      return false;
    }
  }
  if (empty(message.hash)) {
    // console.log("hash not present; added");
    message.hash = hash;
  }
  // console.log("hashed message", message);
  return message;
};

/*=======================================
QUEUE PROCESS
======================================*/
/**
 * Serialises a message object message queue
 * for later synching with the server; via vuex.
 * LF updates automatically via vuex plugin
 * @param {object} message
 */
export const qMessage = function (message) {
  if (empty(message)) throw Error("Cannot queue an empty message");
  // console.log("qMessage message initial", message);
  this.setBusy();
  const created = new Date();
  // console.log("-----------------HASH---------------");
  const hash = createMessageHash(message);

  if (this.hashExists(hash)) {
    // console.log("hash exists so not adding message");
    //- if this hash already exists, don't add the message as it's already present
    return;
  }
  const msg = _.cloneDeep(message);
  // const msg = JSON.parse(JSON.stringify(message));
  msg.hash = hash;
  msg.created = created.getTime();
  // console.log("qMessage message pre-final", message);
  // console.log("qMessage message final", msg);
  this.addMessage(msg);
  this.unsetBusy();
};

/**
 * Proceses all messages in the queue
 */
export const qProcess = async function () {
  //- don't process if offline
  if (!this.isOnline) {
    console.debug(this.messages)
    throw new OfflineError(
      "QUEUE: server is offline; messages will be queued for later synchronisation",
      "qProcess"
    );
  }
  if (this.isBusy) {
    throw new QueueError("The queue is busy processing another message");
  }
  this.setBusy();
  let messages = this.messages;
  if (process.env.DEBUG_QUEUE) {
    // console.log("qProcess messages", messages);
  }
  if (!messages.length) {
    this.unsetBusy();
    throw new QueueError("No messages to process");
  }
  let responses = [];
  // let responseHandler = async promise => {
  //   try {
  //     const result = await promise;
  //     return result;
  //   } catch (error) {
  //     return error;
  //   }
  // };
  /**
   * This while loop is based on message queue length, once the queue is empty, length will be zero and the loop will end.
   * If you try on messages instead (without length) it will loop infinitely because it's still an array: while(array){}
   */
  while (this.messages.length) {
    //- stop processing if offline
    if (!this.isOnline) {
      throw new OfflineError(
        "Messages will be queued for later synchronisation",
        "qProcess"
      );
    }
    let message = this.messages[0];
    this.splice(0);

    //   // console.log("qProcess messages", messages);
    // console.log("qProcess message", message);
    responses.push(await this.mProcess(message));
    // console.log(
    //   "ExecutionChain qProcess received result from mProcess",
    //   responses
    // );
  }
  this.unsetBusy();
  return responses;
};

/**
 * Processes a single message
 * @param {object} message
 * @returns {object} response
 */
export const mProcess = async function (message) {
  // console.log("mProcess message", message);
  const api = this.api(this);
  let [err, res] = await to(api.RS.send(message));
  // console.log("mProcess err", err);
  // console.log("mProcess res", res);
  if (err) {
    throw new MessageProcessError(err);
  }
  if (res) {
    // console.log("Execution Chain mProcess returning to qProcess", res);
    return res;
  }
};

/**
 * If the message was successfully processed, remove it from the queue vuex store
 * @param {object} message
 */
export const qSuccess = (message) => {
  removeMessage(message.hash);
};

/*=======================================
DEFAULT EXPORT
======================================*/
export default function (store) {
  return {
    get isOnline() {
      return store.getters["pings/isFlaggedOnline"];
    },
    set isOnline(val) {
      store.commit("pings/setServerOnline", val);
    },
    qMessage,
    qProcess,
    qSuccess,
    mProcess,
    isBusy: store.getters["queue/isBusy"],
    messages: store.getters["queue/getMessages"],

    splice(idx) {
      return store.commit("queue/splice", idx);
    },
    setBusy() {
      store.commit["queue/setBusy"];
    },
    unsetBusy() {
      store.commit["queue/unsetBusy"];
    },
    toggleBusy() {
      store.commit["queue/toggleBusy"];
    },
    removeMessage(hash) {
      store.commit("queue/removeMessage", hash);
    },
    addMessage(message) {
      store.dispatch("queue/addMessage", message);
      if (process.env.DEBUG_QUEUE) {
        // console.log("qMessage addMessage final store", store);
      }
    },
    hashExists: store.getters["queue/hashExists"],
    createMessageHash,
  };
}
