import { to } from "await-to-js";
import _ from "lodash";
import { axios } from "src/boot/axios";
import { PingError } from "src/exceptions";
import { notify, delay } from "src/utils";


export default {

  /**
   * Pings the server, mutates store flags
   * @returns {boolean}
   */
  async ping(context) {
    // console.log("=====< START PING ACTION >=====");
    // console.log("setting isLoaded to false");
    
    context.commit("setIsLoaded", false);
    await delay(1000);
    let [failed, resolved] = await to(axios.json.get("/api/ping.json"));
    if (resolved) {
      let statusCode = _.get(resolved, "status", 502);
      // console.log("status", statusCode);
      context.commit('setPingLastStatusCode', statusCode);
      if (resolved.data.ping === undefined) {
        throw new PingError(
          "Malformed ping response, server is present but broken",
          "PING",
          context
        );
      }
      const response = { data: resolved, status: statusCode, success: true };
      // console.log("response", response);
      return response;
    }
    if (failed) {
      // console.log("ping failed", failed);
      // console.log("ping failed typeof", typeof failed);
      console.warn("ping failed properties", {...failed});
      // console.log("ping failed", failed.getMessage());
      let statusCode = _.get(failed, "response.status", 504);
      // console.log("status", statusCode);
      context.commit('setPingLastStatusCode', statusCode);
      throw new PingError(failed, "PING", context);
    }
  },

  async loggedIn(context) {
    // return await axios.json.get("/api/people/login");
    let [failed, resolved] = await to(axios.json.get("/api/people/login"));
    let outcome;
    if (resolved) {

      //- check if server response indicates invalid login
      if(resolved && resolved.data && resolved.data.login.valid === false) {
        outcome = false
      } else {
        context.commit("shared/setActingAs", resolved.data.user, { root: true });
        outcome = resolved;
      }
    }
    if (failed) {
      if (failed instanceof Error) {
        notify("Error (#1): " + failed.message, "negative");
        if (context.getters[("shared/isRootURL", { root: true })]) {
          window.location.href =
            context.getters[("shared/rootURL", { root: true })];
        }
      }
      outcome = false
    }
    context.commit("isLoggedIn", outcome);
    return outcome
  },

  async isLoggedIn(context) {
    const res = await context.dispatch("loggedIn");
    // console.log(res);
    if (res && res.status == 200) {
      if (res.data.login.valid == true) {
        return res.data;
      }
    }
    return false;
  },

  async verifyToken(context, options) {
    let { token } = options;
    let [failed, resolved] = await to(
      axios.json.post("/api/people/verify", { token })
    );
    if (resolved) {
      return resolved;
    }
    if (failed) {
      console.debug("login failed", failed);
    }
    return null;
  },


  async checkPingExpired (context) {
    const time = Date.now()
    // await context.commit('setServerOnline', false);
    // await context.commit('setIsLoaded', false);
    // await context.commit('setPingExpired', true);
    // console.log(time)
    // return
    const timeLastPinged = context.getters.timeLastPinged
    const pingExpiredThreshold = context.getters.pingExpiredThreshold
    // console.log("timeLastPinged: ",timeLastPinged)
    // console.log("pingExpiredThreshold: ",pingExpiredThreshold)
    

    const timediff = time - timeLastPinged;
    const expired = timediff > pingExpiredThreshold;
    // console.log('expired', expired)
    await context.commit('setPingExpired', expired)
    
  }
};
