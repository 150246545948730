export const all = (state) => {
  return state.accountingCodes || [];
};

export const options = (state, getters) => {
  const accountingCodeOptions = [];

  return getters.all.map((element) => {
    return {
      value: element.Code,
      label: element.Code + " - " + element.Name,
    };
  });
};

export const isFiltered = (state, getters) => {
  return Boolean(getters.all.filter(x => x.Class == "EXPENSE").length == 0)
}
