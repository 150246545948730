import _ from "lodash";
import Vue from "vue";

const serialise = message => {
  const hash = sha256(JSON.stringify(message));
  const data = {
    message: JSON.parse(JSON.stringify(message)),
    created: new Date().getTime()
  };
  return { hash, data };
};

export const prepareMessage = (state, message) => {
  const hash = sha256(JSON.stringify(message));
  const data = {
    message: JSON.parse(JSON.stringify(message)),
    created: new Date().getTime(),
    hash: hash
  };
  /* check if hash exists already indexedDB @deprecated: hash is unique, so no need and `add` checks this anyway */
  // const hashFoundIndexedDB =
  // (await lf.get("synch_messages", hash)) || false;
  return { data, hash };
};

export const shift = state => {
  state.records.shift();
};
export const splice = (state, idx) => {
  state.records.splice(idx, 1);
};
export const removeMessage = (state, hash) => {
  let index = state.records.findIndex(message => message.hash == hash);
  state.records.splice(index, 1);
};
export const addMessage = (state, message) => {
  state.records.push(message);
};

export const updateMessage = (state, message) => {
  const messageIdx = state.records.findIndex(m => m.hash === message.hash);
  if (messageIdx >= 0) Vue.set(state.records, messageIdx, message);
};

export const unsetBusy = state => {
  state.busy = false;
};
export const setBusy = state => {
  state.busy = true;
};
export const toggleBusy = state => {
  state.busy = !state.busy;
};
