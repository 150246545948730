import _ from "lodash";
import { uid } from "quasar";
import { sqlDateTime } from "src/utils";
import { json } from "src/boot/axios";

/**
 * Async action which will fetch an entity from ID
 * or if an entity object was supplied, just return it
 * @param {object} context
 * @param {object} params
 * -- @param {string} storeName
 * -- @param {object|string} entityDammit
 * @return {object} reststate entity
 */
export async function getReststateEntity(context, params) {
  const { storeName, entityDammit } = params;
  let entity = {},
    id = "";

  if (!_.isObject(entityDammit)) {
    id = entityDammit;
  } else {
    if (typeof entityDammit.id === "undefined")
      throw Error("getRestateEntity received an object without an id property");
    id = entityDammit.id;
  }

  entity = context.rootGetters[`${storeName}/byId`]({ id: id });
  if (typeof entity !== "undefined") return entity;

  const loaderName = `${storeName}/loadById`;
  const result = await context.dispatch(
    loaderName,
    { id: entityDammit },
    { root: true }
  );
  entity = context.rootGetters[`${storeName}/byId`]({ id: entityDammit });
  return entity;
}

/**
 * Updates the default quantity for fees based on settings.
 * @param {object} context
 * @param {object} options index, country_code, value
 */
//- #6836.3
export function updateFeeQuantityDefault(context, options) {
  const feeSections = context.rootGetters["fees/feeSections"];
  // console.log("feeSections", feeSections);

  const { index, value } = options;
  const feeKeys = context.rootGetters["fees/getFeesPerDocument"];
  //- iterate over fee sections, if feeKey exists, then update it
  for (let section in feeSections) {
    _.each(feeKeys, (act, key) => {
      //- check if the field exists in this section
      const sectionPath = `${context.getters.formDataPath}.attributes.fees[${index}][${section}].${key}_quantity`;
      const exists = context.getters.getField(sectionPath);
      if (exists) {
        //- only act if the quantity hasn't already been set manually
        const manualPath = `${context.getters.formDataPath}.attributes.fees[${index}][].${key}_quantitySetManually`;
        //- and if this ancillary step has a `per document` fee
        const setManually = context.getters.getField(manualPath);
        if (act && !setManually) {
          const path = `${context.getters.formDataPath}.attributes.fees[${index}][${section}].${key}_quantity`;
          context.commit("updateField", {
            path,
            value: Number(value),
          });
        }
      }
    });
  }
}

/**
 * @deprecated for #509
 * This is used by AncillaryServices to ensure that `chamber` service is present when required by selected FCO
 * @param {object} context
 * @param {object} options
 */
// export function addChamberServiceIfMissing(context, options) {
//   let { stepIndex, newVal } = options;
//   if (stepIndex === undefined || newVal === undefined) {
//     throw "addChamberServiceIfMissing stepIndex option is missing";
//   }
//   let as;
//   if (
//     context.state.legalisationSteps === undefined ||
//     context.state.legalisationSteps[stepIndex] === undefined ||
//     context.state.legalisationSteps[stepIndex].ancillary_services === undefined
//   ) {
//     as = [];
//   } else {
//     as = _.clone(context.state.legalisationSteps[stepIndex].ancillary_services);
//   }
//   let AS = new Set(as);
//   if (newVal.includes(COUNTRY_RULES.chamber)) {
//     AS.add("chamber");
//   } else {
//     AS.delete("chamber");
//   }
//   if (!_.isEqual([...AS], as)) {
//     context.commit("updateField", {
//       path: `${context.getters.formDataPath}.attributes.legalisationSteps[${stepIndex}].ancillary_services`,
//       value: [...AS],
//     });
//   }
// }

export async function addComplianceRow(context) {
  // console.log('addComplianceRow');
  const emptyRow = await context.dispatch(
    "schemas/getEmptyRow",
    {
      schema: "compliance",
    },
    {
      root: true,
    }
  );
  context.commit("addComplianceRow", emptyRow);
}

/**
 * First gathers the emptyrow from documents before calling the mutation
 * @todo: dangerous because it sounds generic but actually this is hard-coded to the notarial submissions form
 */
export async function addCountryDocRow(context, countryCode) {
  const emptyRow = await context.dispatch(
    "schemas/getEmptyRow",
    {
      schema: "documents",
    },
    {
      root: true,
    }
  );
  // console.log('addCountryDocRow emptyRow', emptyRow);
  emptyRow.attributes.countryCode = countryCode;
  context.commit("addCountryDocRow", emptyRow);   // @todo: this is a problem as it is hardcoded to notarials
}

export async function addCountry(context, params) {
  let {code, name} = params;
  let data = context.rootGetters['countries/emptyJsonModel'](name).emptyRow();

  data.country_code = code;
  const path = `${context.getters.formDataPath}.attributes.${name}`;
  const options = {
    path,
    data,
    from: "addCountry",
  };
  // console.log('addCountry', addCountry );
  // console.log('data', data );
  // console.log('params', params );
  // console.log('name', name );
  // console.log('path', path );
  // console.log('options', options );
  await context.dispatch(
    "addCountryRow",
    options,
  );
}
export async function removeCountry(context, params) {
  console.log('submissions/removeCountry' );

  let {code, name} = params;
  const path = `${context.getters.formDataPath}.attributes.${name}`;
  const options =  {
    path,
    node: "country_code",
    from: "removeCountry",
    value: code,
  };
  await context.dispatch(
    "removeCountryRow",
    options,
  );
}

export function setDefaultAssociations(context, associations) {
  const options = {
    path: context.getters.formSettingsPath + '.defaultAssociations',
    value: associations
  };
  context.commit("updateField", options);
}

/**
 * Intended for use with vuex-map-fields mapMultiRowFields
 * @param {object} context
 * @param {object} params
 * - @param {string} path
 * - @param {object} data
 */
export function addCountryRow(context, params) {
  //- don't add duplicate rows
  const elements = _.cloneDeep(context.getters["getField"](params.path));
  const exists = elements.filter(
    (item) => item.country_code === params.data.country_code
  );
  if (exists.length) return;

  // add the row
  elements.push(params.data);
  const options = {
    path: params.path,
    value: elements,
  };
  // console.log('addCountryRow options', options );

  context.commit("updateField", options);
}

/**
 * Intended for use with vuex-map-fields mapMultiRowFields
 * @param {object} state
 * @param {object} params
 * - @param {string} params.path to the collection
 * - @param {string} params.node the property to match
 * - @param {any} params.value the value of the property to match
 */
export function removeCountryRow(context, params) {
  // console.log('removeCountryRow' );
  // console.log('removeCountryRow params', params);

  const elements = _.cloneDeep(context.getters["getField"](params.path));
  // console.log('elements', elements);

  let filteredArray = elements.filter((x) => x[params.node] !== params.value);
  // console.log('filteredArray',filteredArray );

  context.commit("updateField", { path: params.path, value: filteredArray });
}
/**
 * Similar to removeCountryRow but will ensure JSONAPI compliance by forcing single records to Object type rather than Array type
 */
export function removeInputStepperRow(context, params) {
  const elements = _.cloneDeep(context.getters["getField"](params.path));
  let filteredArray = elements.filter((x) => x[params.node] !== params.value);
  // console.log("filteredArray", filteredArray);
  if (filteredArray.length === 1) filteredArray = filteredArray[0];
  context.commit("updateField", { path: params.path, value: filteredArray });
}

export function removeComplianceRow(context, params) {
  // console.log('removeComplianceRow context', context);
  // console.log('removeComplianceRow params', params);
  const elements = _.cloneDeep(context.getters["getField"](params.path));
  let filteredArray = elements.filter((x) => x[params.node] !== params.value);
  context.commit("updateField", { path: params.path, value: filteredArray });
}

/**
 * Given an enquiry entity,
 * - generate related document empty records for each expectedDocument record
 * - store to a notarial submission
 * - assumes that the required data has already been loaded locally
 * @param {object} context
 * @param {object} options
 */
export async function convertEnquiryToNotarial(context, options) {
  const { source, recipient } = options;
  const entity = JSON.parse(JSON.stringify(source));

  //- set created date on entity
  entity.attributes.created = sqlDateTime(new Date());

  // first write the enquiry to the form, limiting copying of associations to only those relevant to notarial
  context.dispatch("entityToForm", {
    source: entity,
    path: `${context.getters.formDataPath}`,
    recipient,
    associations: context.rootGetters["schemas/associations"]("enquiries"),
    copyId: false,
  });

  // this will have deleted the Enquiries relationships, which needs to be re-established
  context.commit("updateField", {
    path: "forms.notarial.data.related.Enquiries",
    value: entity,
  });

  // reset the id, which will have been set to the enquiry id
  context.commit("updateField", {
    path: "forms.notarial.data.id",
    value: uid(),
  });

  // flag as a draft
  context.commit("updateField", {
    path: "forms.notarial.data.attributes.draft",
    value: true,
  });

  context.dispatch("setDocumentDefaultRelated");

  //- set the requiredCountries reactively
  context.commit("setRequiredCountries", entity.attributes.requiredCountries);
}

export function setDocumentDefaultRelated(context) {
  const signatories = context.getters.signatories;
  const organisations = context.getters.documentOrganisations;
  //- Get docs and store their associations
  const docs = context.getters.getEnquiryDocuments(
    "forms.notarial.data.related.Enquiries.attributes.expectedDocuments"
  );

  if (docs.length && signatories.length) {
    for (let [didx, doc] of docs.entries()) {
      for (let [oidx, organisation] of organisations.entries()) {
        docs[didx].related.Organisations.push(organisation);
      }
      for (let [sidx, signatoryContact] of signatories.entries()) {
        if (signatoryContact.contact_type === "clients") {
          docs[didx].related.Clients.push(signatoryContact);
        }
        if (signatoryContact.contact_type === "representatives") {
          docs[didx].related.Representatives.push(signatoryContact);
        }
      }
    }
  }

  if (!_.isEmpty(docs)) {
    context.commit("updateField", {
      path: "forms.notarial.data.related.Documents",
      value: docs,
    });
  }
}

/**
 * Adds in an emptyRow based on the source type.
 * @why because it needs to ensure the correct data structure.
 * e.g. if an actual reststate entity is passed as recipient,
 * then `related` will be missing.
 * @deprecated one doeesn't always want a fresh empty data structure
 * Then commits the mutations.
 *
 * Also don't merge @reststate nodes; just the facts, Ma'am.
 * @param {object} context
 * @param {object} options
 *  - @param {object} source @reststate record
 *  - @param {object} path state node to write the merge to
 */
// export const entityAttributesToForm = async (context, options) => {
//   // console.log("entityAttributesToForm action options bfore", options);
//   context.commit("entityAttributesToForm", options);
// };

/**
 * Stores relationships from a single assocatiation on a reststate entity to submission form store
 * @param {object} context
 * @param {object} options
 */
export const entityAssociatedRelationshipToForm = async (context, options) => {
  const { entity, path, association } = options;

  //- get the relationship records from restate source
  const related = _.cloneDeep(
    context.rootGetters["schemas/cakeRelated"](entity.type, {
      parent: entity,
      association,
    })
  );

  context.commit("updateField", {
    path,
    value: related,
  });
};

/**
 * Given a reststate entity, load its id, attributes and relationships
 * to a given form path
 * @param {object} context
 * @param {object} params
 * - @param {object} source entity to copy to form
 * - @param {array} associations list to process
 * - @param {string} path i.e. node in submissions store
 * - @param {object} recipient the data structure to merge into
 * - @param {boolean} copyId whether to copy the source id across
 * - @param {string} from for debugging purposes
 */
export const entityToForm = async (context, options) => {
  const {
    source,
    path = `${context.getters.formDataPath}`,
    recipient,
    copyId = true,
  } = options;
  if (source === undefined) {
    throw "Missing required `source` parameter for entityAttributesToForm action";
  }
  if (path === undefined) {
    console.debug("path", path);
    x;
    throw "Missing required `path` parameter for entityAttributesToForm action";
  }
  if (recipient === undefined) {
    console.debug("recipient", recipient);
    throw "Missing required `recipient` parameter for entityAttributesToForm action";
  }

  //- copy attributes to form
  context.commit("entityAttributesToForm", options);

  //- copy id accross if option is set, NB: this must occur after the commit to `entityAttributesToForm` or it will be overridden by that
  if (copyId) {
    context.commit(`updateField`, {
      path: `${context.getters.formDataPath}.id`,
      value: source.id,
    });
  }

  //- copy intersecting associations to form
  let { associations = [] } = options;
  if (_.isEmpty(associations)) {
    associations = context.rootGetters["schemas/associations"](source.type);
  }

  if (!_.isEmpty(associations)) {
    for (let association of associations) {
      const dispatchOptions = {
        entity: source,
        path: `${path}.related.${association.name}`,
        association,
      };

      await context.dispatch(
        "entityAssociatedRelationshipToForm",
        dispatchOptions
      );
    }
  }
};

export const loadRelatedAssociationsToDocumentsOnNotarials = async (
  context,
  options
) => {
  const { documents, path } = options;
  for (let [ridx, doc] of documents.entries()) {
    //- load Documents relationships
    // @todo: this should be going through the API to provide caching
    // @todo: perhaps there's a way to do this with "?include" query parameter to make all of this quicker, i.e. when loading the Documents – pass an inclusion list defined in the root component
    await Promise.all([
      // batches these requests to run concurrently
      context.dispatch(
        "clients/loadRelated",
        {
          parent: doc,
        },
        { root: true }
      ),
      context.dispatch(
        "representatives/loadRelated",
        {
          parent: doc,
        },
        { root: true }
      ),
      context.dispatch(
        "organisations/loadRelated",
        {
          parent: doc,
        },
        { root: true }
      ),
    ]);

    const docRelated = {
      Clients: context.rootGetters["clients/related"]({
        parent: doc,
      }),
      Representatives: context.rootGetters["representatives/related"]({
        parent: doc,
      }),
      Organisations: context.rootGetters["organisations/related"]({
        parent: doc,
      }),
    };
    doc.related = docRelated;
  }

  // write relationship value to mutation
  context.commit("updateField", {
    path,
    value: documents,
  });
};

/**
 * Generic action for post save of related data
 * @param {object} context standard vuex dependency injection
 * @param {object} options:=
 * - @param {object} parent: the parent entity for which relationships are being loaded
 * - @param {string} path: path within submissions state to write to
 * - @param {string} schema: name of the schema related to the parent entity from which loading
 * @return {void}
 */
export const loadRelated = async (context, options) => {
  const { parent, path, schema } = options;
  // console.log('submissions actions loadRelated options', options);
  const promise = await context.dispatch(
    `${schema}/loadRelated`,
    {
      parent,
      relationship: schema,
    },
    { root: true }
  );
  const items = context.rootGetters[`${schema}/related`]({
    parent,
    relationship: schema,
  });
  context.commit("updateField", {
    path,
    value: items,
  });
  return promise;
};

/**
 * Fetches and updates relationships of authorisations assocatiation
 * on people reststate entity to submission form store
 * @param {object} context
 * @param {object} parent
 */
export const loadAuthorisationsRelatedToPeople = async (context, options) => {
  const { parent, path } = options;
  // console.log(context);

  return await loadRelated(context, {
    parent,
    path,
    schema: "authorisations",
  });
};

export const loadRelatedComplianceToNotarial = async (context, parent) => {
  return await loadRelated(context, {
    parent,
    path: "forms.notarial.data.related.Compliance",
    schema: "compliance",
  });
};

export const loadRelatedAssociationsToComplianceOnNotarials = async (
  context,
  options
) => {
  const { related, path } = options;
  for (let [ridx, compliance] of related.entries()) {
    //- load Documents relationships
    // @todo: this should be going through the API to provide caching
    // @todo: perhaps there's a way to do this with "?include" query parameter to make all of this quicker, i.e. when loading the Documents – pass an inclusion list defined in the root component
    await Promise.all([
      // batches these requests to run concurrently
      context.dispatch(
        "authorities/loadRelated",
        {
          parent: compliance,
        },
        { root: true }
      ),
      context.dispatch(
        "capacities/loadRelated",
        {
          parent: compliance,
        },
        { root: true }
      ),
      context.dispatch(
        "notarials/loadRelated",
        {
          parent: compliance,
        },
        { root: true }
      ),
      context.dispatch(
        "people/loadRelated",
        {
          parent: compliance,
        },
        { root: true }
      ),
    ]);

    const complianceRelated = {
      Authorities: context.rootGetters["authorities/related"]({
        parent: compliance,
      }),
      Capacities: context.rootGetters["capacities/related"]({
        parent: compliance,
      }),
      Notarials: context.rootGetters["notarials/related"]({
        parent: compliance,
      }),
      People: context.rootGetters["people/related"]({
        parent: compliance,
      }),
    };
    compliance.related = complianceRelated;
  }

  // write relationship value to mutation
  context.commit("updateField", {
    path,
    value: related,
  });
};

export const setIsLoaded = (context, val) => {
  context.commit("updateField", {
    path: `${context.getters.formSettingsPath}.isLoaded`,
    value: val,
  });
};
export const setPrimeAssociations = (context, val) => {
  context.commit("updateField", {
    path: `${context.getters.formSettingsPath}.defaultAssociations`,
    value: val,
  });
};

/**
 * @returns Object
 */
export const routeReset = async (context, options) => {
  // console.log('routeReset' );
  // console.log('context', context );
  // console.log('options', options );

  context.commit("resetAllForms"); //- @why: NB: without this LoaderMixin doesn't work
  //- check that settings are loaded and load if not
  const settings = context.rootGetters["settings/all"];
  if (!settings.length) {
    await context.dispatch("settings/loadAll", {}, { root: true });
  }

  if (options.nodeName){
    context.commit("updatePrimeEntityName", {
      path: "path",
      value: options.nodeName,
    });
  }

  if (options.schemaName) {
    const emptyRow = await context.dispatch(
      "schemas/getEmptyRow",
      {
        schema: options.schemaName,
      },
      {
        root: true,
      }
    );
    if (emptyRow) {
      // console.log('emptyRow',emptyRow );

      context.commit("updateFormStructure", {
        path: `forms.${options.nodeName}.data`,
        value: emptyRow,
      });

      //- no path if emptyRow was successful
      return {};
    }
  }
  //- redirect to route if not
  return { path: "/" };
};

export const findLatestComplianceRecord = async (context, options) => {
  const { signatoryType, client, organisation, representative } = options;

  await context.dispatch("compliance/loadWhere", {});
};

export async function emptyComplianceRowFunc(context, person) {
  let emptyRow = await context.dispatch(
    "schemas/getEmptyRow",
    {
      schema: "compliance",
    },
    { root: true }
  );
  //- write the document and person id's to this record
  emptyRow.related.People = { id: person.id, type: person.type };
  emptyRow.related.Notarials = { id: context.getters.id, type: "notarials" };
  emptyRow.attributes.signatoryType = context.getters["complianceRole"](
    person.id
  );
  return emptyRow;
}

export async function allowPersonEmail(context, params) {
  let path = `api/people/allowEmail/${params.email}`;
  if(params.id) {
    path += `/${params.id}`;
  }
  console.debug(path)
  try {
    const result = await json.get(path);
    return result.data.result;
  } catch (error) {
    throw new Error(error);
  }
}

export async function allowOrganisationEmail(context, params) {
  let path = `api/organisations/allowEmail/${params.email}`;
  if(params.id) {
    path += `/${params.id}`;
  }
  console.debug(path)
  try {
    const result = await json.get(path);
    return result.data.result;
  } catch (error) {
    throw new Error(error);
  }
}