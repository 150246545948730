import { to } from "await-to-js";
import _ from "lodash";
import queue from "src/api/queue";
import { OfflineError, TransportError } from "src/exceptions";

function getApi(obj) {
  return obj.hasOwnProperty("isMnrApi") ? obj : obj.$API;
}

const validTransports = ["RS", "LF"];
const defaultTransport = "RS";

function isValidTransport(transport) {
  return validTransports.includes(transport);
}

/**
 * The reason this is a function, not an object, is so store can be passed in by the `boot/api` file
 * that maps this to the vm
 * @param {object} store
 * @return {object}
 */
export default function (store) {
  return {
    ...queue(store),
    /*************************
     *       PUBLIC
     *************************/
    isMnrApi: true, // used to prevent injection of other api
    initialise() {
      Interface.ensureImplements(this.$API.LF, interfaces.Transport);
      Interface.ensureImplements(this.$API.RS, interfaces.Transport);
    },

    /**
     * Allows routing of api calls to different XHR integrations
     * @param {string} transport RS: reststate, XERO: xero
     * @return {object} a registered API transport
     */
    transports(transport) {
      const transports = {
        RS: this.RS,
      };
      return transport ? transports[transport] : transports;
    },

    get isOnline() {
      return store.getters["pings/isFlaggedOnline"];
    },
    set isOnline(val) {
      store.commit("pings/setServerOnline", val);
    },

    /**
     * Alias to vuex ping action
     * @param {string} from
     */
    async ping(from = "unspecified") {
      let [err, response] = await to(store.dispatch("pings/ping", from));
      if (response) {
        return response;
      }
      if (err) {
        throw err;
      }
    },

    async fetchSchemas(from = "unspecified") {
      let [err, response] = await to(store.dispatch("schemas/fetch", from));
      if (response) {
        store.commit("pings/setServerOnline", true);
        store.commit("pings/setIsError", false);
        store.commit("pings/setLastError", "");
        store.commit("schemas/setIsError", false);
        store.commit("schemas/setLastError", "");
        return response;
      }
      if (err) {
        store.commit("pings/setServerOnline", false);
        store.commit("pings/setIsError", true);
        store.commit("schemas/setIsError", true);
        store.commit("schemas/setLastError", err);
        throw err;
      }
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    /**
     * @todo: ??? document this
     * @param {string} endPoint
     * @param {object} options
     */
    async reststate(endPoint, options) {
      const path = _.get(options, "actionPath", endPoint);
      const data = _.get(options, "data", {});
      const requestMethod = this.RS.getRequestMethod(path);
      return this.handleRequest(requestMethod, store.dispatch, [path, data]);
    },
    /**
     * This sleight of hand allows the function to search for the correct scope
     * there were instances in the codewhere `this` is actually the vm and others where it's already this object
     */
    api() {
      return this.hasOwnProperty("isMnrApi") ? this : this.$API;
    },
    /**
     * Syntactic sugar for specifying a get request
     * @param {string} transport
     * @param {string} endPoint
     */
    async GET(transport, endPoint) {
      if (arguments.length === 1) {
        endPoint = transport;
        transport = defaultTransport;
      }
      await this.api().wrap(transport, { endPoint });
    },

    /**
     * Convenience DRY function that wraps api persistence requests
     * with built-in notify and error handling.
     * Note that this obfuscates the error source;
     * as the handler is here it prevents output of error source to console.
     * Why these arguments? To make it a clean, consistent, easy to use.
     * @param {string} transport // the transport for the call
     * @param {object} options // options object to be passed to the transport when calling
     * - {*} pass
     * - {*} fail
     * - {boolean} debug // To enable error log to console
     * - {string} from // For debugging: stack trace one level back
     * @todo: consider changing the function name: better alternatives? `persist` (doesn't work for GET) || `request`
     */
    async wrap(transport = null, options = {}) {
      if (!isValidTransport(transport))
        throw new TransportError(
          "Invalid transport type: " +
            transport +
            ". Valid transports: " +
            validTransports.join(" | "),
          "wrap invalid transport"
        );
      const api = getApi(this);
      if (options.from) {
        console.debug("api.wrap called by: ", options.from);
      }
      let { pass = null, fail = null, debug = true } = options;
      if (options.config) {
        config = _.merge(config, options.config);
      }

      const callFunction = api._getCallFunction(api, options);
      if (process.env.DEBUG_TRACE) {
        console.trace();
      }
      if (process.env.DEBUG_API) {
        /**----------------------------------------------------------*/
        console.debug("+++++++++ API WRAP +++++++++");
        console.debug("| transport: ", transport);
        console.debug("| message", options);
        console.debug("| callFunction", callFunction.name);
        console.debug("++++++++++++++++++++++++++++");

        console.debug("ExecutionChain callFunction", callFunction);
        console.trace();
        //----------------------------------------------------------*/
      }

      let [err, resolved] = await to(
        callFunction.call(api, transport, options)
      );

      if (resolved) {
        let statusCode = _.get(resolved, "status", 502);
        return { data: resolved, status: statusCode, success: true };
      }
      if (err) {
        //- @todo, should do somethign more productive with any collected errors
        const requestMethod = api[transport].method(options);

        //- debug
        if (process.env.DEBUG_API) {
          console.debug("api", api);
          console.debug("transport", transport);
          console.debug("api transport", api[transport]);
          console.debug("API wrap error: ", {
            requestMethod,
            data: options,
            err: err,
            success: false,
          });
        }
        /**
         * If not online, the
         * n the online check will already throw an error when re-queued by `callFunction`
         * This check is for when the app thinks it is online but the request to server fails, at which
         * point flag as offline
         */
        if (api.isOnline && err.status === 502) {
          api.isOnline = false;
        }

        if (!api.isOnline) {
          /**
           * RE-queue any POST / PATCH / DELETE messages that failed to send
           * Will throw errors if offline
           * @todo: not sure why  doesn't cause infinite recursion when online
           */
          if (requestMethod !== "GET") {
            await callFunction.call(api, transport, options);
          }
        }
      }
    },

    /*************************
     *       PRIVATE
     *************************/
    /**
     * Wraps the call function for performance tuning.
     * @todo: perhaps poorly named; consider `_performanceTuning`
     * @param {object} api
     * @param {object} options
     * @returns {function}
     */
    _getCallFunction(api, options) {
      // if (options.debounce === false) debounceMs = 0;
      // if (options.throttle === false) throttleMs = 0;
      // if (options.delay === false) delayMs = 0;
      // func =
      //   debounceMs > 0
      //     ? _.debounce(api._handleRequest, debounceMs)
      //     : api._handleRequest;
      // func = throttleMs > 0 ? _.throttle(func, throttleMs) : func;
      // if (delayMs > 0) {
      //   func = async function() {
      //     await this.delay(delayMs);
      //     return func;
      //   };
      // }
      // console.log("func", func);
      // return func;
      return api._handleRequest;
    },
    /**
     * Standardizes request handling for offline / online
     * @param {string} transport RS || LF || <other>
     * @param {object} options as required by the transport
     */
    async _handleRequest(transport, options) {
      let online = this.isOnline;
      // if (process.env.DEBUG_API) {
        // console.debug("_handleRequest online", online);
        // console.debug("_handleRequest options", options);
      // }
      let requestMethod = this[transport].method(options);
      let response;
      const api = getApi(this);
      //- GET
      if (requestMethod === "GET") {
        if (online) {
          let [err, response] = await to(this[transport].send(options));
          if (response) {
            return response;
          }
          if (err) {
            throw new TransportError(err, "_handleRequest");
          }
        } else {
          // console.error("api/index.js::_handleRequest", options);
          throw new OfflineError(
            "Requested data could not be loaded. Please try again once online.",
            "API._handleRequest GET offline"
          );
        }
        return; // <- don't queue GET requests
      }
      //- PATCH, POST, DELETE
      response = api.qMessage(options);
      if (online) {
        return await this.qProcess();
      } else {
        throw new OfflineError(
          "Messages will be queued for later synchronisation",
          "API._handleRequest PATCH / POST / DELETE offline"
        );
      }
    },
  };
}
