import { axios } from "src/boot/axios";
import { notify } from "src/utils";

const postQuote = async (userId, entityId, type) => {
  return await handleRequest("post", "/xero/quote", {
    userId,
    entityId,
    type,
  });
};

const postInvoice = async (userId, entityId, type) => {
  return await handleRequest("post", "/xero/invoice", {
    userId,
    entityId,
    type,
  });
};

const accountCode = async (userId, Class) => {
  const queryString = Class ? `?Class=${Class}` : "";
  return await handleRequest("post", "/xero/accountCode" + queryString, {
    userId,
  });
};

const userLogin = async (email, password, device) => {
  return await handleRequest("post", "/api/people/login", {
    email,
    password,
    device,
  });
};

const clearAuth = async (userId) => {
  await axios.json.post("/xero/clearAuth", { userId });
};

const updateMapping = async (userId, formData) => {
  const params = {
    ...formData,
    userId,
  };
  return await handleRequest("post", "/xero/updateMapping", params);
};

const getAllPermissions = async () => {
  return await handleRequest("get", "/role/getAllPermissions");
};

const updateContactRole = async (roleId, personId) => {
  return await handleRequest("post", "/role/updateContactRole", {
    roleId,
    personId,
  });
};

const updateOrganisationRole = async (roleId, organisationId) => {
  return await handleRequest("post", "/role/updateOrganisationRole", {
    roleId,
    organisationId,
  });
};

const getPermissionList = async (roleId) => {
  return await handleRequest("get", `/role/getPermissionList?roleId=${roleId}`);
};

const updatePermission = async (roleId, permission) => {
  return await handleRequest("post", "/role/updatePermission", {
    roleId,
    permission,
  });
};

const insertPerson = async (userId) => {
  return await handleRequest("post", "/xero/insertPerson", { userId });
};

const registerUser = async (userData) => {
  return await handleRequest("post", "/api/people/register", userData);
};

const forgetPassword = async (email) => {
  return await handleRequest("post", "/api/people/forget_password", {
    email,
  });
};

const resetPassword = async (password, token) => {
  return await handleRequest("post", "/api/people/reset_password", {
    password,
    token,
  });
};

const logoutUser = async () => {
  return await handleRequest("post", "/api/people/logout");
};

const checkEmail = async (email) => {
  return await handleRequest("post", "/api/people/check", { email });
};

const getLoginUser = async (email) => {
  return await handleRequest("post", "/api/people/get_user");
};

const handleRequest = async (method, endPoint, data) => {
  console.log("xeroApiService endPoint", endPoint);

  try {
    if (typeof data == "undefined") {
      return await axios.json[method](endPoint);
    } else {
      return await axios.json[method](endPoint, data);
    }
  } catch (error) {
    console.log('XeroApiService.handleRequest catch block' );
    await handleError(error, endPoint);
    console.log('XeroApiService.handleRequest catch block post handleError about to return false' );

    return false;
  }
};

const getStatus = (err) => {
  return  err.status || err.response?.request?.status || err.response?.data?.data?.code;
}

const getMessage = (err) => {
  return (err.response?.data?.errors && err.response.data.errors.length > 0 ?
    err.response.data.errors.map(error => error.detail).join(', ') : '') ||
        err.response?.data?.data?.message ||
         err.response?.data?.error ||
         err.message;
}


const handleError = async (err, from) => {

  let message = getMessage(err);
  let actions = [];
  let status = getStatus(err);
  console.error('XeroApiService.handleError', status, message);
  console.log(err.toJSON());
  console.log(Object.keys(err));
  if (
    status === 403 &&
    message === "Request failed with status code 403"
  ) {
    message =
      "The Xero Server was not able to process this request. Please ensure that your Xeroaccount is authenticated.";
    actions.push({
      color: "white",
      label: "Goto Xero Account Page",
      handler: () => {
        window.location.href = '/xeroaccounts';
      },
    });
  }


//|| err.response?.data?.errors?.[0]?.status == 401
  notify(from + ": " + message, "negative", {actions});
  if (err.response?.status == 401 ) {
    alert('XeroApiService logout');
    console.log('this.$router',this.$router );

    this.$router.push("/user/logout");
    // await logoutUser();
    // console.log('about to redirect to login' );

    // this.$router.push("/user/login");

  }
  return false;
};

export default {
  accountCode,
  checkEmail,
  clearAuth,
  forgetPassword,
  getAllPermissions,
  getLoginUser,
  getPermissionList,
  insertPerson,
  logoutUser,
  postInvoice,
  postQuote,
  registerUser,
  resetPassword,
  updateContactRole,
  updateMapping,
  userLogin,
  updateOrganisationRole,
  updatePermission,
};
