export default {
  debug: false,
  test: false,
  leftDrawerOpen: false,
  online: {},
  actingAs: null,
  notarial: {
    currentStep: 1,
    steps: {
      appointment: 1,
      legalisation: 2,
      documents: 3,
      authority: 4,
      dataprotection: 5,
      terms: 6,
      fees: 7,
      checklist: 8
    }
  },
  options: {
    /**********************************
     * Clients
     **********************************/
    data_protection_stored: [
      {
        label: "On the Mobile Notary Register system",
        value: 1
      },
      {
        label: "Offline, hard copy",
        value: 2
      },
      {
        label: "Other",
        value: 3
      }
    ],
    entity_types: [
      {
        label: "Individual",
        value: 1
      },
      {
        label: "Company",
        value: 2
      },
      {
        label: "Partnership",
        value: 3
      },
      {
        label: "Charity",
        value: 4
      },
      {
        label: "Trust",
        value: 5
      },
      {
        label: "Other",
        value: 6
      }
    ],
    ind_entity_types: [
      {
        label: "Individual",
        value: 1
      }
    ],
    org_entity_types: [
      {
        label: "Company",
        value: 2
      },
      {
        label: "Partnership",
        value: 3
      },
      {
        label: "Charity",
        value: 4
      },
      {
        label: "Trust",
        value: 5
      },
      {
        label: "Other",
        value: 6
      }
    ],
    //@todo: this is not used?
    representative_capacity: [
      {
        label: "Attorney",
        value: 1
      },
      {
        label: "Executor",
        value: 2
      },
      {
        label: "Trustee",
        value: 3
      },
      {
        label: "Managing Partner",
        value: 4
      },
      {
        label: "Finance Director",
        value: 5
      }
    ],

    /**********************************
     * Enquiries
     **********************************/
    legalisation_agent: [
      {
        label: "Notary or Notary’s internal team",
        value: 1
      },
      {
        label: "An external legalisation agent",
        value: 2
      },
      {
        label: "Legalisation support not required – client has confirmed they will handle this themselves",
        value: 3
      }
      //- #6779 @deprecated: this is now being handled by the FCO fees selection
      // {
      //   /* §2.12.1.1 */
      //   label: "Client will manage the legalisation requirements personally",
      //   value: 3
      // },
      // {
      //   label: "Do not legalise",
      //   value: 4
      // }
    ],
    who: [
      {
        /* §2.1.3.1 */
        label: "Signatory/principal is signing personally / documents are for or relate to the signatory",
        value: 1
      },
      {
        /* §2.1.3.3 */
        label: "Signatory/principal is signing as representative on behalf of a person / documents are for someone else represented by principal",
        value: 2
      },
      {
        /* §2.1.3.2 */
        label: "Signatory is signing on behalf of an organisation / documents are for or relate to the organisation",
        value: 3
      }
    ],
    yesno: [
      {
        label: "Yes",
        value: 1
      },
      {
        label: "No",
        value: 0
      }
    ],
    timescales: [
      {
        label: "Same day",
        value: 1
      },
      {
        label: "2 days",
        value: 2
      },
      {
        label: "3 days",
        value: 3
      },
      {
        label: "4 days",
        value: 4
      },
      {
        label: "5 days",
        value: 5
      },
      {
        label: "6 days",
        value: 6
      },
      {
        label: "1 week",
        value: 7
      },
      {
        label: "2 weeks",
        value: 14
      },
      {
        label: "N/A",
        value: 15
      }
    ],
    document_fwd_delivery_type: [
      {
        label: "Courier",
        value: 1
      },
      {
        label: "First class",
        value: 2
      },
      {
        label: "RM Special delivery",
        value: 3
      },
      // {
      //   label: "Special delivery",
      //   value: 4
      // },
      {
        label: "DX",
        value: 4
      }
    ],
    adminContactType: [
      {
        label: "Enquirer is both: the signatory (or it's their docs) and is the admin contact for the enquiry",
        value: 1
      },
      {
        label: "Enquirer is the signatory (or it's their docs) but another person is the admin contact for the enquiry",
        value: 2
      },
      {
        label: "Enquirer is not the signatory (nor their docs) but is the admin contact for the enquiry",
        value: 3
      }
    ],
    feetypes: [
      {
        label: "Estimate",
        value: 1
      },
      {
        label: "Fixed",
        value: 2
      }
    ],
    locations: [
      {
        label: "Notary's Office",
        value: 1
      },
      {
        label: "Client home address",
        value: 2
      },
      {
        label: "Client business address",
        value: 3
      },
      {
        label: "Other address",
        value: 4
      },
      {
        label: "Remote - by Video Conference",
        value: 5
      }
    ],
    document_fwd_instructions: [
      {
        label: "Not applicable / document(s) handed back directly to client",
        value: 1
      },
      {
        label:
          "We will send the document(s) back to specified address (as below)",
        value: 2
      },
      {
        label: "We will send the document(s) to an external legalisation agent",
        value: 3
      },
      //- #6734
      {
        label: "We will personally deliver the documents back to you",
        value: 4
      },
      {
        label: "To be confirmed",
        value: 5
      }
    ],
    document_legalisation_agent_fwd: [
      {
        label: "We will send on",
        value: 1
      },
      {
        label: "They will send on",
        value: 2
      }
    ],
    other_expense_types: [
      {
        label: "Companies House",
        value: 1
      },
      {
        label: "IPO",
        value: 2
      },
      {
        label: "GRO",
        value: 3
      },
      {
        label: "Translation fees",
        value: 4
      },
      {
        label: "Other",
        value: 5
      }
    ],

   
    /**********************************
     * Notarials
     *********************************/
    notary_only: [
      {
        label: "Certification",
        value: 1
      },
      {
        label: "Declaration",
        value: 2
      },
      {
        label: "Affadavit",
        value: 3
      },
      {
        label: "Other",
        value: 4
      }
    ],
    id_type: [
      {
        label: "Passport",
        value: 1
      },
      {
        label: "Drivers licencse",
        value: 2
      }
    ],

    authorisation_client_type: [
      {
        label: "Individual",
        value: 1
      },
      {
        label: "Organisation",
        value: 3
      }
    ],
    evidence_type: [
      {
        label: "Passport",
        value: 1,
        types: ["id", "address"]
      },
      {
        label: "Driver's License",
        value: 2,
        types: ["id", "address"]
      },
      {
        label: "National ID Card",
        value: 3,
        types: ["id", "address"]
      },
      {
        label: "Utility Bill",
        value: 4,
        types: ["address"]
      },
      {
        label: "Bank Statement",
        value: 5,
        types: ["address"]
      },
      {
        label: "Council Tax Bill",
        value: 6,
        types: ["address"]
      },
      {
        label: "HMRC Tax Notiification",
        value: 7,
        types: ["address"]
      },
      {
        label: "UK Companies House",
        value: 8,
        types: ["authority", "address"]
      },
      {
        label: "Foreign Company Register",
        value: 9,
        types: ["authority"]
      },
      {
        label: "Power of Attorney",
        value: 10,
        types: ["authority"]
      },
      {
        label: "Other",
        value: 11,
        types: ["authority", "id", "address"]
      }
    ],

    // evidence_of_authority_type: [
    //   {
    //     label: "Affadavit",
    //     value: 1
    //   },
    //   {
    //     label: "Board Resolution",
    //     value: 2
    //   },
    //   {
    //     label: "Power of attorney",
    //     value: 3
    //   },
    //   {
    //     label: "Cert. copy ID",
    //     value: 4
    //   },
    //   {
    //     label: "Mortgage",
    //     value: 5
    //   }
    // ],
    notarial_activity: [
      {
        label: "Notarise only",
        value: 1
      },
      {
        label: "Arranging direct legalisation only",
        value: 2
      },
      {
        label: "Notarise & FCDO apostille",
        value: 3
      },
      {
        label: "Notarise & full legalisation",
        value: 4
      }
    ],
    signing_capacity: [
      {
        label: "Personally",
        value: 1
      },
      {
        label: "As representative / attorney / trustee",
        value: 2
      },
      {
        label: "Notary only signing / Not applicable",
        value: 3
      }
    ],
    provided: [
      {
        label: "Email",
        value: 1
      },
      {
        label: "Repeat client",
        value: 2
      },
      {
        label: "Provided previously",
        value: 3
      },
      {
        label: "My website",
        value: 4
      },
      {
        label: "Other",
        value: 5
      }
    ],
    communicated: [
      {
        label: "Email",
        value: 1
      },
      {
        label: "Text",
        value: 2
      },
      {
        label: "Phone",
        value: 3
      },
      {
        label: "At appointment",
        value: 4
      },
      {
        label: "Fee agreement in place with existing",
        value: 5
      },
      {
        label: "Other",
        value: 6
      }
    ],
    
    capacities: [
      {
        label: "Personally",
        value: 1
      },
      {
        label: "Officer",
        value: 2
      },
      {
        label: "Company Representative",
        value: 3
      },
      {
        label: "Attorney",
        value: 4
      },
      {
        label: "Trustee",
        value: 5
      },
      {
        label: "Partner",
        value: 6
      },
      {
        label: "Other",
        value: 7
      },
      {
        label: "N/A",
        value: 8
      }
    ],
    overriding_client_instructions: [
      { label: "Only notarise", value: 1 },
      { label: "Only apostille", value: 2 },
      { label: "No legalisation", value: 3 }
    ],
    id_type: [
      {
        label: "Passport",
        value: 1
      },
      {
        label: "Drivers licencse",
        value: 2
      }
    ],

    signing_capacity: [
      {
        label: "Personally",
        value: 1
      },
      {
        label: "As representative / attorney / trustee",
        value: 2
      },
      {
        label: "Notary only signing / Not applicable",
        value: 3
      }
    ]
  }
};
