import _ from "lodash";

//- True if network is enabled, available and the server is online, i.e. all good!
export function isFlaggedOnline(state, getters) {
  const isSo =
    getters.networkOnline === true &&
    getters.enabled === true &&
    getters.serverOnline === true;
  return isSo;
}
//- e.g. Wifi, Ethernet or Mobile
export function networkOnline(state) {
  return state.networkOnline;
}

//- a manual toggle for spoofing network disconnect
export function enabled(state) {
  return state.enabled;
}

//- ping status code
export function getLastPingStatusCode(state) {
  return state.lastPingStatusCode;
}

//- means last ping to server was a success
export function serverOnline(state) {
  return state.serverOnline;
}

//- means ping has been tried
export function isLoaded(state) {
  return state.isLoaded;
}
export function isLoggedIn(state) {
  return state.isLoggedIn;
}

//- ping error
export function isError(state) {
  return state.isError;
}

export function isSomeProblem(state) {
  const unresolved = 
    state.pingExpired
    || !state.isLoaded
    || !state.serverOnline
    || state.lastPingStatusCode != 200
    || !state.enabled
    || !state.networkOnline
    !! state.isError
    || !state.isFlaggedOnline
  return unresolved
}

export function timeLastPinged(state) {
  return state.timeLastPinged
}
//- we have a timeout on pings after which they must be re-issued
export function pingExpired(state) {
  return state.pingExpired
}
export function pingExpiredThreshold(state) {
  return state.pingExpiredThreshold
}


export { getField } from "src/utils/vuex-map-fields";
