import _ from "lodash";
export const shift = ({ getters, commit }) => {
  // console.log("store.getters", store.getters);
  // let messages = getters["getMessages"];
  // if (!messages.length) return [];
  // const message = _.clone(messages[0]);
  // commit("shift");
  // console.log("while message", message);
  // return message;
};

/**
 * NB: mutations return :void,
 * thus must first get a copy of the message,
 * then commit the splice
 */
export const splice = ({ commit, getters }, idx) => {
  let message = _.clone(getters.byIndex(idx));
  // console.log("processQ splice message", message);
  commit("splice", idx);
  return message;
};

export const addMessage = (context, message) => {
  if (!context.getters.hashExists(message.hash)) {
    context.commit("addMessage", message);
  }
};
export const addMessages = (context, messages) => {
  _.each(messages, message => {
    if (!context.getters.hashExists(message.hash)) {
      context.commit("addMessage", message);
    }
  });
};
