import { format } from "date-fns";

let dateTimeFormat;
export const setDateTimeFormat = (form) => {
  dateTimeFormat = form;
};

export const dateTime = (dateStr) => {
  const date = new Date(dateStr);
  return format(date, dateTimeFormat || "yyyy/M/d HH:mm");
};
