import _ from "lodash"
import { strip } from "src/utils"

export function createDynamicRowsForDefaultFees(context, params) {
  let { countryCode, index } = params;
  
  let defaultfeesToAdd = [];
  const existingDefaultFeees = context.rootGetters["submissions/getField"](
    `${context.rootGetters["submissions/formDataPath"]}.attributes.fees.${index}.defaultfees`
  );
  // console.log('createDynamicRowsForDefaultFees existingDefaultFeees', existingDefaultFeees);
  
  if (_.isEmpty(existingDefaultFeees)) {
    const ruleIds = context.rootGetters["countries/ruleIds"](countryCode);
    // console.log("ruleIds", ruleIds);

    defaultfeesToAdd = context.getters.primaryServiceDefaultFees(countryCode, "createDynamicRowsForDefaultFees");
    // console.log("defaultfeesToAdd", defaultfeesToAdd);
    
    if (!_.isEmpty(strip(defaultfeesToAdd))) {
      for (let defaultfee of defaultfeesToAdd) {
        const tier = context.getters.serviceTier(
          defaultfee.relationships.primaryservice.data.id,
          'createDynamicRowsForDefaultFees'
        );
        // console.log("tier", tier);
        const options = {
          feeIdx: index,
          country_code: countryCode,
          nodeName: "defaultfees",
          entity: defaultfee,
          tier,
        };
        context.commit(
          "submissions/addDynamicFeeRow",
          context.getters.dynamicFeeRowData(options, "createDynamicRowsForDefaultFees"),
          { root: true }
        );
      }
    }
  }
}
