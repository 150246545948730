import { empty } from "locutus/php/var";
import { notify } from "src/utils"
import { OfflineError } from "src/exceptions";

function filterQueryString(obj) {
  return Object.keys(obj)
    .map((k) => `filter[${k}]=${encodeURIComponent(obj[k])}`)
    .join("&");
}

const getOptionsQuery = (optionsObject = {}) =>
  Object.keys(optionsObject)
    .filter((k) => typeof optionsObject[k] !== "undefined")
    .map((k) => `${k}=${encodeURIComponent(optionsObject[k])}`)
    .join("&");

const getFieldsQuery = (fieldsObj = {}) => {
  const queryString = Object.keys(fieldsObj)
    .filter((k) => typeof fieldsObj[k] !== "undefined")
    .map((k) => {
      return `fields[${k}]=${encodeURIComponent(fieldsObj[k])}`;
    })
    .join("&");
  return queryString;
};

const relatedResourceUrl = ({ parent, relationship }) => {
  const builtUrl = `${parent.type}/${parent.id}/${relationship}`;
  // console.log(builtUrl);
  if (
    parent.relationships &&
    Object.keys(parent.relationships).includes(relationship)
  ) {
    // console.log('getOptions if statement', parent.relationships[relationship]);
    return (
      (
        parent.relationships[relationship].links &&
        parent.relationships[relationship].links.related
      ).replace("api/", "") || builtUrl
    );
  }
  // console.log(builtUrl);
  return builtUrl;
};

const extractData = (response) => {
  if (response.included) {
    response.data.included = response.included;
  }
  if(response?.data) {
    return response.data;
  } else {
    notify("No data returned from server. Please try refreshing your browser. If that doesn't help, please contact support.", negative);
    throw new OfflineError("No data returned from server");
  }
};

//@custom
const notifyError = (error) => {
  // console.log('reststate notifyError:');
  // console.log(error);
  
  console.error("@reststate client error handler", error.response);
  notify(error.getMessage(), negative)
  if (error && error.response) {
    // console.log("@reststate client error response", error.response);
    throw error.response;
  } else {
    // console.log("@reststate client error", error);
    throw error;
  }
};

class Resource {
  constructor({ name, httpClient, errorHandler = notifyError }) {
    this.name = name;
    this.api = httpClient;
    this.errorHandler = errorHandler; //@custom
  }

  all({ options = {} } = {}) {
    let url;

    // console.log('all options',options);
    let queryString = `${getOptionsQuery(options)}`;

    if (!empty(options.fields)) {
      const fieldsString = getFieldsQuery(options.fields);
      // console.log('fieldsString', fieldsString);
      delete options.fields;
      // console.log('queryString', queryString);
      queryString += `&${fieldsString}`;
    }
    // console.log('queryString', queryString);

    if (options.url) {
      ({ url } = options);
    } else {
      url = `${this.name}?${queryString}`;
    }

    return this.api.get(url).then(extractData);
  }

  find({ id, options } = {}) {
    const url = `${this.name}/${id}?${getOptionsQuery(options)}`;
    return this.api.get(url).then(extractData);
  }

  where({ filter, options } = {}) {
    // console.log('reststate client', arguments);

    let queryString = filterQueryString(filter);
    if (!empty(options?.fields)) {
      const fieldsString = getFieldsQuery(options.fields);
      // console.log('fieldsString', fieldsString);
      delete options.fields;
      queryString += `&${fieldsString}`;
    }
    // console.log('queryString', queryString);
    // console.log('get arg',`${this.name}?${queryString}&${getOptionsQuery(options)}` );

    return this.api
      .get(`${this.name}?${queryString}&${getOptionsQuery(options)}`)
      .then(extractData);
  }

  related({ parent, relationship = this.name, options }) {
    const baseUrl = relatedResourceUrl({ parent, relationship });
    const queryString = getOptionsQuery(options);
    const url = queryString ? `${baseUrl}?${queryString}` : baseUrl;
    // console.log('restate client related');
    // console.log(baseUrl);
    // console.log(url);
    return this.api.get(url).then(extractData);
  }

  create(partialRecord) {
    const record = Object.assign({}, partialRecord, { type: this.name });
    const requestData = { data: record };
    return this.api.post(`${this.name}`, requestData).then(extractData);
  }

  update(record) {
    // http://jsonapi.org/faq/#wheres-put
    const requestData = { data: record };
    return this.api
      .patch(`${this.name}/${record.id}`, requestData)
      .then(extractData);
  }

  /**
   * Custom addition to @reststate which will generate a PATCh request,
   * sending through a list of ids, which will completely replace existing
   * relationships entirely
   * @param {object} parent
   * @param {string} relationship optional
   * @param {array|object} records
   * @returns void
   */
  patchRelationships(parent, relationship, records) {
    // https://jsonapi.org/format/#crud-updating-to-many-relationships
    const requestData = { data: records };
    return this.api
      .patch(
        `${parent.type}/${parent.id}/relationships/${relationship}`,
        requestData
      )
      .then(extractData);
  }

  /**
   * Custom addition to @reststate which will generate a POST request,
   * sending through a list of ids, which will add relationships to those
   * already existing, ignoring duplicates
   * @param {object} parent
   * @param {string} relationship optional
   * @param {array|object} records
   * @returns void
   */
  postRelationships(parent, relationship, records) {
    // console.log("reststate client postRelationships parent", parent);
    // https://jsonapi.org/format/#crud-updating-to-many-relationships
    const requestData = { data: records };
    return this.api
      .post(
        `${parent.type}/${parent.id}/relationships/${relationship}`,
        requestData
      )
      .then(extractData);
  }

  // @custom
  removeRelationships(parent, relationship, records) {
    // https://jsonapi.org/format/#crud-updating-to-many-relationships
    const requestData = { data: records };
    // console.log("removeRelationships requestData", requestData);
    // console.log("removeRelationships records", records);
    // console.log("this.api", this.api.delete);

    // console.log("parent", parent);
    // console.log("relationship", relationship);
    return this.api
      .delete(`${parent.type}/${parent.id}/relationships/${relationship}`, {
        data: requestData,
      })
      .then(extractData);
  }

  // @custom
  clearRelationships(parent, relationship, toOne = false) {
    // console.log('clearRelationships');
    // console.log("parent", parent);
    // console.log("relationship", relationship);
    const path = `${parent.type}/${parent.id}/relationships/${relationship}`;
    // console.log(path);
    const data = {
      data: toOne ? null : [],
    }
    // console.log(data);
    return this.api
      .patch(path, data)
      .then(extractData);
  }

  delete({ id }) {
    return this.api.delete(`${this.name}/${id}`);
  }
}

export default Resource;

// @not for production
export { Resource as ResourceClient };
